import { FC } from "react";
import { useTranslation } from "react-i18next";
import LoadingOverlay from "react-loading-overlay";
import useStyles from "./styles";
import ReactDOM from "react-dom";

type props = {
  isActive: boolean;
};

export const CustomLoader: FC<props> = ({ isActive }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return ReactDOM.createPortal(
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <div className={classes.container}>
        <div>
          <LoadingOverlay
            active={isActive}
            spinner
            text={t("common.loading", "Loading...")}
          />
        </div>
      </div>
    </div>,
    document.body
  );
};
