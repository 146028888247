import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
type PropsStyle = {
  width?: string | number;
  padding?: string | number;
  marginBottom?: string | number;
};
const useStyles = createUseStyles({
  buttonRoot: {
    "&.MuiButton-root": {
      width: (props: PropsStyle) => props.width || "100%",
      padding: (props: PropsStyle) => props.padding || "3px 41px 3px 41px",
      marginBottom: (props: PropsStyle) => props.marginBottom || 0,
      height: "30px",
      borderRadius: "50px",
      textTransform: "none",
    },
  },

  variantSuccess: {
    "&.MuiButton-root": {
      color: colors.BLACK,
      textWrap: "nowrap",
      borderColor: colors.success,
      "&:hover": {
        borderColor: colors.success,
      },
    },
  },
  variantError: {
    "&.MuiButton-root": {
      borderColor: colors.error,
      color: colors.BLACK,
      "&:hover": {
        borderColor: colors.error,
      },
    },
  },
  variantIconSuccess: {
    backgroundColor: colors.success,
    width: "8px",
    height: "8px",
    borderRadius: "50px",
    marginRight: " 10px",
  },
  variantIconError: {
    backgroundColor: colors.error,
    width: "8px",
    height: "8px",
    borderRadius: "50px",
    marginRight: "10px",
  },
});
export default useStyles;
