import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    minWidth: "187px",
    borderRadius: "15px",
    backgroundColor: colors.WHITE,
    padding: "20px",
    height: "fit-content",
    maxHeight: "162px",
    overflowY: "scroll",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subContainer2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  contratTitle: {
    color: colors.primary,
  },
  containerHeader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  iconButton: { margin: 0, padding: 0 },

  containerText: {
    padding: "19px 15px 29px 15px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    "& > div": {
      marginBottom: 0,
    },
  },

  divModalText: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    "& > div": {
      flex: 1,
      marginBottom: 0,
    },
  },
});

export default useStyles;
