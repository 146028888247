import { typeOfCardOrBadge } from "../../../../api/badge";
import { subDocuments } from "../../../../components/DocumentsPanel/DocumentsPanel";
import i18n from "../../../../i18n/i18n";

export const transformDataCollab = (employee: TEmployeeOne | undefined) => {
  return [
    { subTitle: "ID Codenekt", description: employee?.company_id },
    {
      subTitle: i18n.t("collaborator.name", "Name"),
      description: `${employee?.lastname ?? ""}`,
    },
    {
      subTitle: i18n.t("collaborator.firstName", "Firstname"),
      description: `${employee?.firstname ?? ""}`,
    },
    {
      subTitle: i18n.t("collaborator.email", "Email"),
      description: `${employee?.company_email ?? ""}`,
    },
    {
      subTitle: i18n.t("collaborator.category", "Category"),
      description: `${employee?.department ?? ""}`,
    },
  ] as subDocuments[];
};

export const transformDataVehicle = (vehicle: TVehicule | undefined) => {
  return [
    {
      subTitle: "Id",
      description: `${vehicle?.immatriculation ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.brand", "Brand"),
      description: `${vehicle?.brand ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.model", "Model"),
      description: `${vehicle?.brandModel ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.vin", "Vin"),
      description: `${vehicle?.vin ?? ""}`,
    },
    {
      subTitle: i18n.t("collaborator.dateOfAssignment", "Date of assignment"),
      description: `${vehicle?.start_date ?? ""}`,
    },
    {
      subTitle: i18n.t("collaborator.assignmentEndDate", "Assignment end date"),
      description: `${vehicle?.end_date ?? ""}`,
    },
  ] as subDocuments[];
};

export const transformDataCardsAndBadge = (
  cardsAndBadges: TCardsAndBadges[] | []
) => {
  return cardsAndBadges.map((cardOrbadge) => {
    return {
      subTitle:
        cardOrbadge.fleet_card.fleet_card_type.support_type ===
        typeOfCardOrBadge.card
          ? i18n.t("collaborator.cards", "Cards")
          : i18n.t("collaborator.badges", "Badges"),
      description: `${cardOrbadge.fleet_card.fleet_card_type.type} <br/>
                    ${cardOrbadge.fleet_card.fleet_card_type.marque} <br/> 
                    ${cardOrbadge.fleet_card.release_date}`,
    };
  }) as subDocuments[];
};
