import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { CustomTypographyLabelValue } from "../../../../components/CustomTypographyLabelValue/CustomTypographyLabelValue";
import { CustomTextSelect } from "../../../../components/CustomTextSelect/CustomTextSelect";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { IconButton, SelectChangeEvent } from "@mui/material";
import { Icon } from "../../../../components/Icon/Icon";
import { colors } from "../../../../styles/colors";
import { CustomDropZoneUpload } from "../../../../components/CustomDropZoneUpload/CustomDropZoneUpload";
import { CustomButtonTable } from "../../../../components/CustomButtonTable/CustomButtonTable";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import { useGetOneEmployee } from "../../../../api/employee";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import toast from "react-hot-toast";
import { uploadImage } from "../../../../utils/firebaseConfig";
import {
  typeOfDocument,
  useCreateDocument,
  useGetDocumentByEmployee,
  useReminderDocumentMissing,
} from "../../../../api/document";
import {
  useAssignDepartementCollab,
  useGetAllDepartments,
} from "../../../../api/departments";
import { getOptionsFromObjects } from "../../../../utils/utils";

type Props = {
  currentEmployee: TEmployeeWithoutVehicle | null;
  onClickPrevious?: () => void;
  onClickSaveAndNext: (step: number) => void;
};

export const StepFileCollaborator: FC<Props> = ({
  currentEmployee,
  onClickPrevious,
  onClickSaveAndNext,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { employee } = useGetOneEmployee(
    getCurrentFleetId(),
    currentEmployee?.user_id ?? 0
  );
  const { mutate: assignCollabDepartement } = useAssignDepartementCollab(
    getCurrentFleetId(),
    employee?.user_id ?? -1
  );
  const { mutate: sendReminderMissingDoc, isPending: isPendinfRemindDoc } =
    useReminderDocumentMissing(
      getCurrentFleetId(),
      currentEmployee?.user_id ?? 0
    );

  const { documents } = useGetDocumentByEmployee(
    getCurrentFleetId(),
    employee?.user_id ?? -1
  );

  const document = documents?.find(
    (document) => document.type === "license_card"
  );

  const { mutate: createDocument, isPending } = useCreateDocument(() =>
    onClickSaveAndNext(2)
  );
  const { departments } = useGetAllDepartments(getCurrentFleetId());

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [valueSelected, setValueSelected] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    setValueSelected({
      label: employee?.department ?? "",
      value: String(employee?.department_id) ?? "",
    });
  }, [employee]);

  const onDrop = (acceptedFiles: File[]) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const onChangeSelectDep = (event: SelectChangeEvent<any>, _: ReactNode) => {
    setValueSelected(event.target.value as { label: string; value: string });
    assignCollabDepartement({
      department_id: event?.target?.value?.value as number,
    });
  };

  const onSave = async () => {
    if (!document) {
      if (uploadedFile) {
        const url = (await uploadImage(
          URL.createObjectURL(uploadedFile)
        )) as string;
        createDocument({
          name: "Permis de conduire",
          user_id: currentEmployee?.user_id ?? 0,
          type: typeOfDocument.permis_de_conduire,
          scan_id: url,
        });
      } else {
        toast.error(t("collaborator.missingDocument", "Missing document"));
      }
    } else {
      onClickSaveAndNext && onClickSaveAndNext(2);
    }
  };

  return (
    <div className={classes.container}>
      {/* Fiche collaborateur */}
      <div className={classes.containerFile}>
        <div className={classes.subContainerFileLeft}>
          <div className={classes.contentLeft}>
            <div>
              <CustomTypography
                text={t("collaborator.collaborator", "Collaborator")}
                variant={"h4"}
                isUppercase
              />
            </div>
            <div className={classes.divCenter}>
              {/* left */}
              <div className={classes.divText}>
                <CustomTypographyLabelValue
                  label={"Company Id"}
                  value={getCurrentFleetId().toString()}
                  isUppercaseValue={false}
                />
                <CustomTypographyLabelValue
                  label={t("collaborator.email", "Email")}
                  value={employee?.company_email ?? ""}
                  isUppercaseValue={false}
                />
              </div>
              {/* right */}
              <div className={classes.divText}>
                <CustomTypographyLabelValue
                  label={t("collaborator.name", "Name")}
                  value={employee?.lastname ?? ""}
                  isUppercaseValue={false}
                />
                <CustomTypographyLabelValue
                  label={t("collaborator.firstName", "Firstname")}
                  value={employee?.firstname ?? ""}
                  isUppercaseValue={false}
                />
              </div>
            </div>
            <div className={classes.divSelectCat}>
              <CustomTextSelect
                label={t("collaborator.service", "Service")}
                placeholder={t("collaborator.service", "Service")}
                value={valueSelected}
                onChange={onChangeSelectDep}
                options={getOptionsFromObjects(departments, "name", "id")}
              />
            </div>
          </div>
        </div>
        <div className={classes.subContainerFileRight}>
          <div className={classes.divRight}>
            <CustomTypography
              text={t("panel.document.documents", "Documents")}
              variant={"h4"}
              isUppercase
            />
            <div className={classes.subContainer}>
              <div className={classes.subContainer2}>
                <IconWithBackground iconName={"license"} />
                <div>
                  <CustomTypography
                    text={t("collaborator.idCard", "Id card")}
                    isUppercase
                    variant="link"
                    fontWeight={"bold"}
                  />
                  <CustomTypography
                    text={t(
                      "document.upload",
                      "Upload your identity card or permit here"
                    )}
                    variant="link"
                  />
                </div>
              </div>
              <IconButton className={classes.iconButton}>
                <Icon
                  name="eye"
                  width={"24px"}
                  height={"24px"}
                  color={colors.BLACK}
                />
              </IconButton>
            </div>
            <div className={classes.subContainer}>
              <div className={classes.subContainer2}>
                <div>
                  <CustomTypography
                    text={t("collaborator.liscence", "Liscence")}
                    isUppercase
                    variant="link"
                    fontWeight={"bold"}
                  />
                  {uploadedFile === null && !document ? (
                    <CustomTypography
                      text={`(${t(
                        "collaborator.missingDocument",
                        "Missing document"
                      )})`}
                      variant="link"
                      color={colors.secondary}
                    />
                  ) : (
                    <CustomTypography
                      text={uploadedFile?.name ?? ""}
                      variant="link"
                      color={colors.BLACK}
                    />
                  )}
                </div>
              </div>
              {!document && (
                <CustomDropZoneUpload
                  width={"40px"}
                  height={"40px"}
                  maxWidth={"40px"}
                  maxHeight={"40px"}
                  iconHeight={"24px"}
                  iconWidth={"24px"}
                  borderRadius={"6px"}
                  onDrop={onDrop}
                  uploadedFile={uploadedFile ? uploadedFile : null}
                />
              )}
              {document && (
                <img
                  src={document.scan_id}
                  alt="Uploaded"
                  className={classes.imagesStyles}
                />
              )}
            </div>

            <div className={classes.buttonSendRappel}>
              <CustomTypography
                text={t(
                  "collaborator.sendAReminderToYourColleague",
                  "Send a reminder to your colleague"
                )}
                variant="link"
              />
              <CustomButtonTable
                variant={"primary"}
                textVariant={"link"}
                label={t("collaborator.sendReminder", "Send reminder")}
                width={"fit-content"}
                fontWeight={"bold"}
                textDecoration={"none"}
                iconName={"bell2"}
                padding={"0px 15px"}
                textTransform="uppercase"
                disabled={isPendinfRemindDoc}
                onClick={() => sendReminderMissingDoc()}
              />
            </div>
          </div>
        </div>
      </div>
      {/* action button */}
      <div>
        <div className={classes.containerAllButton}>
          <CustomButton
            onClick={onClickPrevious}
            label={t("common.previous", "Previous")}
            variant={"default"}
            width={"fit-content"}
            height={"40px"}
          />
          <div className={classes.containerButton}>
            <CustomButton
              label={t("common.saveButton", "Save")}
              variant={"default"}
              width={"fit-content"}
              height={"40px"}
            />
            <CustomButton
              label={t("common.saveAndNext", "Save and next")}
              variant={"primary"}
              width={"fit-content"}
              loading={isPending}
              onClick={onSave}
              height={"40px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
