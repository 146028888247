const initialState = {
  employee: null,
};

const employeeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "NEWLY_SELECTED_EMPLOYEE_STEP_CREATE_VEHICULE":
      return {
        ...state,
        employee: action.data,
      };
    default:
      return state;
  }
};

export default employeeReducer;
