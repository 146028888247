import { FC } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import { Card } from "./Card/Card";
import { Badges } from "./Badges/Badges";

type Props = {
  onClickPrevious?: () => void;
  onClickSaveAndNext?: () => void;
  currentEmployee: TEmployeeWithoutVehicle | null;
};

export const StepCardAndBadge: FC<Props> = ({
  onClickPrevious,
  onClickSaveAndNext,
  currentEmployee,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      {/* content */}
      <div className={classes.subContainer}>
        <div className={classes.content}>
          <Card currentEmployee={currentEmployee} />
          <Badges currentEmployee={currentEmployee} />
        </div>
        {/* buttons */}
        <div className={classes.containerAllButton}>
          <CustomButton
            onClick={onClickPrevious}
            label={t("common.previous", "Previous")}
            variant={"default"}
            width={"fit-content"}
            height={"40px"}
          />
          <div className={classes.containerButton}>
            <CustomButton
              label={t("common.saveButton", "Save")}
              variant={"default"}
              width={"fit-content"}
              height={"40px"}
            />
            <CustomButton
              label={t("common.saveAndNext", "Save and next")}
              variant={"primary"}
              width={"fit-content"}
              onClick={onClickSaveAndNext}
              height={"40px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
