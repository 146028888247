import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type Props = {
  isLastStep?: boolean;
};

const useStyles = createUseStyles(() => ({
  containerStepper: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: "30px 10px 10px 10px",
    width: "100%",
    "& .MuiStepper-root": {
      width: "100%",
      "& .MuiStepLabel-root .MuiStepLabel-iconContainer.Mui-disabled .MuiSvgIcon-root":
        {
          color: colors.greydark,
        },
      "& .MuiStep-root .MuiSvgIcon-root": {
        color: colors.primary,
      },
      "& .MuiStep-root:last-child .MuiSvgIcon-root": {
        color: (props: Props) =>
          props.isLastStep ? colors.success : undefined,
      },
    },
    "& .MuiStepper-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label.Mui-active":
      {
        fontFamily: "Poppins-Regular",
        fontSize: "13px",
        fontWeight: "bold",
      },
    "& .MuiStepper-root .MuiStepLabel-root .MuiStepLabel-labelContainer .MuiStepLabel-label":
      {
        fontFamily: "Poppins-Regular",
        fontSize: "13px",
      },
  },
}));

export default useStyles;
