import { keepPreviousData, useQuery } from "@tanstack/react-query";
import axios from "./axios";

export const eventsKeys = {
  all: (fleetId: number, vehicleId: number, page: number, per_page: number) =>
    [
      "fleetId",
      fleetId,
      "vehicleId",
      vehicleId,
      "page",
      page,
      "per_page",
      per_page,
    ] as const,
};

interface ResponseGetEvents {
  data: TEvent[];
  total: number;
}

export const useGetAllEvents = (
  fleetId: number,
  vehicleId: number,
  page: number = 1,
  per_page: number = 10
) => {
  const { data, error, ...res } = useQuery({
    queryKey: eventsKeys.all(fleetId, vehicleId, page, per_page),
    queryFn: async (): Promise<ResponseGetEvents> =>
      axios.get(`/fleets/${fleetId}/vehicles/${vehicleId}/events`),
    // une minute
    staleTime: 0,
    placeholderData: keepPreviousData,
  });
  return {
    events: data?.data ?? [],
    // totalCount: data?.data?.total,
    ...res,
  };
};
