import { FC } from "react";
import { Menu, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "../styles";
import { CustomTypography } from "../../CustomTypography/CustomTypography";
import { Icon } from "../../Icon/Icon";
import { colors } from "../../../styles/colors";

type Props = {
  handleClose?: () => void;
  anchorEl: null | HTMLElement;
  open: boolean;
};

export const CustomMenuEnchorNotification: FC<Props> = ({
  handleClose,
  open,
  anchorEl,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Menu
      elevation={1}
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem onClick={handleClose}>
        <div className={classes.divMenuEnchor}>
          <Icon
            name="bell2"
            width={"12px"}
            height={"12px"}
            color={colors.BLACK}
          />
          <CustomTypography
            text={t(
              "panel.mileage.relaunchTheEmployee",
              "Relaunch the employee"
            )}
            variant={"link"}
          />
        </div>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <div className={classes.divMenuEnchor}>
          <Icon
            name="trash"
            width={"12px"}
            height={"12px"}
            color={colors.BLACK}
          />
          <CustomTypography
            text={t("common.delete", "Delete")}
            variant={"link"}
          />
        </div>
      </MenuItem>
    </Menu>
  );
};
