import { MouseEventHandler } from "react";
import { Icon } from "../../../components/Icon/Icon";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";

import useStyles from "./styles";

type MenuItemProps = {
  iconName: string;
  text: string;
  width: number | string;
  height: number | string;
  isCollapsed: boolean;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
};

export const MenuItem: React.FC<MenuItemProps> = ({
  iconName,
  isCollapsed,
  text,
  width,
  height,

  onClick,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classes.menuItem}
      style={{
        width: isCollapsed ? 200 : 40,
        justifyContent: isCollapsed ? "start" : "center",
        paddingLeft: isCollapsed ? 10 : 0,
      }}
      onClick={onClick}
    >
      <Icon name={iconName} width={width} height={height} />
      {isCollapsed && (
        <CustomTypography text={text} variant={"h5"} isUppercase />
      )}
    </div>
  );
};
