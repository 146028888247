import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "./axios";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const employeeKeys = {
  all: (fleetId: number) => ["employees", "idFleet", fleetId] as const,
  allEmployeeWithoutVehicle: (fleetId: number) =>
    ["employeesWithoutVehicle", "idFleet", fleetId] as const,
  employeeByID: (fleetId: number, idEmployee: number) =>
    [...employeeKeys.all(fleetId), "idEmployee", idEmployee] as const,
  adminUsers: () => ["adminUsers"] as const,
};

export const useGetAllEmployeeWithVehicle = (fleetId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: employeeKeys.all(fleetId),
    queryFn: async (): Promise<TEmployee[]> => {
      const data = await axios.get(`/companies/${fleetId}/employees-vehicle`);
      return data?.data?.users;
    },
  });

  return { employees: data ?? [], ...res };
};

export const useGetAllEmployeeWithoutVehicle = (fleetId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: employeeKeys.allEmployeeWithoutVehicle(fleetId),
    queryFn: async (): Promise<TEmployeeWithoutVehicle[]> => {
      const data = await axios.get(`/companies/${fleetId}/employees`);
      return data?.data?.users;
    },
  });

  return { employees: data ?? [], ...res };
};

export const useGetOneEmployee = (fleetId: number, idEmployee: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: employeeKeys.employeeByID(fleetId, idEmployee),
    queryFn: async (): Promise<TEmployeeOne> => {
      const data = await axios.get(
        `/companies/${fleetId}/employees/${idEmployee}`
      );
      return data?.data?.[0];
    },
  });

  return { employee: data, ...res };
};

interface RequestUpdateUserAdmin {
  firstname: string;
  lastname: string;
  email: string;
}

export const useUpdateUserEmployee = (
  fleetId: number,
  idEmployee: number,
  onClose?: () => void,
  initUserAdmin?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newUserAdmin: RequestUpdateUserAdmin) => {
      const data = await axios.put(
        `/fleets/${fleetId}/employees/${idEmployee}`,
        newUserAdmin
      );
      return data.data;
    },
    onSuccess: (_) => {
      onClose && onClose();
      initUserAdmin && initUserAdmin();
      // Update cache user admin
      const keys = employeeKeys.employeeByID(fleetId, idEmployee);
      queryClient.refetchQueries(keys as any);

      toast.success(t("common.save", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useDeleteEmployee = (fleetId: number, idEmployee: number) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const data = await axios.delete(
        `/companies/${fleetId}/employees/:${idEmployee}`
      );
      return data.data;
    },
    onSuccess: (_) => {
      const keys = employeeKeys.all(fleetId);
      const prev = queryClient.getQueryData<TEmployee[]>(keys);
      if (prev) {
        const res = prev.filter((employee) => employee.userId !== idEmployee);
        queryClient.setQueryData<TEmployee[]>(keys, res);
      }
      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export enum UserRole {
  SUPER_ADMIN = "SUPER_ADMIN",
  FLEET_MANAGER = "FLEET_MANAGER",
  EMPLOYEE = "EMPLOYEE",
}

interface RequestInviteEmployee {
  role: UserRole;
  email: string;
  firstname: string;
  lastname: string;
}

export const useInviteEmployeeCompanie = (
  idCompanie: number,
  onClose?: () => void
) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (user: RequestInviteEmployee) => {
      const data = await axios.post(
        `/companies/${idCompanie}/employees/invitation`,
        user
      );
      return data.data;
    },
    onSuccess: (_) => {
      onClose && onClose();
      toast.success(t("common.sendInvitation", "Invitation send"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useDisabledEmployee = (
  companyId: number,
  userId: number,
  onClose?: () => void,
  initUserAdmin?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const data = await axios.put(
        `/companies/${companyId}/employees/${userId}/deactivate`
      );
      return data.data;
    },
    onSuccess: (_) => {
      onClose && onClose();
      initUserAdmin && initUserAdmin();
      // Update cache user admin
      const keys = employeeKeys.employeeByID(companyId, userId);
      queryClient.refetchQueries(keys as any);

      toast.success(
        t("collaborator.collaboratorDisabled", "Collaborator disabled")
      );
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorCollab.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};
