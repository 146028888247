import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    minWidth: "204px",
    borderRadius: "15px",
    backgroundColor: colors.WHITE,
    padding: "20px",
    height: "fit-content",
    minHeight: "260px",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // paddingLeft: "20px",
    // paddingRight: "20px",
  },
  subContainer2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  contratTitle: {
    color: colors.primary,
  },
  containerHeader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "54px",
  },
});

export default useStyles;
