import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  container: {
    height: "calc(100vh - 462px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerSearch: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
    gap: "15px",
    width: "400px",
    marginBottom: "40px",
    "& > div": {
      width: "100%",
    },
  },
  divInput: {
    overflowY: "auto",
    maxHeight: "calc(100vh - 456px)",
    marginTop: "65px",
  },
});

export default useStyles;
