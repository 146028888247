import { useGetAllBadgeFleet } from "../../../api/badge";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";
import { CustomTable } from "../../../components/CustomTable/CustomTable";
import { getCurrentFleetId } from "../../../utils/getCurrentFleet";
import { useTranslation } from "react-i18next";
import { columnsBadges } from "./columnsBadges";
import { useState } from "react";

export const Badges = () => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  const {
    badges,
    isPending: isPendingBadges,
    totalCount,
  } = useGetAllBadgeFleet(getCurrentFleetId(), currentPage, 10, searchTerm);

  const [resetPagination, setResetPagination] = useState<boolean>(true);

  const handleChangePageProps = (newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const onResetTable = () => {
    setCurrentPage(1);
    setResetPagination(!resetPagination);
  };

  return (
    <>
      {isPendingBadges && <CustomLoader isActive={isPendingBadges} />}

      <CustomTable
        columns={columnsBadges}
        data={badges}
        titleHeader={t("collaborator.badges", "Badges")}
        tableMaxHeight={"300px"}
        isSearchable
        isFilterFromBackend
        tablePagnationCount={totalCount}
        handleChangePageProps={handleChangePageProps}
        onChangeSearchTerm={(e) => {
          onResetTable();
          setTimeout(() => {
            setSearchTerm(e.target.value);
          }, 1000);
        }}
        // showSelectedField
        // componentHeader={
        //   <>
        //     <CustomButtonTable
        //       variant={"primary"}
        //       textVariant={"link"}
        //       label={t("common.groupeActions", "Groupe actions")}
        //       width={"fit-content"}
        //       fontWeight={"bold"}
        //       textDecoration={"none"}
        //       iconName={"chevronDown"}
        //       padding={"0px 15px"}
        //       textTransform="uppercase"
        //       endIcon
        //     />
        //   </>
        // }
      />
    </>
  );
};

export default Badges;
