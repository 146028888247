import dayjs from "dayjs";

export const columnsHistory = [
  {
    header: "Date",
    field: "date",
    render: (data: Record<string, any>) => {
      return (
        <div>
          {data?.datetime ? dayjs(data.datetime).format("DD-MM-YYYY") : ""}
        </div>
      );
    },
  },
  { header: "Type d'intervention", field: "event_type_name" },
  { header: "Nom d'intervention", field: "name" },
  {
    header: "Facture",
    field: "bill",
  },
];

export const columnsClaims = [
  { header: "Date", field: "date" },
  { header: "Nom", field: "name" },
  { header: "Statut", field: "status" },
];
