import Button from "@mui/material/Button";
import useStyles from "./styles";
import classNames from "classnames";
import { CustomTypography } from "../CustomTypography/CustomTypography";

type Props = {
  label: string;
  width?: string | number;
  padding?: string | number;
  marginBottom?: string | number;
  variant?: "success" | "error";
  textVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "link" | "default";
};

export const CustomButtonOutlined: React.FC<Props> = ({
  marginBottom,
  padding,
  width,
  variant = "success",
  label,
  textVariant = "link",
}) => {
  const classes = useStyles({ marginBottom, padding, width });
  let variantClasses;
  switch (variant) {
    case "success":
      variantClasses = classes.variantSuccess;
      break;
    case "error":
      variantClasses = classes.variantError;
      break;
    default:
      break;
  }
  return (
    <Button
      className={classNames(classes.buttonRoot, variantClasses)}
      variant="outlined"
    >
      <div>
        <div
          className={
            variant === "success"
              ? classes.variantIconSuccess
              : classes.variantIconError
          }
        />
      </div>
      <CustomTypography text={label} variant={textVariant} />
    </Button>
  );
};
