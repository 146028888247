import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles({
  divContainerTVA: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    padding: "15px",
    gap: "15px",
    backgroundColor: colors.WHITE,
  },
  divButtonTVA: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    alignItems: "flex-end",
    "& > div": {
      margin: 0,
      flex: 1,
    },
  },
});

export default useStyles;
