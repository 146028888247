import { useTranslation } from "react-i18next";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import useStyles from "./styles";
import { Sidebar } from "../../../containers/Sidebar/Sidebar";
import { CustomButtonTable } from "../../../components/CustomButtonTable/CustomButtonTable";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { useState } from "react";
import toast from "react-hot-toast";
import { SectionSinister } from "./SectionSinister/SectionSinister";
import { DocumentsClaims } from "./DocumentsClaims/DocumentsClaims";
import { SectionCollaboratorClaims } from "./SectionCollaboratorClaims/SectionCollaboratorClaims";

import { SectionPictures } from "./SectionPictures/SectionPictures";
import { useParams } from "react-router";
import {
  useGetSinisterById,
  useGetSinisterDocuments,
} from "../../../api/sinistre";
import { getCurrentFleetId } from "../../../utils/getCurrentFleet";
type Props = {};

export const UpdateClaims: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id = "" } = useParams();
  const sinisterId = parseInt(id);
  const { sinisterDetail } = useGetSinisterById(
    getCurrentFleetId(),
    sinisterId
  );
  const { sinisterDocs } = useGetSinisterDocuments(
    getCurrentFleetId(),
    sinisterDetail?.vehicle_id ?? -1,
    sinisterId
  );

  const [openDeactive, setOpenDeactive] = useState(false);
  const images = sinisterDocs
    .filter((file: any) => file.document_type.name !== "CST")
    .map((file) => file.scan_id);
   
  return (
    <>
      <CustomModal
        open={openDeactive}
        onCancel={() => setOpenDeactive(false)}
        title={""}
        onClose={() => setOpenDeactive(false)}
        onSave={() => toast.success(t("common.changeSaved", "Change saved"))}
        isConfirmation
        textConfirmation={t("common.confirm", "Confirm")}
        onConfirm={() =>
          toast.success(t("claims.sinisterDisabled", "Disaster close"))
        }
      >
        <div className={classes.textModalContainer}>
          <CustomTypography
            text={t(
              "claims.disabledMessage",
              "</br>You are about to deactivate the sinister.</br>You will not be able to go back.</br></br>Do you confirm this action ?"
            )}
            variant="default"
          />
        </div>
      </CustomModal>
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.container}>
          <div className={classes.containerHeader}>
            <div>
              <CustomTypography
                variant="h2"
                text={t("claims.sinister", "Sinister")}
                lineHeight={"47px"}
                isUppercase
              />
            </div>
            <CustomButtonTable
              variant={"secondary"}
              textVariant={"link"}
              label={t("claims.closeTheDisaster", "Close the disaster")}
              textTransform={"uppercase"}
              width={"fit-content"}
              fontWeight={"bold"}
              textDecoration={"none"}
              iconName={"trash"}
              onClick={() => setOpenDeactive(true)}
            />
          </div>
          <div className={classes.subContainer}>
            <div className={classes.divLeft}>
              <SectionSinister />
              <div className={classes.divLeftBottom}>
                <DocumentsClaims />
                <SectionCollaboratorClaims />
              </div>
            </div>

            {/* right */}
            {images.length > 0 && (
              <div className={classes.divRightSection}>
                <div className={classes.divSinister}>
                  <CustomTypography
                    text={t("claims.sinister", "Sinister")}
                    variant="h3"
                    isUppercase
                  />
                  {/* <CustomIconButton
                    iconName={"plus"}
                    widthIcon={"30px"}
                    heightIcon={"30px"}
                  /> */}
                </div>
                <div className={classes.divPictureList}>
                  {images?.map((image, index) => (
                    <SectionPictures key={index} image={image} index={index} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Sidebar>
    </>
  );
};
