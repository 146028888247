import { CustomTextInput } from "../../../../components/CustomTextInput/CustomTextInput";
import { useTranslation } from "react-i18next";
import { CustomTextDatePicker } from "../../../../components/CustomTextDatePicker/CustomTextDatePicker";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import useStyles from "./styles";
import { colors } from "../../../../styles/colors";
import { ChangeEvent, FC, ReactNode, useState } from "react";
import { CustomTextSelect } from "../../../../components/CustomTextSelect/CustomTextSelect";
import { ModalLeasing } from "./ModalLeasing/ModalLeasing";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
} from "@mui/material";
import { useFormik } from "formik";

import { useGetAllBrands, useGetAllModel } from "../../../../api/brandModel";
import { CustomSearch } from "../../../../components/CustomSearch/CustomSearch";
import {
  VehicleStatus,
  getValueByKeyVehicleStatus,
} from "../../../../utils/types";
import dayjs from "dayjs";
import {
  validationSchemaCreateVehicle,
  initialValuesVehicle,
} from "./validationShema";
import { useCreateVehicule } from "../../../../api/vehicule";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

type Props = {
  onClickSaveAndNext?: () => void;
};

// note: Mettre ici tous les validation des formulaire

export const StepCreateFileVehicule: FC<Props> = ({ onClickSaveAndNext }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const [openLeasing, setOpenLeasing] = useState(false);
  const [brand, setbrand] = useState<TBrand | null>(null);

  const { brands } = useGetAllBrands();
  const { brandsModel } = useGetAllModel(brand?.id ?? null);

  const { mutate: createVehicle, isPending } = useCreateVehicule(
    getCurrentFleetId(),
    onClickSaveAndNext
  );

  const [selectedRental, setSelectedRental] = useState({
    label: "",
    value: "",
  });

  const formik = useFormik({
    initialValues: initialValuesVehicle,
    validationSchema: validationSchemaCreateVehicle,
    onSubmit: (values) => {
      createVehicle({
        // required api
        vin: values.vin ?? "",
        number: values.immatriculation ?? "",
        owner_company_id:
          selectedRental.label.trim().length === 0
            ? getCurrentFleetId()
            : Number(selectedRental.value),
        brand_model_id: Number(values.modele) ?? 0,
        vehicle_status: getValueByKeyVehicleStatus(values.status ?? "") ?? "F",
        monthly_average_cost: 0,
        // not required api
        boite: values.gearBox ?? "",
        puissance: values.fiscalPower ?? "",
        carburant: values.energy ?? "",
        mise_en_circulation: values.releaseDate ?? "",
        picture: `https://firebasestorage.googleapis.com/v0/b/hardy-notch-261718.appspot.com/o/${Number(
          values.modele
        )}.jpg?alt=media`,
        register_step: 0,
      });
    },
  });

  const onChangeSelectRentalCompany = (
    event: SelectChangeEvent<any>,
    _: ReactNode
  ) => {
    setSelectedRental(event.target.value as { label: string; value: string });
    dispatch({ type: "OWNER_COMPANY", data: event.target.value });
  };

  const onChangeAutoCompletBrand = (_: any, brand: any) => {
    setbrand(brand as TBrand);
    formik?.setFieldValue("brand", brand.id);
  };

  const onChangeAutoCompletModele = (_: any, modele: any) => {
    setbrand(modele as TBrandModel);
    formik?.setFieldValue("modele", modele.id);
  };

  const handleRadioChange = (
    _: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    formik.setFieldValue("isLeasing", value);
    if (value === "yes") {
      setOpenLeasing(true);
    } else {
      setSelectedRental({ label: "", value: "" });
      dispatch({ type: "OWNER_COMPANY", data: { label: "", value: "" } });
      setOpenLeasing(false);
    }
  };

  const onCloseLeasing = () => {
    setOpenLeasing(false);
    formik?.setFieldValue("isLeasing", null);
    setSelectedRental({ label: "", value: "" });
    dispatch({ type: "OWNER_COMPANY", data: { label: "", value: "" } });
  };

  const onSaveLeasing = () => {
    if (selectedRental?.label?.trim()?.length > 0) {
      setOpenLeasing(false);
    } else {
      toast.error(
        t("required.selectRentalCompany", "Please select a rental company")
      );
    }
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <ModalLeasing
        open={openLeasing}
        title={t("vehicule.societyName", "Adding rental company name")}
        valueSelectedRental={selectedRental}
        onSave={onSaveLeasing}
        onCancel={onCloseLeasing}
        onClose={onCloseLeasing}
        onChange={onChangeSelectRentalCompany}
      />

      <form className={classes.divForm}>
        <div className={classes.container}>
          <div className={classes.containerInput}>
            <CustomTextInput
              name="vin"
              value={formik?.values?.vin}
              onChange={formik.handleChange}
              label={`${t("vehicule.vin", "VIN ")} *`}
              placeholder={t("vehicule.vin", "VIN")}
            />
            <CustomTextInput
              name="immatriculation"
              value={formik?.values?.immatriculation}
              onChange={formik.handleChange}
              label={`${t("vehicule.registration", "registration")} *`}
              placeholder={t("vehicule.registration", "Registration")}
            />
          </div>
          <div className={classes.containerInput}>
            <CustomSearch
              options={brands}
              getOptionLabel={(option: any) => option?.name}
              label={`${t("vehicule.brand", "Brand")} *`}
              placeholder={t("vehicule.brand", "Brand")}
              onChange={onChangeAutoCompletBrand}
              onChangeTextField={(event) => {
                if (event.target.value?.trim().length === 0) {
                  setbrand(null);
                  formik?.setFieldValue("brand", null);
                }
              }}
            />
            <CustomSearch
              options={brandsModel}
              getOptionLabel={(option: any) => option?.name}
              label={`${t("vehicule.model", "Model")} *`}
              placeholder={t("vehicule.model", "Model")}
              onChange={onChangeAutoCompletModele}
              onChangeTextField={(event) => {
                if (event.target.value?.trim().length === 0) {
                  formik?.setFieldValue("modele", null);
                }
              }}
            />
          </div>
          <div className={classes.containerInput}>
            <CustomTextSelect
              label={`${t("vehicule.gearBox", "Gearbox")} *`}
              options={["Automatique", "Manuelle"]}
              placeholder={t("vehicule.gearBox", "Gearbox")}
              name="gearBox"
              value={formik?.values?.gearBox}
              onChange={formik.handleChange}
            />
            <CustomTextInput
              label={`${t("vehicule.fiscalePower", "Fiscal power")} *`}
              placeholder={t("vehicule.fiscalePower", "Fiscal power")}
              name="fiscalPower"
              value={formik?.values?.fiscalPower}
              onChange={formik.handleChange}
            />
          </div>
          <div className={classes.containerInput}>
            <CustomTextSelect
              label={`${t("vehicule.fuel", "Fuel")} *`}
              options={[
                "Essence",
                "Diesel",
                "Electrique",
                "GPL",
                "Hydrogène",
                "Ethanol",
                "Hybride",
                "hybride rechargeable",
              ]}
              placeholder={t("vehicule.fuel", "Fuel")}
              name="energy"
              value={formik?.values?.energy}
              onChange={formik.handleChange}
            />
            <CustomTextDatePicker
              label={`${t("vehicule.release", "Release")} *`}
              onChange={(value) =>
                formik?.setFieldValue(
                  "releaseDate",
                  dayjs(value).format("YYYY-MM-DD")
                )
              }
            />
          </div>
          <div className={classes.containerInput}>
            <CustomTextSelect
              label={`${t("vehicule.status", "Status")} *`}
              options={Object.keys(VehicleStatus)}
              placeholder={t("vehicule.status", "Status")}
              name="status"
              value={formik?.values?.status}
              onChange={formik.handleChange}
            />
          </div>
          <div className={classes.divRent}>
            <CustomTypography
              text={t("vehicule.rent", "Is this a rental or leasing car? *")}
              variant={"link"}
            />
            <FormControl>
              <RadioGroup
                row
                value={formik?.values?.isLeasing}
                name="isLeasing"
                onChange={(event) => {
                  handleRadioChange(event, event.target.value);
                }}
              >
                <FormControlLabel
                  value={"no"}
                  control={<Radio />}
                  label={t("common.no", "No")}
                />
                <FormControlLabel
                  value={"yes"}
                  control={<Radio />}
                  label={t("common.yes", "Yes")}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className={classes.containerRequire}>
            <CustomTypography
              variant={"link"}
              color={colors.primary}
              text={t(
                "vehicule.allFieldsAreRequired",
                "All fields are required"
              )}
            />
          </div>
          <div className={classes.containerAllButton}>
            <div className={classes.containerButton}>
              <CustomButton
                label={t("common.saveButton", "Save")}
                variant={
                  formik.isValid && formik.dirty ? "default" : "disabled"
                }
                width={"fit-content"}
                height={"40px"}
                onClick={handleSave}
              />
              <CustomButton
                label={t("vehicule.saveAndNext", "Save and next")}
                variant={
                  formik.isValid && formik.dirty ? "primary" : "disabled"
                }
                loading={isPending}
                width={"fit-content"}
                onClick={handleSave}
                height={"40px"}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
