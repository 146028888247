import useStyles from "./styles";
import { FC } from "react";
import { Icon } from "../Icon/Icon";
import { colors } from "../../styles/colors";

type Props = {
  handleAddCustomDropZone?: () => void;
};

export const CustomButtonDropZone: FC<Props> = ({
  handleAddCustomDropZone,
}) => {
  const classes = useStyles();

  return (
    <button onClick={handleAddCustomDropZone} className={classes.divButton}>
      <Icon
        name={"plus"}
        width={"25.33px"}
        height={"25.33px"}
        color={colors.greydark}
      />
    </button>
  );
};
