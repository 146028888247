import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import axios from "./axios";

export const contratKeys = {
  contratById: (id: number) => ["contrat", "id", id] as const,
};

export interface RequestContrat {
  // required
  contract_number: string;
  duration: number;
  start_date: string;
  end_date: string;
  contract_km: number;
  rental_tax: number;
  periodicity_id: number;
  number_winter_tire: number;
  number_summer_tire: number;
  owner_company_id: number;
}

export const useCreateContratVehicle = (
  fleetId: number,
  vehicleId: number,
  onSave?: () => void
) => {
  const { t } = useTranslation();
  const { refetch } = useGetContratByVehicleId(fleetId, vehicleId);
  return useMutation({
    mutationFn: async (createVehicule: RequestContrat) => {
      const data = await axios.post(
        `/fleets/${fleetId}/vehicles/${vehicleId}/rent-contract`,
        createVehicule
      );
      return data.data?.vehicule;
    },
    onSuccess: (_) => {
      toast.success(t("common.save", "Change saved"));
      onSave && onSave();
      refetch();
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorContract.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export const useUpdateContratVehicle = (
  fleetId: number,
  vehicleId: number,
  contractId: number,
  onSave?: () => void
) => {
  const { t } = useTranslation();
  const { refetch } = useGetContratByVehicleId(fleetId, vehicleId);
  return useMutation({
    mutationFn: async (updateVehicule: RequestContrat) => {
      const data = await axios.put(
        `/fleets/${fleetId}/vehicles/${vehicleId}/rent-contract/${contractId}`,
        updateVehicule
      );
      return data.data?.vehicule;
    },
    onSuccess: (_) => {
      refetch();
      toast.success(t("common.save", "Change saved"));
      onSave && onSave();
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorContract.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export const useGetContratByVehicleId = (
  fleetId: number,
  vehicleId: number
) => {
  const { data, error, ...res } = useQuery({
    queryKey: contratKeys.contratById(vehicleId),
    queryFn: async (): Promise<Tcontrat[]> => {
      const data = await axios.get(
        `/fleets/${fleetId}/vehicles/${vehicleId}/rent-contract`
      );
      return data.data;
    },
  });
  if (error) {
  }
  return { contrats: data ?? [], ...res };
};
