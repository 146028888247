import { useTranslation } from "react-i18next";
import { FC, ReactNode, useEffect, useState } from "react";
import { useFormik } from "formik";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { CustomTextInput } from "../../../components/CustomTextInput/CustomTextInput";
import useStyles from "./styles";
import { validationSchemaUpdateCollaborator } from "./validationSchemaUpdateCollaborator";
import { getCurrentFleetId } from "../../../utils/getCurrentFleet";
import {
  useAssignDepartementCollab,
  useGetAllDepartments,
} from "../../../api/departments";
import { SelectChangeEvent } from "@mui/material";
import { CustomTextSelect } from "../../../components/CustomTextSelect/CustomTextSelect";
import { getOptionsFromObjects } from "../../../utils/utils";
import { useUpdateUserEmployee } from "../../../api/employee";

type props = {
  open: boolean;
  employee: TEmployeeOne | null;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalUpdateCollaborator: FC<props> = ({
  open,
  employee,
  onCancel,
  onClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { departments } = useGetAllDepartments(getCurrentFleetId());
  const { mutate: updateUserAdmin, isPending } = useUpdateUserEmployee(
    getCurrentFleetId(),
    employee?.user_id ?? 0,
    onClose,
    () => {}
  );
  const { mutate: assignCollabDepartement } = useAssignDepartementCollab(
    getCurrentFleetId(),
    employee?.user_id ?? -1
  );

  const formik = useFormik({
    initialValues: {
      firstname: employee?.firstname ?? null,
      lastname: employee?.lastname ?? null,
      email: employee?.email ?? null,
      department_id: employee?.department_id,
    },
    validationSchema: validationSchemaUpdateCollaborator,
    onSubmit: (values) => {
      updateUserAdmin({
        email: values.email ?? "",
        firstname: values.firstname ?? "",
        lastname: values.lastname ?? "",
      });
    },
  });

  const [departmentSelected, setDepartmentSelected] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    if (employee !== null) {
      formik.setFieldValue("firstname", employee.firstname);
      formik.setFieldValue("lastname", employee.lastname);
      formik.setFieldValue("email", employee.email);
      formik.setFieldValue("department_id", employee.department_id);
      setDepartmentSelected({
        label: employee?.department ?? "",
        value: String(employee?.department_id) ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee]);

  const onChangeSelectDep = (event: SelectChangeEvent<any>, _: ReactNode) => {
    const dep = event.target.value as { label: string; value: string };
    formik.setFieldValue("department_id", dep.value);
    setDepartmentSelected(dep);
    assignCollabDepartement({
      department_id: Number(dep.value),
    });
  };

  const onSave = () => {
    formik.handleSubmit();
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={t("collaborator.collaborator", "Collaborator")}
      onClose={onClose}
      onSave={onSave}
      loadingSave={isPending}
    >
      <div className={classes.containerText}>
        <CustomTextInput
          label={t("collaborator.name", "Name")}
          placeholder={t("collaborator.name", "Name")}
          marginBottom={"0px"}
          name="lastname"
          value={formik.values.lastname}
          onChange={formik.handleChange}
        />
        <CustomTextInput
          label={t("collaborator.firstName", "Firstname")}
          placeholder={t("collaborator.firstName", "Firstname")}
          marginBottom={"0px"}
          name="firstname"
          value={formik.values.firstname}
          onChange={formik.handleChange}
        />
        <CustomTextInput
          label={t("collaborator.email", "Email")}
          placeholder={t("collaborator.email", "Email")}
          marginBottom={"0px"}
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <CustomTextSelect
          label={t("collaborator.service", "Service")}
          placeholder={t("collaborator.service", "Service")}
          value={departmentSelected}
          onChange={onChangeSelectDep}
          options={getOptionsFromObjects(departments, "name", "id")}
        />
      </div>
    </CustomModal>
  );
};
