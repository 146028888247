import useStyles from "./styles";
import { FC } from "react";
import { Accept, DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { Icon } from "../Icon/Icon";
import { colors } from "../../styles/colors";
import { CustomTypography } from "../CustomTypography/CustomTypography";

type Props = {
  width?: string | number;
  height?: string | number;
  maxWidth?: string | number;
  maxHeight?: string | number;
  borderRadius?: string | number;
  iconWidth?: string | number | undefined;
  iconHeight?: string | number | undefined;
  iconName?: string;
  uploadedFile: File | null;
  accept?: Accept | undefined;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
};

export const CustomDropZoneUpload: FC<Props> = ({
  width,
  height,
  maxWidth,
  maxHeight,
  borderRadius,
  iconHeight,
  iconWidth,
  iconName,
  uploadedFile,
  accept,
  onDrop,
}) => {
  const classes = useStyles({
    maxWidth,
    maxHeight,
    width,
    height,
    borderRadius,
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: accept
      ? accept
      : {
          "image/png": [".png"],
          "image/jpeg": [".jpg", ".jpeg"],
        },
    multiple: false,
    maxSize: 10 * 1024 * 1024, // 10 megabytes in bytes
  });

  const getIcon = (uploadedFile: File | null) => {
    if (uploadedFile) {
      if (uploadedFile?.type?.includes("image")) {
        return (
          <img
            src={URL.createObjectURL(uploadedFile)}
            alt="Uploaded"
            className={classes.imagesStyles}
          />
        );
      } else {
        return (
          <div className={classes.textName}>
            {/* <Icon
            name={iconName ? iconName : "fileInfo"}
            width={iconWidth ? iconWidth : "38px"}
            height={iconHeight ? iconHeight : "38px"}
            color={colors.greydark}
          /> */}
            <CustomTypography
              text={uploadedFile.name}
              variant={"default"}
              color={colors.darkblack}
            />
          </div>
        );
      }
    } else {
      return (
        <Icon
          name={iconName ? iconName : "photo"}
          width={iconWidth ? iconWidth : "38px"}
          height={iconHeight ? iconHeight : "38px"}
          color={colors.greydark}
        />
      );
    }
  };

  return (
    <div className={classes.uploadFile} {...getRootProps()}>
      <input {...getInputProps()} />
      {getIcon(uploadedFile)}
    </div>
  );
};
