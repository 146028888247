import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type Props = {
  minHeight?: string | number;
};

const useStyles = createUseStyles({
  containerSubDocuments: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
    borderRadius: "8px",
    flexBasis: "217px",
    flexGrow: 0,
    flexShrink: 0,
    minHeight: (props: Props) => (props.minHeight ? props.minHeight : "462px"),
    backgroundColor: colors.WHITE,
  },
  divContentPicture: {
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
  },
  divPictures: {
    width: "40px",
    height: "40px",
    borderRadius: "6px",
    border: `1px dashed ${colors.primary}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default useStyles;
