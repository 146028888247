import { useTranslation } from "react-i18next";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { Sidebar } from "../../../containers/Sidebar/Sidebar";
import useStyles from "./styles";
import i18n from "../../../i18n/i18n";
import { CustomStepper } from "../../../components/CustomStepper/CustomStepper";
import { useState } from "react";
import { StepContract } from "./StepContract/StepContract";
import { StepCreateFileVehicule } from "./StepCreateFileVehicule/StepCreateFileVehicule";
import { StepAssignmentOfTheCollaborator } from "./StepAssignmentOfTheCollaborator/StepAssignmentOfTheCollaborator";
import { StepValidationVehicule } from "./StepValidationVehicule/StepValidationVehicule";
import { StepAddDocuments } from "./StepAddDocuments/StepAddDocuments";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

type Props = {};

const steps = [
  i18n.t("vehicule.creationOfTheVehicle", "Creation of the vehicle"),
  i18n.t("vehicule.contract", "Contract"),
  i18n.t("vehicule.addingDocuments", "Adding documents"),
  i18n.t("vehicule.assignACollaborator", "Assing a collaborator"),
  i18n.t(
    "vehicule.validationOfTheVehicleFile",
    "Validation of the vehicle file"
  ),
];

export const CreateVehicule: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const newlyCreatedVehicle = useSelector(
    (state: any) => state.vehicleReducer.vehicule
  );
  const onClickSkipThisStepAssignmentOfTheCollaborator = () => {
    dispatch({
      type: "NEWLY_SELECTED_EMPLOYEE_STEP_CREATE_VEHICULE",
      data: null,
    });
    setCurrentStep(4);
  };

  return (
    <Sidebar
      styles={{
        sidebarRightRoot: classes.vehiculeRoot,
        sidebarRoot: classes.contentMain,
      }}
    >
      <div className={classes.container}>
        <div className={classes.containerCar}>
          <CustomTypography
            variant="h2"
            isUppercase
            text={t("vehicule.addVehicule", "Add a new car")}
          />
        </div>
        <div className={classes.subContainer}>
          <CustomStepper steps={steps} activeStep={currentStep} />
          <div className={classes.subContainerStepper}>
            <div className={classes.subsubContainer}>
              {currentStep === 0 && (
                <CustomTypography
                  isUppercase
                  text={t(
                    "vehicule.creationOfTheVehicleFile",
                    "Creation of the vehicle file"
                  )}
                  variant={"h3"}
                />
              )}
              {currentStep === 1 && (
                <CustomTypography
                  isUppercase
                  text={t("vehicule.vehicleContract", "Vehicle contract")}
                  variant={"h3"}
                />
              )}
              {currentStep === 2 && (
                <CustomTypography
                  isUppercase
                  text={t("vehicule.addingDocuments", "Adding documents")}
                  variant={"h3"}
                />
              )}
              {currentStep === 3 && (
                <CustomTypography
                  isUppercase
                  text={t(
                    "vehicule.assignmentOfTheCollaborator",
                    "Assignment of the collaborator"
                  )}
                  variant={"h3"}
                />
              )}
              {currentStep === 4 && (
                <CustomTypography
                  isUppercase
                  text={t(
                    "vehicule.validationOfTheVehicleFile",
                    "Validation of the vehicle file"
                  )}
                  variant={"h3"}
                />
              )}
              <div className={classes.content}>
                {currentStep === 0 && (
                  <StepCreateFileVehicule
                    onClickSaveAndNext={() => setCurrentStep(1)}
                  />
                )}
                {currentStep === 1 && (
                  <StepContract
                    onClickPrevious={() => setCurrentStep(0)}
                    onClickSaveAndNext={() => setCurrentStep(2)}
                  />
                )}
                {currentStep === 2 && (
                  <StepAddDocuments
                    onClickPrevious={() => setCurrentStep(1)}
                    onClickSaveAndNext={() => setCurrentStep(3)}
                  />
                )}
                {currentStep === 3 && (
                  <StepAssignmentOfTheCollaborator
                    onClickSkipThisStep={
                      onClickSkipThisStepAssignmentOfTheCollaborator
                    }
                    onClickSaveAndNext={() => setCurrentStep(4)}
                  />
                )}
                {currentStep === 4 && (
                  <StepValidationVehicule
                    onClickPrevious={() => setCurrentStep(3)}
                    onClickSave={() =>
                      navigate(`/vehicule/update/${newlyCreatedVehicle?.id}`)
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};
