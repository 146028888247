import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles((_) => ({
  containerHeaderLeft: {
    borderRadius: "15px",
    padding: "15px",
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    backgroundColor: colors.WHITE,
  },
  subContainerHeaderLeft: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    width: "100%",
  },

  divImages: {
    display: "flex",
    borderRadius: "10px",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "117px",
    backgroundColor: colors.default,
  },
  divCollaborator: {
    display: "flex",
    flexDirection: "column",
    gap: "2px",
    padding: "10px 5px 10px 10px",
    borderRadius: "10px",
    width: "100%",
  },
  divCollaboratorText: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divContainerInfo: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    "& > div": {
      flex: 1,
    },
  },
  subContainerInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    justifyContent: "space-between",
    minWidth: "240px",
  },

  containerText: {
    padding: "19px 15px 29px 15px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
}));

export default useStyles;
