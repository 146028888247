import { ButtonsActionsCollaborator } from "./ButtonsActionsCollaborator";

export const columnsCollaborator = [
  { header: "Département", field: "department" },
  { header: "Nom", field: "lastname" },
  { header: "Prenom", field: "firstname" },
  { header: "vehicule", field: "model" },
  { header: "marque", field: "brand" },
  {
    header: "",
    field: "",
    render: (collaborator: Record<string, any>) => {
      return (
        <ButtonsActionsCollaborator collaborator={collaborator as TEmployee} />
      );
    },
  },
];