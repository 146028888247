import dayjs from "dayjs";
import { CustomChip } from "../../../components/CustomChip/CustomChip";
import { getSinisterKeyLabelByValue } from "../types";
import { ButtonsListSinister } from "./ButtonsListSinister";

export const columnsClaimsList = [
  { header: "ID", field: "id" },
  { header: "Nom du sinistre", field: "name" },
  {
    header: "Date",
    field: "",
    render: (rowData: Record<string, any>) => {
      return (
        <div>
          {rowData?.sinister_at &&
            dayjs(rowData.sinister_at).format("DD-MM-YYYY")}
        </div>
      );
    },
  },
  { header: "Immatriculation", field: "number" },
  { header: "kilométrage", field: "mileage" },
  {
    header: "Statut",
    field: "status",
    render: (rowData: Record<string, any>) => {
      return (
        <CustomChip
          label={getSinisterKeyLabelByValue(rowData?.sinister_status) ?? ""}
          value={rowData?.sinister_status}
        />
      );
    },
  },
  {
    header: "",
    field: "",
    render: (rowData: Record<string, any>) => {
      return <ButtonsListSinister sinister={rowData as TSinister} />;
    },
  },
];
