import dayjs from "dayjs";
import i18n from "../../../../i18n/i18n";

export const transformDataMileages = (contrat: Tcontrat | undefined) => {
  return [
    {
      icoName: "fileStack",
      title: i18n.t("panel.document.contract", "Contract"),
      subTitle: `${contrat?.contract_km ? contrat?.contract_km : 0} kms`,
    },
    {
      icoName: "license",
      title: `${i18n.t("panel.mileage.statements", "Statements of")} ${
        contrat?.start_date
          ? dayjs(contrat.start_date).format("DD-MM-YYYY")
          : ""
      }`,
      subTitle: `${contrat?.contract_km ? contrat?.contract_km : 0} Km`,
    },
  ];
};
