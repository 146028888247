import * as Yup from "yup";

export const validationSchemaCreateCarteAndBadge = Yup.object().shape({
  choiceOfType: Yup.string(),
  choiceOfBrand: Yup.string(),
  dateOfCirculation: Yup.string(),
  billing: Yup.string(),
  number: Yup.string(),
  // Ajoutez d'autres champs de validation si nécessaire
});
