import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  divLeftTop: {
    display: "flex",
    flexDirection: "row",
    borderRadius: "15px",
    padding: "15px",
    gap: "15px",
    minHeight: "193px",
    maxHeight: "193px",
    backgroundColor: colors.WHITE,
  },
  code: {
    minWidth: "182px",
    minHeight: "182px",
    backgroundColor: "#EEEEEE",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  divLeftTopRight: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    padding: "10px",
    gap: "15px",
    // width: "594px",
    width: "100%",
  },
  divSinister: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divInfos: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    "& > div": { flex: 1 },
  },
  divInfosComments: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  divText: {
    width: "222px",
    maxHeight: "46px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export default useStyles;
