import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { useState } from "react";
import { CustomTextField } from "../../components/CustomTextField/CustomTextField";
import toast from "react-hot-toast";
import { validateEmail } from "../../utils/regex";
import { useSendCode } from "../../api/user";

type Props = {};

export const EnterEmailForgotPassword: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");

  const { mutate: sendCode, isPending } = useSendCode(email);

  const handleConfirm = () => {
    if (!validateEmail(email)) {
      toast.error(t("required.emailInvalid", "Invalid email"));
    } else {
      sendCode({ email });
    }
  };

  return (
    <div className={classes.container}>
      <Logo className={classes.logo} />
      <CustomTypography
        variant="link"
        textDecoration={"none"}
        styles={{ containerText: classes.text }}
        text={t(
          "login.enterEmail",
          "Enter your email address to receive a code to reset your password."
        )}
      />
      <div className={classes.subContainer}>
        <CustomTextField
          placeholder={t("login.email", "Email")}
          fullWidth={true}
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className={classes.subContainer2}>
          <CustomButton
            onClick={handleConfirm}
            height={"40px"}
            variant={"white"}
            label={t("login.newPassword", "VALIDATE")}
            loading={isPending}
          />
        </div>
      </div>
    </div>
  );
};
