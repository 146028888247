/* eslint-disable react-hooks/exhaustive-deps */
import useStyles from "./styles";
import { CustomModal } from "../../../../components/CustomModal/CustomModal";
import { useTranslation } from "react-i18next";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { CustomSearch } from "../../../../components/CustomSearch/CustomSearch";
import { CustomTextDatePicker } from "../../../../components/CustomTextDatePicker/CustomTextDatePicker";
import dayjs from "dayjs";
import {
  useAssignCardOrBadge,
  useGetAllCardsFleetUnassigned,
} from "../../../../api/badge";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useParams } from "react-router";
import toast from "react-hot-toast";
import { useGetOneEmployee } from "../../../../api/employee";

type props = {
  opeModal: boolean;
  onClose: () => void;
  onCancel: () => void;
};

export const ModalAddCard: FC<props> = ({ opeModal, onCancel, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const userId = parseInt(id);

  const { employee } = useGetOneEmployee(getCurrentFleetId(), userId);

  const { cards, isPending } = useGetAllCardsFleetUnassigned(
    getCurrentFleetId()
  );

  const [startDate, setStartDate] = useState<
    string | number | Date | dayjs.Dayjs | null
  >(null);
  const [endDate, setEndDate] = useState<
    string | number | Date | dayjs.Dayjs | null
  >(null);
  const [selectedCard, setSelectedCard] = useState<TCard | null>(null);

  useEffect(() => {
    setStartDate(null);
    setEndDate(null);
    setSelectedCard(null);
  }, [opeModal]);

  const { mutate: assignCard } = useAssignCardOrBadge(
    getCurrentFleetId(),
    employee?.id ?? userId,
    onClose
  );

  const onChangeTextField = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.trim().length === 0) {
      setSelectedCard(null);
    }
  };

  const onChangeAutocomplet = (_: any, valueTrue: any) => {
    setSelectedCard(valueTrue as TCard);
  };

  const onSave = () => {
    if (startDate === null || selectedCard === null) {
      toast.error(t("required.fieldRequired", "Fields (*) are required."));
    } else {
      assignCard({
        badgeOrCardId: selectedCard.id,
        company_user_id: employee?.id ?? userId,
        start_date: startDate,
        end_date: endDate,
      });
    }
  };

  return (
    <>
      <CustomModal
        width={"400px"}
        open={opeModal}
        onCancel={onCancel}
        title={t("carteBadge.addCard", "Add card")}
        onClose={onClose}
        onSave={onSave}
        loadingSave={isPending}
      >
        <div className={classes.containerText}>
          <div className={classes.divModalText}>
            <CustomSearch
              label={`${t("contrat.number", "Card number")} *`}
              placeholder={"123456789"}
              options={cards}
              onChange={onChangeAutocomplet}
              onChangeTextField={onChangeTextField}
              getOptionLabel={(card: any) =>
                `${card?.number}  - ${card?.cardBrand} - ${card?.cardType}` ??
                ""
              }
            />
            <CustomTextDatePicker
              label={`${t("common.startDate", "Start date")} *`}
              value={startDate as any}
              onChange={(value) =>
                setStartDate(dayjs(value).format("YYYY-MM-DD"))
              }
            />
            <CustomTextDatePicker
              label={`${t("common.endDate", "End date")}`}
              value={endDate as any}
              onChange={(value) =>
                setEndDate(dayjs(value).format("YYYY-MM-DD"))
              }
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};
