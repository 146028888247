import { FC, useState } from "react";
import useStyles from "./styles";
import { IconButton } from "@mui/material";
import { Icon } from "../Icon/Icon";
import { colors } from "../../styles/colors";
import { CustomMenuEnchorNotification } from "./CustomMenuEnchorNotification/CustomMenuEnchorNotification";
import { useReadNotification } from "../../api/notification";
import { getCurrentFleetId } from "../../utils/getCurrentFleet";

type Props = {
  notification: TNotification;
  isRead?: boolean;
};

export const CustomEnchorNotification: FC<Props> = ({
  isRead,
  notification,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { mutate: readNotification } = useReadNotification(getCurrentFleetId());

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onRead = () => {
    readNotification(notification.id);
  };

  return (
    <div className={classes.menuEnchor}>
      {!isRead && (
        <IconButton>
          <Icon
            name="mail"
            width={"24px"}
            height={"24px"}
            color={colors.BLACK}
          />
        </IconButton>
      )}
      <IconButton onClick={onRead}>
        <Icon name="eye" width={"24px"} height={"24px"} color={colors.BLACK} />
      </IconButton>
      <div>
        <IconButton onClick={handleClick}>
          <Icon
            name="dots"
            width={"24px"}
            height={"24px"}
            color={colors.BLACK}
          />
        </IconButton>
        <CustomMenuEnchorNotification
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
};
