import { useTranslation } from "react-i18next";
import useStyles from "./styles";

import { FC, ReactNode } from "react";
import { CustomTextSelect } from "../../../../../components/CustomTextSelect/CustomTextSelect";
import { CustomModal } from "../../../../../components/CustomModal/CustomModal";
import { CustomTypography } from "../../../../../components/CustomTypography/CustomTypography";
import { useGetAllCompaniesRental } from "../../../../../api/companies";
import { SelectChangeEvent } from "@mui/material";
import { getOptionsFromObjects } from "../../../../../utils/utils";

type props = {
  open: boolean;
  title: string;
  valueSelectedRental?: any;
  onCancel?: () => void;
  onClose?: () => void;
  onSave?: () => void;
  onChange?: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
};

export const ModalLeasing: FC<props> = ({
  open,
  title,
  valueSelectedRental,
  onCancel,
  onClose,
  onSave,
  onChange,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { rentalCompanies } = useGetAllCompaniesRental();

  return (
    <CustomModal
      width={"382px"}
      open={open}
      onCancel={onCancel}
      title={title}
      onClose={onClose}
      onSave={onSave}
      labelConfirmation={t("common.add", "Add")}
    >
      <div className={classes.containerText}>
        <CustomTextSelect
          label={`${t(
            "vehicule.rentalName",
            "Name of the rental company appearing on the contract"
          )} *`}
          placeholder={`${t("vehicule.companyName", "companyName")} *`}
          value={valueSelectedRental}
          onChange={onChange}
          options={getOptionsFromObjects(rentalCompanies, "name", "id")}
        />
        <CustomTypography
          text={t(
            "vehicule.warning",
            "NB: If the name is not listed, contact CodeNekt"
          )}
          variant={"link"}
        />
      </div>
    </CustomModal>
  );
};
