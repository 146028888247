import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  divUpload: {
    height: "34px",
    backgroundColor: colors.secondary,
    padding: "3px 41px 3px 41px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },

  divText: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  divTextDisplay: {
    width: "100%",
    height: "40px",
    borderRadius: "50px",
    border: `2px solid ${colors.WHITE}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default useStyles;
