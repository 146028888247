import { useTranslation } from "react-i18next";
import { CustomTypographyLabelValue } from "../CustomTypographyLabelValue/CustomTypographyLabelValue";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { FC } from "react";
import { CustomIconButton } from "../CustomIconButton/CustomIconButton";
import useStyles from "./styles";
import { useParams } from "react-router";
import { useGetOneVehicle } from "../../api/vehicule";
import { getCurrentFleetId } from "../../utils/getCurrentFleet";
import dayjs from "dayjs";

type props = {
  isEditableIcon?: boolean;
  onClickEditIcon?: () => void;
};

export const CustomVehicleInfos: FC<props> = ({
  isEditableIcon,
  onClickEditIcon,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id = "" } = useParams();
  const vehicleId = parseInt(id);
  const { vehicule } = useGetOneVehicle(getCurrentFleetId(), vehicleId);

  return (
    <div className={classes.subContainerInfo}>
      <div className={classes.code}>
        <CustomTypography
          text={vehicule?.immatriculation ?? ""}
          variant={"h3"}
        />
      </div>
      <div className={classes.description}>
        <div className={classes.paperTitle}>
          <CustomTypography
            isUppercase
            variant="h3"
            text={t("vehicule.vehicule", "Vehicle")}
          />
          {isEditableIcon && (
            <CustomIconButton
              iconName={"pencil"}
              widthIcon={"20px"}
              heightIcon={"20px"}
              onClick={onClickEditIcon}
            />
          )}
        </div>
        <div className={classes.mark}>
          <div>
            <CustomTypographyLabelValue
              label={t("panel.vehicule.brand", "Brand")}
              value={vehicule?.brand ?? ""}
            />
            <CustomTypographyLabelValue
              label={t("panel.vehicule.model", "Model")}
              value={
                (vehicule?.brandModel &&
                  JSON.parse(vehicule.brandModel).name) ??
                ""
              }
            />
            <CustomTypographyLabelValue
              label={t("vehicule.carburant")}
              value={vehicule?.carburant ?? ""}
            />
            <CustomTypographyLabelValue
              label={t("panel.vehicule.fiscalPower", "Fiscal power")}
              value={`${vehicule?.puissance ?? ""} CV`}
            />
          </div>
          <div>
            <CustomTypographyLabelValue
              label={t("panel.vehicule.box", "Box")}
              value={vehicule?.boite ?? ""}
            />
            <CustomTypographyLabelValue
              label={t("vehicule.vin", "Vin")}
              value={vehicule?.vin ?? ""}
            />
            <CustomTypographyLabelValue
              label={t(
                "panel.vehicule.dateOfCirculation",
                "Date of circulation"
              )}
              value={
                vehicule?.mise_en_circulation
                  ? dayjs(vehicule.mise_en_circulation).format("DD-MM-YYYY")
                  : ""
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
