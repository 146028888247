import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  containerVehicule: {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: colors.WHITE,
  },
  containerNumber: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    padding: "15px 10px 15px 10px",
    backgroundColor: colors.default,
    width: "292px",
  },
  containerButton: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  divText: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  divButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  divButtonIcon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "16px",
  },
  divHeader: {
    marginTop: "6px",
    marginBottom: "5px",
  },

  textModalContainer: {
    padding: "0px 0px 29px 0px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },

  containerText: {
    padding: "19px 15px 29px 15px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
}));

export default useStyles;
