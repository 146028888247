import toast from "react-hot-toast";
import { FormValuesVehicleUpdate } from "./validationShemaUpdate";
import i18n from "../../../../i18n/i18n";

export const checkValues = (values: FormValuesVehicleUpdate) => {
  if (
    values.brand === null ||
    values.modele === null ||
    values.vin?.trim().length === 0 ||
    values.immatriculation?.trim().length === 0 ||
    values.fiscalPower?.trim().length === 0
  ) {
    toast.error(i18n.t("required.checkFields"));
    return false;
  } else {
    return true;
  }
};
