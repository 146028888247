export const colors = {
  darkblack: "#000000",
  BLACK: "#312D2D",
  WHITE: "#FFFFFF",
  primary: "#0898FF",
  secondary: "#FF6F08",
  tertiary: "#FF0000",
  default: "#F2F2F2",
  success: "#39B82E",
  error: "#FF0000",
  info: "#0898FF",
  bgcolor: "#F7F8F9",
  greydark: "#beb5b5",
};
