import { useTranslation } from "react-i18next";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import useStyles from "./styles";
import { Sidebar } from "../../../containers/Sidebar/Sidebar";
import { CustomButtonTable } from "../../../components/CustomButtonTable/CustomButtonTable";
import { Icon } from "../../../components/Icon/Icon";
import { ContainerCollaborator } from "../../../containers/ContainerCollaborator/ContainerCollaborator";
import { DocumentsCollaborator } from "./DocumentsCollaborator/DocumentsCollaborator";
import { CustomTable } from "../../../components/CustomTable/CustomTable";

import { VehiculeAffected } from "./VehiculeAffected/VehiculeAffected";
import { HistoriqueContent } from "./HistoriqueContent/HistoriqueContent";
import { CustomModal } from "../../../components/CustomModal/CustomModal";
import { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { CarteCollaborator } from "./CarteCollaborator/CarteCollaborator";
import { BadgesCollaborator } from "./BadgesCollaborator/BadgesCollaborator";
import { useDisabledEmployee } from "../../../api/employee";
import { getCurrentFleetId } from "../../../utils/getCurrentFleet";
import { columnsClaims } from "./fakeData";
type Props = {};

export const UpdateCollaborator: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const userId = parseInt(id);

  const [openDeactive, setOpenDeactive] = useState(false);
  const navigate = useNavigate();

  const { mutate: disabledUser, isPending } = useDisabledEmployee(
    getCurrentFleetId(),
    userId,
    () => setOpenDeactive(false)
  );

  const onConfirmDisabled = () => {
    disabledUser();
  };

  return (
    <>
      <CustomModal
        open={openDeactive}
        onCancel={() => setOpenDeactive(false)}
        title={t("collaborator.collaborator", "Collaborator")}
        onClose={() => setOpenDeactive(false)}
        onSave={() => toast.success(t("common.changeSaved", "Change saved"))}
        isConfirmation
        loadingSave={isPending}
        textConfirmation={t("common.disabled", "Disabled")}
        onConfirm={onConfirmDisabled}
      >
        <div className={classes.textModalContainer}>
          <CustomTypography
            text={t(
              "collaborator.disableWarning",
              "</br>You are about to deactivate the employee.</br>You will not be able to go back.</br></br>Do you confirm this action?"
            )}
            variant="default"
          />
        </div>
      </CustomModal>
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.container}>
          <div className={classes.containerHeader}>
            <div>
              <CustomTypography
                variant="h2"
                text={t("collaborator.collaborator", "Collaborator")}
                lineHeight={"47px"}
                isUppercase
              />
              <div className={classes.iconContainerHeader}>
                <Icon name={"collaborateur"} height={24} width={24} />
                <CustomTypography variant="default" text={`ID: ${userId}`} />
              </div>
            </div>
            <CustomButtonTable
              variant={"secondary"}
              textVariant={"link"}
              label={t(
                "collaborator.deactivateTheCollaborator",
                "Deactive the collaborator"
              )}
              textTransform={"uppercase"}
              width={"fit-content"}
              fontWeight={"bold"}
              textDecoration={"none"}
              iconName={"trash"}
              onClick={() => setOpenDeactive(true)}
            />
          </div>
          <div className={classes.subContainer}>
            <div className={classes.leftContent}>
              <ContainerCollaborator isEditableIcon fullInfos userId={userId} />
              <div className={classes.containerBodyLeft}>
                <div className={classes.divDocument}>
                  <DocumentsCollaborator />
                  <CarteCollaborator />
                  <BadgesCollaborator />
                </div>

                {/* sinistres */}
                <div className={classes.divClaims}>
                  <CustomTable
                    titleHeader={t("panel.claims.claims", "Claims")}
                    columns={columnsClaims}
                    data={[]}
                    labelButtonFooter={t(
                      "panel.claims.seeAllClaims",
                      "See all claims"
                    )}
                    showIconEye
                    tableMaxHeight={"357px"}
                    tableMinHeight={"357px"}
                    showPagination={false}
                    iconButton="plus"
                    onClickIconButton={() => navigate("/claims/create")}
                    onClickButtonFooter={() => navigate("/claims/list")}
                  />
                </div>
              </div>
            </div>
            <div className={classes.rightContent}>
              <VehiculeAffected />
              <HistoriqueContent />
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};
