import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignSelf: "center",
    gap: "20px",
  },
  containerFile: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    justifyContent: "center",
  },
  subContainerFileLeft: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "8px",
    padding: "25px 20px 10px 20px",
    backgroundColor: colors.WHITE,
    width: "350px",
    minWidth: "350px",
  },
  subContainerFileRight: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    borderRadius: "8px",
    padding: "25px 20px 25px 20px",
    backgroundColor: colors.WHITE,
    width: "350px",
    minWidth: "350px",
  },
  divRight: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
  },
  contentLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  divCenter: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    "& > div": {
      flex: 1,
    },
  },
  divSelectCat: {
    marginTop: "11px",
  },
  divText: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },

  subContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subContainer2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  iconButton: { margin: 0, padding: 0 },
  buttonSendRappel: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  containerAllButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  imagesStyles: {
    maxWidth: "100px",
    maxHeight: "100px",
  },
});

export default useStyles;
