import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  subContainerInfo: {
    display: "flex",
    gap: "15px",
    flexDirection: "row",
  },
  code: {
    width: "182px",
    height: "182px",
    backgroundColor: "#EEEEEE",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  description: {
    flex: 1,
    marginLeft: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  paperTitle: {
    paddingBottom: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mark: {
    fontSize: 12,
    lineHeight: "15px",
    display: "flex",
    "& > div": {
      flex: 1,
      "& > div": {
        marginBottom: 10,
      },
    },
  },
});

export default useStyles;
