import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { Sidebar } from "../../containers/Sidebar/Sidebar";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomButtonTable } from "../../components/CustomButtonTable/CustomButtonTable";
import { Vehicule } from "./Vehicule/Vehicule";
import { TCO } from "./TCO/TCO";
import { CustomTable } from "../../components/CustomTable/CustomTable";
import { useNavigate } from "react-router";
import { useGetAllNotification } from "../../api/notification";
import { getCurrentFleetId } from "../../utils/getCurrentFleet";
import { columnsNotification } from "../Notification/ListNotification/notificationColumns";
import { columnsClaimsList } from "../Claims/ListClaims/columnsClaimsList";
import { useGetAllSinisters } from "../../api/sinistre";
type Props = {};

export const Dashboard: React.FC<Props> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { notifications } = useGetAllNotification(getCurrentFleetId());
  const { sinistres } = useGetAllSinisters(getCurrentFleetId());
  return (
    <>
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.container}>
          <div className={classes.containerHeader}>
            <div>
              <CustomTypography
                variant="h2"
                text={t("dashboard.dashboard", "Dashboard")}
                lineHeight={"47px"}
                isUppercase
              />
            </div>
            <div className={classes.divbuttonHeader}>
              <CustomButtonTable
                variant={"primary"}
                textVariant={"link"}
                label={t("collaborator.addACollaborator", "add a collaborator")}
                textTransform={"uppercase"}
                width={"fit-content"}
                fontWeight={"bold"}
                textDecoration={"none"}
                iconName={"plus"}
                onClick={() => navigate("/collaborator/create")}
              />
              <CustomButtonTable
                variant={"primary"}
                textVariant={"link"}
                label={t("vehicule.addVehicule", "Add vehicule")}
                textTransform={"uppercase"}
                width={"fit-content"}
                fontWeight={"bold"}
                textDecoration={"none"}
                iconName={"plus"}
                onClick={() => navigate("/vehicule/create")}
              />
              <CustomButtonTable
                variant={"primary"}
                textVariant={"link"}
                label={t(
                  "panel.claims.toDeclareAsinister",
                  "To declare a sinister"
                )}
                textTransform={"uppercase"}
                width={"fit-content"}
                fontWeight={"bold"}
                textDecoration={"none"}
                iconName={"plus"}
                onClick={() => navigate("/claims/create")}
              />
            </div>
          </div>
          <div className={classes.subContainer}>
            <div className={classes.divLeft}>
              <Vehicule />
              <TCO />
            </div>
            <div className={classes.divRight}>
              <CustomTable
                titleHeader={t("sidebar.notifications", "Notifications")}
                columns={columnsNotification}
                data={notifications}
                tableMaxHeight={"234px"}
                tableMinHeight={"234px"}
                labelButtonFooter={t(
                  "notification.seeAllNotifications",
                  "See all notifications"
                )}
                showPagination={false}
                onClickButtonFooter={() => navigate("/notification/list")}
              />
              <CustomTable
                titleHeader={t("panel.claims.claims", "Claims")}
                columns={columnsClaimsList}
                data={sinistres}
                labelButtonFooter={t(
                  "panel.claims.seeAllClaims",
                  "See all claims"
                )}
                tableMaxHeight={"180px"}
                tableMinHeight={"180px"}
                showPagination={false}
                onClickButtonFooter={() => navigate("/claims/list")}
              />
            </div>
          </div>
        </div>
      </Sidebar>
    </>
  );
};
