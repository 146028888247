import { FC } from "react";
import { Icon } from "../Icon/Icon";
import { colors } from "../../styles/colors";

type props = {
  isActive?: boolean;
};

export const CustomToggle: FC<props> = ({ isActive }) => {
  return (
    <>
      {isActive ? (
        <Icon
          name="toggleRight"
          width={"24px"}
          height={"24px"}
          color={colors.primary}
        />
      ) : (
        <Icon name="toggleLeft" width={"24px"} height={"24px"} color={colors.greydark}/>
      )}
    </>
  );
};
