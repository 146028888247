import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  containerAssignment: {
    width: "33%",
    alignSelf: "center",
  },
  containerInput: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "8px",
    padding: "20px 10px 30px 10px",
    gap: "20px",
    "& :nth-child(2)": {
      marginBottom: 0,
    },
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    justifyContent: "space-between",
  },
});

export default useStyles;
