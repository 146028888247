import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  divTCO: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    padding: "20px",
    gap: "13px",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
    "& .MuiButtonBase-root": {
      alignSelf: "center",
    },
  },
}));

export default useStyles;
