import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

const useStyles = createUseStyles({
  divButton: {
    border: `1px dashed ${colors.primary}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
    borderRadius: "8px",
    width: "103px",
    height: "103px",
    backgroundColor: "transparent",
  },
});

export default useStyles;
