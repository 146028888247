import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomTextField } from "../../components/CustomTextField/CustomTextField";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useRegisterFleetUser } from "../../api/user";
import { useLocation, useNavigate } from "react-router";

type Props = {};

export const GeneratePassword: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");
  const code = queryParams.get("code");
  const company = queryParams.get("company");

  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const { mutate: register, isPending, isSuccess } = useRegisterFleetUser();

  useEffect(() => {
    localStorage?.clear();
  }, []);

  useEffect(() => {
    if (isSuccess && !isPending) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const handleConfirm = async () => {
    if (password.trim().length > 0 && password !== passwordConfirm) {
      toast.error(t("login.errorConfirm", "Passwords do not match"));
    } else {
      const format = /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
      if (!format.test(password)) {
        toast.error(
          t(
            "login.samePassword",
            "A password must contain at least 8 characters, at least one lowercase letter and one uppercase letter, one special character and one number."
          )
        );
      } else {
        await register({
          "password-confirmation": passwordConfirm,
          password,
          code: code || "",
          email: email || "",
          company: company || "",
        });
      }
    }
  };

  return (
    <div className={classes.container}>
      <Logo className={classes.logo} />
      <CustomTypography
        variant="link"
        textDecoration={"none"}
        styles={{ containerText: classes.text }}
        text={t(
          "login.before",
          "Enter the email address associated with your account<br/>to generate a new password then<br/>click on Validate."
        )}
      />
      <div className={classes.subContainer}>
        <CustomTextField
          placeholder={t("login.password", "Password")}
          type={"password"}
          pass
          fullWidth={true}
          onChange={(e) => setPassword(e.target.value)}
        />
        <CustomTextField
          placeholder={t("login.confirmPassword", "Confirm password")}
          type={"password"}
          pass
          fullWidth={true}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />
        <div className={classes.subContainer2}>
          <CustomButton
            onClick={handleConfirm}
            height={"40px"}
            variant={"white"}
            label={t("login.newPassword", "VALIDATE")}
            loading={isPending}
          />
        </div>
      </div>
    </div>
  );
};
