import { useTranslation } from "react-i18next";
import { CustomIconButton } from "../../../components/CustomIconButton/CustomIconButton";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { CustomVehicleInfos } from "../../../components/CustomVehicleInfos/CustomVehicleInfos";
import { colors } from "../../../styles/colors";
import { CustomVehicleContrat } from "../../../components/CustomVehicleContrat/CustomVehicleContrat";
import { CustomCost } from "../../../components/CustomCost/CustomCost";
import useStyles from "./styles";
import { FC } from "react";

type props = {
  onClose?: () => void;
};

export const ResultSearchVehicle: FC<props> = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.divResultSearch}>
      <div className={classes.divTextResult}>
        <CustomTypography
          text={t("common.result", "Result")}
          variant={"default"}
          fontWeight={"bold"}
        />
        <CustomIconButton
          color={colors.BLACK}
          backgroundColor={colors.default}
          widthIcon={"25px"}
          heightIcon={"25px"}
          iconName="close"
          onClick={onClose}
        />
      </div>
      <CustomVehicleInfos />
      <CustomVehicleContrat />
      <div className={classes.divContentTCO}>
        <CustomCost
          label={t("panel.average.averageMonthlyCost", "AVERAGE MONTHLY COST")}
          value={"€ 229,67"}
        />
        <CustomCost
          label={t("panel.average.totalCost", "TOTAL COST")}
          value={"€ 1224,56"}
        />
      </div>
    </div>
  );
};
