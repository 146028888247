import { FC } from "react";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { useTranslation } from "react-i18next";
import { colors } from "../../styles/colors";
import { CustomButtonOutlined } from "../CustomButtonOutlined/CustomButtonOutlined";
import useStyles from "./styles";
import { useParams } from "react-router";
import { useGetContratByVehicleId } from "../../api/contrat";
import { getCurrentFleetId } from "../../utils/getCurrentFleet";
import { CustomIconButton } from "../CustomIconButton/CustomIconButton";
import { useGetOneVehicle } from "../../api/vehicule";
import { getKeyLabelByValue } from "../../utils/types";
import dayjs from "dayjs";

type props = {
  onClickEditIcon?: () => void;
};

export const CustomVehicleContrat: FC<props> = ({ onClickEditIcon }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id = "" } = useParams();
  const vehicleId = parseInt(id);
  const { contrats } = useGetContratByVehicleId(getCurrentFleetId(), vehicleId);
  const { vehicule } = useGetOneVehicle(getCurrentFleetId(), vehicleId);

  return (
    <div className={classes.subContainerContrat}>
      <div className={classes.buttonEdit}>
        <CustomIconButton
          onClick={onClickEditIcon}
          iconName={"pencil"}
          widthIcon={"20px"}
          heightIcon={"20px"}
        />
      </div>

      <div className={classes.flexParent}>
        <div className={classes.containerContrat}>
          <CustomTypography
            variant="h5"
            isUppercase
            text={t("panel.vehicule.contract", "Contract")}
            color={colors.primary}
          />

          <div>
            {contrats?.[0]?.duration?.toString() ? (
              <CustomTypography
                variant="link"
                text={`${contrats?.[0]?.duration?.toString()} mois`}
              />
            ) : (
              <CustomTypography
                variant="link"
                text={t("panel.vehicule.duration", "Duration")}
              />
            )}

            {contrats?.[0]?.start_date ? (
              <CustomTypography
                variant="link"
                text={`${
                  contrats?.[0]?.start_date
                    ? dayjs(contrats[0].start_date).format("DD-MM-YYYY")
                    : ""
                } |&nbsp;
                    ${
                      contrats?.[0]?.end_date
                        ? dayjs(contrats[0].end_date).format("DD-MM-YYYY")
                        : ""
                    }`}
              />
            ) : (
              <CustomTypography
                variant="link"
                text={`${t(
                  "panel.vehicule.dateOfEntry",
                  "Date of entry"
                )} |&nbsp;
                    ${t("panel.vehicule.releaseDate", "Release date")}`}
              />
            )}

            {contrats?.[0]?.rental_tax ? (
              <CustomTypography
                variant="link"
                text={`${t(
                  "panel.vehicule.rentExcludingTax",
                  "Rent exluding tax"
                )} ${contrats?.[0]?.rental_tax} €`}
              />
            ) : (
              <CustomTypography
                variant="link"
                text={t("panel.vehicule.rentExcludingTax", "Rent exluding tax")}
              />
            )}
          </div>
        </div>
        <div>
          <div style={{ marginBottom: "14px" }}>
            <CustomTypography
              isUppercase
              variant="h5"
              text={t("panel.vehicule.statusDone", "Status")}
              color={colors.primary}
            />
          </div>

          <CustomButtonOutlined
            variant="success"
            label={
              getKeyLabelByValue(vehicule?.fleetVehicleStatut ?? "F") ?? ""
            }
            // label={t("panel.vehicule.affected", "Affected")}
            width={"fit-content"}
            padding={"0px 10px"}
          />
        </div>
      </div>
    </div>
  );
};
