import { ChangeEvent, FC, useState } from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { CustomTextDatePicker } from "../../../../components/CustomTextDatePicker/CustomTextDatePicker";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import { CustomSearch } from "../../../../components/CustomSearch/CustomSearch";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useGetAllEmployeeWithoutVehicle } from "../../../../api/employee";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useAssignVehicleToEmployee } from "../../../../api/vehicule";

type Props = {
  onClickSkipThisStep?: () => void;
  onClickSaveAndNext?: () => void;
};

export const StepAssignmentOfTheCollaborator: FC<Props> = ({
  onClickSkipThisStep,
  onClickSaveAndNext,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { employees } = useGetAllEmployeeWithoutVehicle(getCurrentFleetId());
  const newlyCreatedVehicle = useSelector(
    (state: any) => state.vehicleReducer.vehicule
  );

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number | null>(
    null
  );

  const { mutate: assign, isPending } = useAssignVehicleToEmployee(
    getCurrentFleetId(),
    selectedEmployeeId ?? -1,
    newlyCreatedVehicle?.id || -1,
    onClickSaveAndNext
  );

  const onChangeTextFieldEmployee = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.trim().length === 0) {
      setSelectedEmployeeId(null);
      dispatch({
        type: "NEWLY_SELECTED_EMPLOYEE_STEP_CREATE_VEHICULE",
        data: null,
      });
    }
  };

  const onChangeAutoCompletEmployee = (_: any, employee: any) => {
    setSelectedEmployeeId(employee.user_id);
    dispatch({
      type: "NEWLY_SELECTED_EMPLOYEE_STEP_CREATE_VEHICULE",
      data: employee,
    });
  };

  const onSave = () => {
    if (selectedEmployeeId && selectedStartDate && selectedEndDate) {
      assign({
        start_date: dayjs(selectedStartDate).format("YYYY-MM-DD"),
        end_date: dayjs(selectedEndDate).format("YYYY-MM-DD"),
      });
    } else {
      toast.error(t("required.checkFields", "Please check the fields"));
    }
  };

  return (
    <div className={classes.containerAssignment}>
      <div className={classes.containerInput}>
        <CustomSearch
          options={employees}
          getOptionLabel={(user: any) =>
            `${user?.firstname ?? ""} ${user?.lastname ?? ""}`
          }
          label={`${t(
            "vehicule.searchACollaborator",
            "Search a collaborator"
          )} *`}
          placeholder={t("vehicule.searchAName", "Search a name")}
          marginBottom={"0px"}
          onChange={onChangeAutoCompletEmployee}
          onChangeTextField={onChangeTextFieldEmployee}
        />
        <CustomTextDatePicker
          label={`${t(
            "collaborator.assignmentStartDate",
            "Assignment start date"
          )} *`}
          value={selectedStartDate}
          onChange={(value) => setSelectedStartDate(value)}
        />
        <CustomTextDatePicker
          label={`${t(
            "collaborator.assignmentEndDate",
            "Assignment end date"
          )} *`}
          value={selectedEndDate}
          onChange={(value) => setSelectedEndDate(value)}
        />
      </div>
      <div className={classes.containerButton}>
        <CustomButton
          onClick={onClickSkipThisStep}
          label={t("vehicule.skipThisStep", "skipThisStep")}
          variant={"default"}
          width={"fit-content"}
          height={"40px"}
        />
        <CustomButton
          loading={isPending}
          label={t("vehicule.saveAndNext", "Save and next")}
          variant={"primary"}
          width={"fit-content"}
          onClick={onSave}
          height={"40px"}
        />
      </div>
    </div>
  );
};
