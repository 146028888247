import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { Icon } from "../../../../components/Icon/Icon";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { colors } from "../../../../styles/colors";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router";
import {
  useGetSinisterById,
  useGetSinisterDocuments,
} from "../../../../api/sinistre";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
type Props = {};

export const DocumentsClaims: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const sinisterId = parseInt(id);
  const { sinisterDetail } = useGetSinisterById(
    getCurrentFleetId(),
    sinisterId
  );
  const { sinisterDocs } = useGetSinisterDocuments(
    getCurrentFleetId(),
    sinisterDetail?.vehicle_id ?? -1,
    sinisterId
  );

  const onClickEye = (link: string) => {
    window.open(link, "_blank");
  };

  const files = sinisterDocs.filter(
    (file: any) => file.document_type.name === "CST"
  );
  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <CustomTypography
          isUppercase
          text={t("panel.document.documents", "Documents")}
          variant={"h5"}
          styles={{ containerText: classes.contratTitle }}
        />
        {/* <CustomIconButton
          iconName={"pencil"}
          widthIcon={"20px"}
          heightIcon={"20px"}
        /> */}
      </div>
      <div className={classes.scroll}>
        {files.map((document: any, index) => (
          <div className={classes.subContainer} key={index}>
            <div className={classes.subContainer2}>
              <IconWithBackground iconName={"cards"} />
              <div>
                <CustomTypography
                  text={document.name}
                  isUppercase
                  variant="link"
                  fontWeight={"bold"}
                />
                {/* <CustomTypography text={document.subTitle} variant="link" /> */}
              </div>
            </div>
            <IconButton
              className={classes.iconButton}
              onClick={() => onClickEye(document.scan_id)}
            >
              <Icon
                name="eye"
                width={"24px"}
                height={"24px"}
                color={colors.BLACK}
              />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  );
};
