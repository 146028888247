const initialState = {
  sinisterNewlyCreated: null,
};

const sinisterReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "NEWLY_CREATED_SINISTER":
      return {
        ...state,
        sinisterNewlyCreated: action.data,
      };

    default:
      return state;
  }
};

export default sinisterReducer;
