import { FC } from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import { DocumentsPanel } from "../../../../components/DocumentsPanel/DocumentsPanel";
import {
  transformDataCardsAndBadge,
  transformDataCollab,
  transformDataVehicle,
} from "./data";
import { useGetOneEmployee } from "../../../../api/employee";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useGetVehiculesByEmployee } from "../../../../api/vehicule";
import { useWindowSize } from "react-use";
import Confetti from "react-confetti";
import { useGetAllCardsAndBadgeFleetByEmployee } from "../../../../api/badge";

type Props = {
  onClickSave?: () => void;
  onClickPrevious?: () => void;
  currentEmployee: TEmployeeWithoutVehicle | null;
};

export const StepValidationCollab: FC<Props> = ({
  onClickSave,
  onClickPrevious,
  currentEmployee,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { width, height } = useWindowSize();

  const { employee } = useGetOneEmployee(
    getCurrentFleetId(),
    currentEmployee?.user_id ?? -1
  );
  const { vehicules } = useGetVehiculesByEmployee(
    getCurrentFleetId(),
    currentEmployee?.user_id ?? -1
  );

  const { cardsAndBadges } = useGetAllCardsAndBadgeFleetByEmployee(
    getCurrentFleetId(),
    currentEmployee?.id ?? -1
  );

  return (
    <>
      <Confetti width={width} height={height} />
      <div className={classes.container}>
        <div className={classes.subContainer}>
          <div className={classes.containerDocuments}>
            <DocumentsPanel
              title={t("collaborator.collaborator", "Collaborator")}
              descriptions={transformDataCollab(employee)}
              minHeight={"0px"}
              maxHeight={"281px"}
            />
            <DocumentsPanel
              title={t("document.documents", "documents")}
              descriptions={transformDataCardsAndBadge(cardsAndBadges)}
              minHeight={"0px"}
              maxHeight={"281px"}
            />
            <DocumentsPanel
              title={t("vehicule.vehicule", "Vehicle")}
              descriptions={transformDataVehicle(
                vehicules[vehicules.length - 1]
              )}
              minHeight={"0px"}
              maxHeight={"281px"}
            />
          </div>

          <div className={classes.containerButton}>
            <CustomButton
              onClick={onClickPrevious}
              label={t("common.previous", "Previous")}
              variant={"default"}
              width={"fit-content"}
              height={"40px"}
            />
            <CustomButton
              onClick={onClickSave}
              label={t(
                "panel.collaborator.seeCollaborator",
                "See collaborator"
              )}
              variant={"primary"}
              width={"fit-content"}
              height={"40px"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
