import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { CustomTypography } from "../../../../../components/CustomTypography/CustomTypography";
import { CustomButton } from "../../../../../components/CustomButton/CustomButton";
import { IconWithBackground } from "../../../../../components/IconWithBackground/IconWithBackground";
import { IconButton } from "@mui/material";
import { Icon } from "../../../../../components/Icon/Icon";
import { colors } from "../../../../../styles/colors";
import { ChangeEvent, FC, useState } from "react";
import { ModalCarteAndBadge } from "../../../../../containers/ModalCarteAndBadge/ModalCarteAndBadge";
import {
  typeOfCardOrBadge,
  useAssignCardOrBadge,
  useGetAllBadgeFleetUnassigned,
  useGetAllCardsAndBadgeFleetByEmployee,
} from "../../../../../api/badge";
import { getCurrentFleetId } from "../../../../../utils/getCurrentFleet";
import { CustomSearch } from "../../../../../components/CustomSearch/CustomSearch";
import toast from "react-hot-toast";
import { CustomTextDatePicker } from "../../../../../components/CustomTextDatePicker/CustomTextDatePicker";
import dayjs from "dayjs";

type Props = {
  currentEmployee: TEmployeeWithoutVehicle | null;
};

export const Badges: FC<Props> = ({ currentEmployee }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { badges } = useGetAllBadgeFleetUnassigned(getCurrentFleetId());
  const { cardsAndBadges } = useGetAllCardsAndBadgeFleetByEmployee(
    getCurrentFleetId(),
    currentEmployee?.id ?? -1
  );
  const badgesForCurrentEmployee = cardsAndBadges.filter(
    (cards) =>
      cards.fleet_card.fleet_card_type.support_type === typeOfCardOrBadge.badge
  );

  const [startDate, setStartDate] = useState<
    string | number | Date | dayjs.Dayjs | null
  >(null);
  const [endDate, setEndDate] = useState<
    string | number | Date | dayjs.Dayjs | null
  >(null);
  const [selectedBadge, setSelectedBadge] = useState<TBadge | null>(null);

  const onSuccess = () => {
    setSelectedBadge(null);
    setStartDate(null);
    setEndDate(null);
  };

  const { mutate: assignBadge, isPending } = useAssignCardOrBadge(
    getCurrentFleetId(),
    currentEmployee?.id ?? -1,
    onSuccess
  );

  const [open, setOpen] = useState(false);

  const onChangeTextField = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.trim().length === 0) {
      setSelectedBadge(null);
    }
  };

  const onChangeAutocomplet = (_: any, valueTrue: any) => {
    setSelectedBadge(valueTrue as TCard);
  };

  const onClose = () => {
    setOpen(false);
  };

  const assignBadgeButton = () => {
    if (currentEmployee === null) {
      toast.error("Veuillez sélectionner un employé");
      return;
    } else {
      if (startDate === null || selectedBadge === null) {
        toast.error(t("required.fieldRequired", "Fields (*) are required."));
      } else {
        assignBadge({
          badgeOrCardId: selectedBadge.id,
          company_user_id: currentEmployee.id,
          start_date: startDate,
          end_date: endDate,
        });
      }
    }
  };

  return (
    <>
      <ModalCarteAndBadge
        open={open}
        currentCartOrBadge={null}
        supportTypes={typeOfCardOrBadge.badge}
        title={t("collaborator.newBadge", "New badge")}
        onCancel={onClose}
        onClose={onClose}
      />
      <div className={classes.divContentCard}>
        <div className={classes.divAdd}>
          <CustomTypography
            text={t("collaborator.badges", "Badges")}
            variant={"h4"}
            isUppercase
          />
          {/* <CustomIconButton
            iconName={"plus"}
            widthIcon={"30px"}
            heightIcon={"30px"}
            onClick={() => setOpen(true)}
          /> */}
        </div>

        <CustomSearch
          label={`${t("collaborator.badgeNumber", "Badge number")} *`}
          placeholder={"123456789"}
          options={badges}
          onChange={onChangeAutocomplet}
          onChangeTextField={onChangeTextField}
          getOptionLabel={(card: any) =>
            `${card?.number}  - ${card?.cardBrand}` ?? ""
          }
        />
        <CustomTextDatePicker
          label={`${t("common.startDate", "Start date")} *`}
          value={startDate as any}
          onChange={(value) => setStartDate(dayjs(value).format("YYYY-MM-DD"))}
        />
        <CustomTextDatePicker
          label={`${t("common.endDate", "End date")}`}
          value={endDate as any}
          onChange={(value) => setEndDate(dayjs(value).format("YYYY-MM-DD"))}
        />
        <CustomButton
          onClick={assignBadgeButton}
          loading={isPending}
          label={t("collaborator.addBadge", "Add badge")}
          variant={"primary"}
          width={"fit-content"}
          height={"40px"}
        />

        <div>
          <CustomTypography
            text={`${t("collaborator.allBadge", "All badge")} :`}
            variant={"h4"}
            isUppercase
          />
          <div className={classes.divContainerAllCards}>
            {badgesForCurrentEmployee.map((badge) => (
              <div className={classes.subContainerCard} key={badge.id}>
                <div className={classes.subContainer2}>
                  <IconWithBackground iconName={"cards"} />
                  <div>
                    <CustomTypography
                      text={String(badge.fleet_card.number)}
                      isUppercase
                      variant="link"
                      fontWeight={"bold"}
                    />
                    <CustomTypography
                      text={`${badge.fleet_card.fleet_card_type.type} - ${badge.fleet_card.fleet_card_type.marque} <br/> ${badge.fleet_card.release_date}`}
                      variant="link"
                    />
                  </div>
                </div>
                <IconButton className={classes.iconButton}>
                  <Icon
                    name="trash"
                    width={"24px"}
                    height={"24px"}
                    color={colors.error}
                  />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
