import { useQuery } from "@tanstack/react-query";
import axios from "./axios";

export const brandKeys = {
  allBrand: () => ["brands"] as const,
  allModel: (idBrand: number) => ["brands", "models", idBrand] as const,
};

export const useGetAllBrands = () => {
  const { data, error, ...res } = useQuery({
    queryKey: brandKeys.allBrand(),
    queryFn: async (): Promise<TBrand[]> => {
      const data = await axios.get(`/brand/list`);
      return data?.data;
    },
  });

  return { brands: data ?? [], ...res };
};

export const useGetAllModel = (brand_id: number | null) => {
  const { data, error, ...res } = useQuery({
    queryKey: brandKeys.allModel(brand_id ? brand_id : 0),
    queryFn: async (): Promise<TBrandModel[]> => {
      const data = await axios.get(`/brand/model/${brand_id}`);
      return data?.data;
    },
    enabled: brand_id !== null,
  });

  return { brandsModel: data ?? [], ...res };
};
