import { createUseStyles } from "react-jss";
import { colors } from "../../../../../styles/colors";
const useStyles = createUseStyles({
  divContrat: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "25px 20px 25px 20px",
    backgroundColor: colors.WHITE,
    gap: "20px",
    flex: 1,
  },
  headerContrat: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  contratDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  divFormatAccepted: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
});

export default useStyles;
