import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type Props = {
  minHeight?: string | number;
  maxHeight?: string | number;
};

const useStyles = createUseStyles({
  containerSubDocuments: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
    borderRadius: "8px",
    flexBasis: "217px",
    flexGrow: 0,
    flexShrink: 0,
    minHeight: (props: Props) => (props.minHeight ? props.minHeight : "462px"),
    maxHeight: (props: Props) => props.maxHeight,
    backgroundColor: colors.WHITE,
    overflowY: "scroll",
  },
});

export default useStyles;
