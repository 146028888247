import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "./axios";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import dayjs from "dayjs";

export const badgesKeys = {
  all: (fleetId: number, page: number, per_page: number, filter: string) =>
    [
      "badges",
      "idFleet",
      fleetId,
      "page",
      page,
      "per_page",
      per_page,
      "filter",
      filter,
    ] as const,
  allCards: (fleetId: number, page: number, per_page: number, filter: string) =>
    [
      "cards",
      "idFleet",
      fleetId,
      "page",
      page,
      "per_page",
      per_page,
      "filter",
      filter,
    ] as const,
  allUnassignedCards: (fleetId: number) =>
    ["allUnassignedCards", "idFleet", fleetId] as const,
  allUnassignedBadges: (fleetId: number) =>
    ["allUnassignedBadges", "idFleet", fleetId] as const,
  allBadgeByEmployee: (fleetId: number, idEmployee: number) =>
    ["badges", "idFleet", fleetId, "employee", idEmployee] as const,
  allCardsByEmployee: (fleetId: number, idEmployee: number) =>
    ["cards", "idFleet", fleetId, "employee", idEmployee] as const,
  badgeByID: (idBadge: number, fleetId: number) =>
    ["fleetId", fleetId, "idBadge", idBadge] as const,
};

export enum typeOfCardOrBadge {
  card = "card",
  badge = "badge",
}

interface ResponseGetBadge {
  data: {
    data: TBadge[];
    total: number;
  };
}

export const useGetAllBadgeFleet = (
  fleetId: number,
  page: number = 1,
  per_page: number = 10,
  filter: string = ""
) => {
  const { data, error, ...res } = useQuery({
    queryKey: badgesKeys.all(fleetId, page, per_page, filter),
    queryFn: (): Promise<ResponseGetBadge> =>
      axios.get(`/fleets/${fleetId}/badges`, {
        params: {
          page,
          per_page,
          filter: filter.trim().length > 0 ? filter : undefined,
        },
      }),
    staleTime: 0,
  });
  return {
    badges: data?.data?.data ?? [],
    totalCount: data?.data?.total,
    ...res,
  };
};

interface ResponseGetCard {
  data: {
    data: TCard[];
    total: number;
  };
}

export const useGetAllCardsFleet = (
  fleetId: number,
  page: number = 1,
  per_page: number = 10,
  filter: string = ""
) => {
  const { data, error, ...res } = useQuery({
    queryKey: badgesKeys.allCards(fleetId, page, per_page, filter),
    queryFn: (): Promise<ResponseGetCard> =>
      axios.get(`/fleets/${fleetId}/cards`, {
        params: {
          page,
          per_page,
          filter: filter.trim().length > 0 ? filter : undefined,
        },
      }),
    staleTime: 0,
  });
  return {
    cards: data?.data?.data ?? [],
    totalCount: data?.data?.total,
    ...res,
  };
};

export const useGetAllBadgeFleetUnassigned = (fleetId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: badgesKeys.allUnassignedBadges(fleetId),
    queryFn: async (): Promise<TBadge[]> => {
      const data = await axios.get(`/fleets/${fleetId}/badges/unassigned`);
      return data?.data;
    },
    staleTime: 60000,
  });

  return { badges: data ?? [], ...res };
};

export const useGetAllCardsFleetUnassigned = (fleetId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: badgesKeys.allUnassignedCards(fleetId),
    queryFn: async (): Promise<TCard[]> => {
      const data = await axios.get(`/fleets/${fleetId}/cards/unassigned`);
      return data?.data;
    },
    staleTime: 60000,
  });

  return { cards: data ?? [], ...res };
};

export const useGetAllCardsAndBadgeFleetByEmployee = (
  fleetId: number,
  idEmployee: number
) => {
  const { data, error, ...res } = useQuery({
    queryKey: badgesKeys.allBadgeByEmployee(fleetId, idEmployee),
    queryFn: async (): Promise<TCardsAndBadges[]> => {
      const data = await axios.get(
        `/fleets/${fleetId}/badges-card/${idEmployee}`
      );
      return data?.data;
    },
  });

  return { cardsAndBadges: data ?? [], ...res };
};

export const useGetOneBadgeFleet = (fleetId: number, idBadge: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: badgesKeys.badgeByID(fleetId, idBadge),
    queryFn: async (): Promise<TBadge> => {
      const data = await axios.get(`/fleets/${fleetId}/badges/${idBadge}`);
      return data?.data;
    },
  });

  return { badge: data ?? [], ...res };
};

export interface RequestUpdateBadgeOrCard {
  badgeId: number;
  support_type: typeOfCardOrBadge;
  type: string;
  marque: string;
  release_date: string;
  number: number;
  billing_periodicity: number;
}

export const useUpdateBadgeOrCard = (
  fleetId: number,
  onSuccess?: () => void
) => {
  const { t } = useTranslation();
  const { refetch } = useGetAllBadgeFleet(fleetId);
  const { refetch: refetchCard } = useGetAllCardsFleet(fleetId);
  return useMutation({
    mutationFn: async (updateBadge: RequestUpdateBadgeOrCard) => {
      const data = await axios.put(
        `/fleets/${fleetId}/badges/${updateBadge.badgeId}`,
        updateBadge
      );
      return data.data;
    },
    onSuccess: (_) => {
      refetch();
      refetchCard();
      toast.success(t("common.changeSaved", "Change saved"));
      onSuccess && onSuccess();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export interface RequestCreateBadge {
  support_type: typeOfCardOrBadge;
  type: string;
  marque: string;
  release_date: string;
  number: string;
  billing_periodicity: number;
}

export const useCreateBadgeOrCards = (
  fleetId: number,
  onSuccess?: () => void
) => {
  const { t } = useTranslation();
  const { refetch } = useGetAllBadgeFleet(fleetId);
  const { refetch: refetchCard } = useGetAllCardsFleet(fleetId);

  return useMutation({
    mutationFn: async (createBadge: RequestCreateBadge) => {
      const data = await axios.post(`/fleets/${fleetId}/badges`, createBadge);
      return data.data?.badge;
    },
    onSuccess: (_) => {
      refetch();
      refetchCard();
      onSuccess && onSuccess();
      toast.success(t("common.save", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export interface RequestAssignBadge {
  company_user_id: number;
  badgeOrCardId: number;
  start_date: string | number | Date | dayjs.Dayjs | null;
  end_date: string | number | Date | dayjs.Dayjs | null;
}

export const useAssignCardOrBadge = (
  fleetId: number,
  currentEmployeeId: number,
  onSuccess?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (updateBadge: RequestAssignBadge) => {
      const data = await axios.post(
        `/fleets/${fleetId}/badges/${updateBadge.badgeOrCardId}/assign`,
        updateBadge
      );
      return data.data?.badge;
    },
    onSuccess: (_) => {
      toast.success(t("common.save", "Save"));
      const keysCardsOrbadges = badgesKeys.allBadgeByEmployee(
        fleetId,
        currentEmployeeId
      );
      queryClient.refetchQueries(keysCardsOrbadges as any);

      const keysallUnassignedCards = badgesKeys.allUnassignedCards(fleetId);
      queryClient.refetchQueries(keysallUnassignedCards as any);

      const keysallUnassignedBadges = badgesKeys.allUnassignedBadges(fleetId);
      queryClient.refetchQueries(keysallUnassignedBadges as any);

      onSuccess && onSuccess();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export interface RequestDeleteBadgeOrCard {
  badgeId: number;
}

export const useDeleteBadgeOrCard = (
  fleetId: number,
  onSuccess?: () => void
) => {
  const { t } = useTranslation();
  const { refetch } = useGetAllBadgeFleet(fleetId);
  const { refetch: refetchCard } = useGetAllCardsFleet(fleetId);

  return useMutation({
    mutationFn: async (deleteCardOrBadge: RequestDeleteBadgeOrCard) => {
      const data = await axios.delete(
        `/fleets/${fleetId}/badges/${deleteCardOrBadge.badgeId}`
      );
      return data.data;
    },
    onSuccess: (_) => {
      refetch();
      refetchCard();
      toast.success(t("common.changeSaved", "Change saved"));
      onSuccess && onSuccess();
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};
