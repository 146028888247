import i18n from "../../i18n/i18n";

export const items = [
  {
    label: i18n.t("sidebar.dashboard", "Dashboard"),
    iconName: "dashboard",
    width: 24,
    height: 24,
    to: "/dashboard",
  },
  {
    label: i18n.t("sidebar.colaborators", "Colaborators"),
    iconName: "collaborateur",
    width: 24,
    height: 24,
    to: "/collaborator/list",
  },
  {
    label: i18n.t("sidebar.car", "Car"),
    iconName: "vehicule",
    width: 24,
    height: 24,
    to: "/vehicule/list",
  },
  {
    label: i18n.t("sidebar.notifications", "Notifications"),
    iconName: "notification",
    width: 24,
    height: 24,
    to: "/notification/list",
  },
  {
    label: i18n.t("sidebar.claims", "Claims"),
    iconName: "sinistre",
    width: 24,
    height: 24,
    to: "/claims/list",
  },

  {
    label: i18n.t("sidebar.CardsAndBadges", "Cards & badges"),
    iconName: "certificate",
    width: 24,
    height: 24,
    to: "/carteAndBadge",
  },
  // {
  //   label: i18n.t("sidebar.TCO", "TCO"),
  //   iconName: "license",
  //   width: 24,
  //   height: 24,
  //   // to: "/tco",
  // },
  {
    label: i18n.t("sidebar.conduct", "Conduct"),
    iconName: "steeringWheel",
    width: 24,
    height: 24,
  },
  {
    label: i18n.t("sidebar.geolocation", "Geolocation"),
    iconName: "map2",
    width: 24,
    height: 24,
  },
  {
    label: i18n.t("sidebar.services", "Services"),
    iconName: "stars",
    width: 24,
    height: 24,
  },

  {
    label: i18n.t("sidebar.helper", "Helper"),
    iconName: "help",
    width: 24,
    height: 24,
  },
  {
    label: i18n.t("sidebar.setting", "Setting"),
    iconName: "settings",
    width: 24,
    height: 24,
    to: "/parametre",
  },
  {
    label: i18n.t("sidebar.logout", "Logout"),
    iconName: "logout",
    width: 24,
    height: 24,
  },
];
