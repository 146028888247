import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "./axios";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { employeeKeys } from "./employee";

export const departmentsKeys = {
  all: (idCompanie: number) => ["departments", "companie", idCompanie] as const,
};

export const useGetAllDepartments = (fleedId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: departmentsKeys.all(fleedId),
    queryFn: async (): Promise<TDepartment[]> => {
      const data = await axios.get(`/companies/${fleedId}/departments`);
      return data?.data;
    },
  });

  return { departments: data ?? [], ...res };
};

export interface RequestAssignDepartmentCollab {
  department_id: number;
}

export const useAssignDepartementCollab = (
  companyId: number,
  userId: number
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newDepartment: RequestAssignDepartmentCollab) => {
      const data = await axios.put(
        `/companies/${companyId}/employees/${userId}/update-department`,
        newDepartment
      );
      return data.data;
    },
    onSuccess: (_) => {
      const keys = employeeKeys.employeeByID(companyId, userId);
      queryClient.refetchQueries(keys as any);
      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};
