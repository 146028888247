import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { Icon } from "../../../../components/Icon/Icon";
import { CustomDropZoneUpload } from "../../../../components/CustomDropZoneUpload/CustomDropZoneUpload";
import { CustomButtonDropZone } from "../../../../components/CustomButtonDropZone/CustomButtonDropZone";
import { colors } from "../../../../styles/colors";
import { useSelector } from "react-redux";
import { useSinisterAddImages } from "../../../../api/sinistre";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { uploadFileMultiple } from "../../../../utils/firebaseConfig";

type Props = {
  onClickPrevious?: () => void;
  onClickSaveAndNext?: () => void;
};

export const StepDetailsAddPictures: FC<Props> = ({
  onClickPrevious,
  onClickSaveAndNext,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { vehicle_id, id: sinisterId } = useSelector(
    (state: any) => state.sinisterReducer.sinisterNewlyCreated
  );

  const { mutate: addImages } = useSinisterAddImages(
    getCurrentFleetId(),
    vehicle_id,
    sinisterId,
    onClickSaveAndNext
  );

  const [isLoading, setIsLoading] = useState(false);

  const [customDropZones, setCustomDropZones] = useState<(File | null)[]>([
    null,
    null,
    null,
  ]);

  const handleAddCustomDropZone = () => {
    setCustomDropZones((prevDropZones) => [
      ...prevDropZones,
      null, // Ajouter un emplacement vide pour un fichier
    ]);
  };

  const onDrop = (acceptedFiles: File[]) => {
    setCustomDropZones((prevDropZones) => {
      let newDropZones = [...prevDropZones];
      let fileIndex = 0;

      // Trouver les indices null et les remplacer par les fichiers acceptés
      newDropZones = newDropZones.map((dropZone) => {
        if (dropZone === null && fileIndex < acceptedFiles.length) {
          const fileToAdd = acceptedFiles[fileIndex];
          fileIndex += 1;
          return fileToAdd;
        }
        return dropZone;
      });

      // Si tous les null sont remplacés et qu'il reste des fichiers, les ajouter à la fin
      if (fileIndex < acceptedFiles.length) {
        newDropZones = newDropZones.concat(acceptedFiles.slice(fileIndex));
      }

      return newDropZones;
    });
  };

  const onSaveAndNext = async () => {
    const newFileToUpload = customDropZones.filter((file) => file !== null);

    if (newFileToUpload.length > 0) {
      setIsLoading(true);
      try {
        // Attendre ici que Promise.all soit résolu avant de continuer
        const dataToSet = await Promise.all(
          newFileToUpload.map(async (file) => {
            const urlUploadedFile = await uploadFileMultiple(file);
            return urlUploadedFile as string;
          })
        );
        addImages({ imgUrls: dataToSet });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.divPhoto}>
        <div className={classes.divSubPhoto}>
          {/* left */}
          <div className={classes.divLeft}>
            <CustomTypography
              text={t("claims.pictures", "Pictures")}
              variant="h4"
              isUppercase
            />
            <div className={classes.divFormat}>
              <div>
                <CustomTypography
                  text={`${t("common.acceptedFormats", "Accepted formats")} :`}
                  variant="link"
                  fontWeight={"bold"}
                />
                <CustomTypography text={"PNG - JPG - JPEG"} variant="link" />
              </div>
              <div>
                <CustomTypography
                  text={`${t("common.maxSize", "Max size")} :`}
                  variant="link"
                  fontWeight={"bold"}
                />
                <CustomTypography text={"10 Mo"} variant="link" />
              </div>
            </div>
            <CustomTypography
              text={t(
                "claims.instructions",
                "Please upload your photo. Click on + to add more or on the delete icon to delete all."
              )}
              variant="link"
              color={colors.greydark}
            />
          </div>
          {/* right */}
          <div className={classes.divRight}>
            <div
              className={classes.divTrash}
              onClick={() => setCustomDropZones([null, null, null])}
            >
              <Icon name={"trash"} width={"24px"} height={"24px"} />
            </div>
            <div className={classes.divAllPictures}>
              {customDropZones &&
                customDropZones.map((file, index) => {
                  return (
                    <CustomDropZoneUpload
                      key={index}
                      width={"100px"}
                      height={"100px"}
                      iconHeight={"25.33px"}
                      iconWidth={"25.33px"}
                      borderRadius={"8px"}
                      uploadedFile={file}
                      onDrop={onDrop}
                    />
                  );
                })}
              <CustomButtonDropZone
                handleAddCustomDropZone={handleAddCustomDropZone}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={classes.containerAllButton}>
        <CustomButton
          onClick={onClickPrevious}
          label={t("common.previous", "Previous")}
          variant={"default"}
          width={"fit-content"}
          height={"40px"}
        />
        <div className={classes.containerButton}>
          <CustomButton
            label={t("common.saveButton", "Save")}
            variant={"default"}
            width={"fit-content"}
            height={"40px"}
          />
          <CustomButton
            label={t("common.saveAndNext", "Save and next")}
            variant={"primary"}
            width={"fit-content"}
            onClick={onSaveAndNext}
            height={"40px"}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
