import { FC, useEffect, useState } from "react";
import useStyles from "./styles";
import { motion, AnimatePresence } from "framer-motion";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { colors } from "../../styles/colors";

type Props = {
  label: string;
  value: string;
  backgroundColor?: string;
  width?: string | number;
  height?: string | number;
};

const characters = "0123456789";

const generateString = (length: number) => {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const CustomCardInfo: FC<Props> = ({
  label,
  value,
  backgroundColor,
  height,
  width,
}) => {
  const classes = useStyles({ backgroundColor, width, height });

  const [animationCompleted, setAnimationCompleted] = useState(false);
  const [odometerLetters, setOdometerLetters] = useState<string[]>([]);

  const variants = {
    spin: {
      y: "calc(-100% + 51px)",
    },
    initial: {
      y: "calc(0% - 4px)",
    },
  };

  useEffect(() => {
    // legnth of the odometer
    const odometerWord = value.length > 0 ? value : "0";
    const letterArray = odometerWord.split("");
    const odometerArray: string[] = [];

    letterArray.forEach((letter, index) => {
      const letters = generateString((index + 1) * 3) + letter;
      odometerArray.push(letters);
    });
    setOdometerLetters(odometerArray);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createOdometer = () => {
    return odometerLetters.map((letter, index) => {
      return (
        <AnimatePresence>
          <motion.div
            key={index}
            variants={variants}
            initial="initial"
            animate="spin"
            className={classes.item}
            transition={{
              duration: 2,
            }}
            onAnimationComplete={() => {
              setAnimationCompleted(true);
            }}
          >
            {!animationCompleted && (
              <CustomTypography
                text={letter}
                variant="h3"
                color={colors.darkblack}
              />
            )}
          </motion.div>
        </AnimatePresence>
      );
    });
  };

  return (
    <div className={classes.divCardinfo}>
      <CustomTypography text={label} variant="link" color={colors.darkblack} />
      {!animationCompleted && (
        <div className={classes.odometer}>{createOdometer()}</div>
      )}
      {animationCompleted && (
        <CustomTypography text={value} variant="h3" color={colors.darkblack} />
      )}
    </div>
  );
};
