import { faker } from "@faker-js/faker";

export const columnsClaims = [
  { header: "Date", field: "date" },
  { header: "Nom", field: "name" },
  { header: "Statut", field: "status" },
];

export const dataClaims = [
  {
    date: "02-02-2023",
    name: "Nom du sinistre 001",
    status: "STATUT_01",
  },
  {
    date: "02-02-2023",
    name: "Nom du sinistre 002",
    status: "STATUT_01",
  },
  {
    date: "02-02-2023",
    name: "Nom du sinistre 003",
    status: "STATUT_01",
  },
  {
    date: "02-02-2023",
    name: "Nom du sinistre 004",
    status: "STATUT_01",
  },
  {
    date: "02-02-2023",
    name: "Nom du sinistre 005",
    status: "STATUT_01",
  },
  {
    date: "02-02-2023",
    name: "Nom du sinistre 006",
    status: "STATUT_01",
  },
  {
    date: "02-02-2023",
    name: "Nom du sinistre 007",
    status: "STATUT_01",
  },
];

export const generateFakeData = () => {
  const fakeData = [];
  for (let i = 1; i <= 15; i++) {
    const newEntry = {
      id: i,
      date: faker.date
        .between("2023-01-01", "2023-12-31")
        .toLocaleDateString("fr-FR"),
      hours: faker.date
        .between("2023-01-01T00:00:00", "2023-01-01T23:59:59")
        .toLocaleTimeString("fr-FR", {
          hour: "2-digit",
          minute: "2-digit",
        }),
    };
    fakeData.push(newEntry);
  }
  return fakeData;
};
