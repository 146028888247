import dayjs from "dayjs";
import i18n from "../../../../i18n/i18n";

export const transformDataSinister = (
  sinister: TSinister | undefined,
  sinisterEmployee: TSinisterEmployee | undefined
) => {
  return [
    { subTitle: "Nom", description: sinister?.name ?? "" },
    {
      subTitle: "Date",
      description: sinister?.sinister_at
        ? dayjs(sinister.sinister_at).format("DD-MM-YYYY")
        : "",
    },
    { subTitle: "Véhicule", description: sinister?.number ?? "" },
    {
      subTitle: "Collaborateur",
      description: `${sinisterEmployee?.firstname ?? ""} ${
        sinisterEmployee?.lastname ?? ""
      }`,
    },
  ];
};

export const transformDataSinisterDetail = (
  sinister: TSinister | undefined
) => {
  return [
    {
      subTitle: "Date du sinistre",
      description: sinister?.sinister_at
        ? dayjs(sinister.sinister_at).format("DD-MM-YYYY")
        : "",
    },
    {
      subTitle: "Commentaire",
      description: sinister?.description ?? "",
    },
  ];
};

export const transformDataVehicle = (vehicle: TVehiculeOne | undefined) => {
  return [
    {
      subTitle: i18n.t("vehicule.vin", "Vin"),
      description: `${vehicle?.vin ?? ""}`,
    },
    {
      subTitle: "Immatriculation",
      description: `${vehicle?.immatriculation ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.brand", "Brand"),
      description: `${vehicle?.brand ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.model", "Model"),
      description: `${vehicle ? JSON.parse(vehicle?.brandModel)?.name : ""}`,
    },
  ];
};
