import i18n from "../../../../i18n/i18n";

export const dataDocuments = [
  {
    id: "1",
    icoName: "license",
    title: i18n.t("panel.document.contract", "Contract"),
    subTitle: "",
  },
  // {
  //   icoName: "cards",
  //   title: i18n.t("panel.document.card", "Card"),
  //   subTitle: "",
  // },
  // {
  //   icoName: "certificate",
  //   title: i18n.t("panel.document.badge", "Badge"),
  //   subTitle: "",
  // },
  {
    id: "2",
    icoName: "cards",
    title: i18n.t("panel.document.greyCard", "Grey card"),
    subTitle: "",
  },
  {
    id: "3",
    icoName: "shieldCheck",
    title: i18n.t("panel.document.insurance", "Insurance"),
    subTitle: "",
  },
];
