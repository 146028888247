import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  containerHistorique: {
    borderRadius: "15px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    backgroundColor: colors.WHITE,
  },
  historiqueContent: {
    maxHeight: "230px",
    minHeight: "230px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    overflow: "auto",
  },
}));

export default useStyles;
