import useStyles from "./styles";

import { CustomVehicleInfos } from "../../../../components/CustomVehicleInfos/CustomVehicleInfos";
import { CustomVehicleContrat } from "../../../../components/CustomVehicleContrat/CustomVehicleContrat";
import { ModalUpdateVehicleContrat } from "./ModalUpdateVehicleContrat";
import { useState } from "react";
import { ModalUpdateVehicle } from "./ModalUpdateVehicle";

type Props = {};

export const VehiculeSection: React.FC<Props> = () => {
  const classes = useStyles();
  const [openModalEditVehicleContrat, setOpenModalEditVehicleContrat] =
    useState(false);

  const [openModalEditVehicle, setOpenModalEditVehicle] = useState(false);

  const onClose = () => {
    setOpenModalEditVehicleContrat(false);
    setOpenModalEditVehicle(false);
  };

  return (
    <>
      <ModalUpdateVehicleContrat
        opeModal={openModalEditVehicleContrat}
        onCancel={onClose}
        onClose={onClose}
      />
      <ModalUpdateVehicle
        opeModal={openModalEditVehicle}
        onCancel={onClose}
        onClose={onClose}
      />
      <div className={classes.info}>
        <CustomVehicleInfos
          isEditableIcon
          onClickEditIcon={() => setOpenModalEditVehicle(true)}
        />
        <CustomVehicleContrat
          onClickEditIcon={() => setOpenModalEditVehicleContrat(true)}
        />
      </div>
    </>
  );
};
