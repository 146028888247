import i18n from "../../../../i18n/i18n";

export const dataDocuments = [
  {
    id: "1",
    icoName: "cards",
    title: i18n.t("collaborator.idCard", "Id card"),
    subTitle: "",
  },
  {
    id: "2",
    icoName: "certificate",
    title: i18n.t("collaborator.liscence", "Liscence"),
    subTitle: "",
  },
];
