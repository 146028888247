import { Step, StepLabel, Stepper } from "@mui/material";
import useStyles from "./styles";
type Props = {
  steps?: string[];
  activeStep?: number;
};

export const CustomStepper: React.FC<Props> = ({ steps, activeStep }) => {
  const classes = useStyles({ isLastStep: steps?.length === activeStep });

  return (
    <div className={classes.containerStepper}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps?.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
