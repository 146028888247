import { FormControl } from "@mui/material";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import useStyles from "./styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/fr";
import {
  DateValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
type Props = {
  label?: string;
  marginBottom?: string | number;
  value?: Date | null;
  onChange?: (
    value: Date | null,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => void;
};

export const CustomTextDatePicker: React.FC<Props> = ({
  label,
  marginBottom,
  value,
  onChange,
}) => {
  const classes = useStyles({ marginBottom });

  return (
    <div className={classes.container}>
      {label && (
        <div className={classes.containerLabel}>
          <CustomTypography
            text={label}
            variant={"link"}
            textDecoration={"none"}
          />
        </div>
      )}
      <FormControl className={classes.formControl} variant="outlined">
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
          <DatePicker value={value} format="DD/MM/YYYY" onChange={onChange} />
        </LocalizationProvider>
      </FormControl>
    </div>
  );
};
