import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 0px 15px 0px",
  },
  vehiculeRoot: {
    padding: "30px 30px",
    overflowY: "auto",
    height: "calc(100vh - 60px)",
    backgroundColor: colors.bgcolor,
  },
  buttonsCollab: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: "15px",
    width: "100%",
  },
});

export default useStyles;
