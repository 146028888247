import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "30px",
    alignItems: "center",
    justifyContent: "center",
    padding: "25px 15px 25px 15px",
    "& > div": {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiOutlinedInput-root": {
      width: "434px",
    },
  },
  containerSearch: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
    gap: "15px",
    width: "33%",
  },

  divLeft: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    gap: "30px",
  },

  subDivInvit: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  divSendEmail: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "15px 0px 15px 0px",
    "& > div": {
      margin: 0,
    },
  },
  divSendEmail2: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    alignItems: "center",
  },

  divText: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
    "& > div": {
      padding: "0px 25px",
    },
  },
  divTextDisplay: {
    width: "100%",
    height: "40px",
    borderRadius: "50px",
    border: `2px solid ${colors.WHITE}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 25px",
  },
});

export default useStyles;
