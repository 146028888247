import * as Yup from "yup";

export const validationSchemaCreateVehicle = Yup.object().shape({
  vin: Yup.string().nonNullable().required("Vin is required"),
  immatriculation: Yup.string()
    .nonNullable()
    .required("Immatriculation is required"),
  brand: Yup.string().nonNullable().required("Brand is required"),
  modele: Yup.string().nonNullable().required("Modele is required"),
  gearBox: Yup.string().nonNullable().required("Gearbox is required"),
  fiscalPower: Yup.string().nonNullable().required("Fiscal power is required"),
  energy: Yup.string().nonNullable().required("Energy power is required"),
  releaseDate: Yup.string().nonNullable().required("Release power is required"),
  status: Yup.string().nonNullable().required("Status power is required"),
  isLeasing: Yup.string().required(
    "Please select whether it is leasing or not"
  ),
  // Ajoutez d'autres champs de validation si nécessaire
});

interface FormValuesVehicle {
  vin: string | null;
  immatriculation: string | null;
  brand: string | null;
  modele: string | null;
  gearBox: string | null;
  fiscalPower: string | null;
  energy: string | null;
  releaseDate: string | null;
  status: string | null;
  isLeasing: string | null;
}

export const initialValuesVehicle = {
  vin: null,
  immatriculation: null,
  brand: null,
  modele: null,
  gearBox: null,
  fiscalPower: null,
  energy: null,
  releaseDate: null,
  status: null,
  isLeasing: null,
} as FormValuesVehicle;
