import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles({
  divInformationGeneral: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    padding: "15px",
    gap: "15px",
    backgroundColor: colors.WHITE,
  },
  textInformationGeneral: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  divInfos: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    "& > div": { flex: 1 },
  },
  divInfos2: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
});

export default useStyles;
