import * as Yup from "yup";

export const validationSchemaCreateVehicleDocument = Yup.object().shape({
  cardNumber: Yup.string().nonNullable().required("Required field"),
  policeNumber: Yup.string().nonNullable().required("Required field"),
  cost: Yup.string().nonNullable().required("Required field"),
  periodicity: Yup.string().nonNullable().required("Required field"),
  // Ajoutez d'autres champs de validation si nécessaire
});

interface FormValuesVehicleDocument {
  cardNumber: string | null;
  policeNumber: string | null;
  cost: string | null;
  periodicity: string | null;
}

export const initialValuesVehicleDocument = {
  cardNumber: null,
  policeNumber: null,
  cost: null,
  periodicity: null,
} as FormValuesVehicleDocument;
