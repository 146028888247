import { useParams } from "react-router";
import { CustomButtonModal } from "../../../../components/CustomButtonModal/CustomButtonModal";
import { CustomButtonTable } from "../../../../components/CustomButtonTable/CustomButtonTable";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { transformDataMileages } from "./data";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { useGetContratByVehicleId } from "../../../../api/contrat";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";

type Props = {};

export const Mileage: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const vehicleId = parseInt(id);
  const { contrats } = useGetContratByVehicleId(getCurrentFleetId(), vehicleId);

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <CustomTypography
          isUppercase
          text={t("panel.mileage.mileage", "Mileage")}
          variant={"h5"}
          styles={{ containerText: classes.contratTitle }}
        />
        {/* <CustomIconButton
          iconName={"pencil"}
          widthIcon={"20px"}
          heightIcon={"20px"}
        /> */}
      </div>
      {transformDataMileages(contrats[0]).map((document, index) => (
        <div className={classes.subContainer} key={index}>
          <div className={classes.subContainer2}>
            <IconWithBackground iconName={document.icoName} />
            <div>
              <CustomTypography
                text={document.title}
                isUppercase
                variant="link"
                fontWeight={"bold"}
              />
              <CustomTypography text={document.subTitle} variant="link" />
            </div>
          </div>
        </div>
      ))}
      <div className={classes.buttonContainer}>
        <CustomButtonTable
          // variant={"secondary"}
          variant={'default'}
          textVariant={"link"}
          label={t(
            "panel.mileage.relaunchTheEmployee",
            "Relaunch the employee"
          )}
          width={"fit-content"}
          fontWeight={"bold"}
          textDecoration={"none"}
          iconName={"bell"}
          padding={"0px 10px 0px 10px;"}
        />
        <CustomButtonModal
          variant={"default"}
          textVariant={"link"}
          label={t("panel.mileage.seeOtherStatements", "See other statements")}
          width={"fit-content"}
          textDecoration={"none"}
          iconName={"chevronRight"}
          padding={"0px 10px 0px 10px;"}
        />
      </div>
    </div>
  );
};
