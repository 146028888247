import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "./axios";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { employeeKeys } from "./employee";
import { useDispatch } from "react-redux";
import { transformDate } from "../utils/date";
import { TCOKeys } from "./tco";

export const vehiculesKeys = {
  all: (
    fleetId: number,
    page: number,
    per_page: number,
    filter: string,
    status: string
  ) =>
    [
      "vehicles",
      "fleetId",
      fleetId,
      "page",
      page,
      "per_page",
      per_page,
      "filter",
      filter,
      "status",
      status,
    ] as const,
  vehiculesByEmployee: (fleetId: number, employeeId: number) =>
    ["vehicules", "idFleet", fleetId, "employee", employeeId] as const,
  documents: (vehicleId: number) =>
    ["vehicule", "documents", "vehicleId", vehicleId] as const,
  events: (vehicleId: number) =>
    ["vehicule", "events", "vehicleId", vehicleId] as const,
  eventsById: (vehicleId: number, eventsId: number) =>
    ["vehicule", vehicleId, "events", eventsId] as const,
  eventsDocuments: (vehicleId: number, eventsId: number) =>
    ["vehicule", vehicleId, "events", eventsId, "documents"] as const,
  eventsDocumentsById: (
    vehicleId: number,
    eventsId: number,
    documentId: number
  ) =>
    [
      "vehicule",
      vehicleId,
      "events",
      eventsId,
      "documents",
      documentId,
    ] as const,
  vehicule: (vehicleId: number) => ["vehicule", "id", vehicleId] as const,
  vehiculeNumber: (fleetId: number) =>
    ["vehiculeNumber", "id", fleetId] as const,
};

interface ResponseGetVehicules {
  data: {
    data: TVehicule[];
    total: number;
  };
}

export const useGetAllVehiculesFleet = (
  fleetId: number,
  page: number = 1,
  per_page: number = 10,
  filter: string = "",
  status: string = ""
) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesKeys.all(fleetId, page, per_page, filter, status),
    queryFn: (): Promise<ResponseGetVehicules> =>
      axios.get(`/fleets/${fleetId}/vehicles`, {
        params: {
          page,
          per_page,
          filter: filter.trim().length > 0 ? filter : undefined,
          status: status.trim().length > 0 ? status : undefined,
        },
      }),
    staleTime: 0,
  });
  return {
    vehicules: data?.data?.data ?? [],
    totalCount: data?.data?.total,
    ...res,
  };
};

export const useGetOneVehicle = (fleetId: number, vehicleId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesKeys.vehicule(vehicleId),
    queryFn: async (): Promise<TVehiculeOne> => {
      const data = await axios.get(`/fleets/${fleetId}/vehicles/${vehicleId}`);
      return data?.data;
    },
  });
  return { vehicule: data, ...res };
};

export const useGetVehiculesByEmployee = (
  fleetId: number,
  employeeId: number
) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesKeys.vehiculesByEmployee(fleetId, employeeId),
    queryFn: async (): Promise<TVehicule[]> => {
      const data = await axios.get(
        `/fleets/${fleetId}/employees/${employeeId}/vehicles`
      );
      return data?.data;
    },
  });

  return { vehicules: data ?? [], ...res };
};

interface RequestUpdateStatusVehicle {
  status: number;
}

export const useUpdateStatusVehicle = (fleetId: number, vehiculeId: number) => {
  const { t } = useTranslation();
  const { refetch } = useGetAllVehiculesFleet(fleetId);
  return useMutation({
    mutationFn: async (updateStatus: RequestUpdateStatusVehicle) => {
      const data = await axios.put(
        `/fleets/${fleetId}/vehicle/${vehiculeId}/status`,
        updateStatus
      );
      return data.data?.vehicule;
    },
    onSuccess: (_) => {
      refetch();
      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export interface RequestCreateVehicule {
  // required
  vin: string;
  number: string;
  owner_company_id?: number;
  brand_model_id: number;
  vehicle_status: string;
  monthly_average_cost: number;
  // not required
  identification_id?: number;
  picture?: string;
  type?: string;
  gravage?: string;
  register_step?: number;
  boite?: string;
  puissance?: string;
  carburant?: string;
  mise_en_circulation?: string;
  commissioning_date?: string;
}

export const useCreateVehicule = (fleetId: number, onSave?: () => void) => {
  const { t } = useTranslation();
  const { refetch } = useGetAllVehiculesFleet(fleetId);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async (createVehicule: RequestCreateVehicule) => {
      const data = await axios.post(
        `companies/${fleetId}/vehicles/add`,
        createVehicule
      );
      return data.data;
    },
    onSuccess: (data) => {
      refetch();

      const statusKeys = TCOKeys.vehicles(fleetId);
      queryClient.refetchQueries(statusKeys as any);

      toast.success(t("common.save", "Change saved"));

      dispatch({ type: "NEWLY_CREATED_VEHICULE", data: data });
      onSave && onSave();
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorAddVehicle.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

interface RequestUpdateVehiculeFleet {
  vehicle_status: string;
  monthly_average_cost: number;
}

export const useUpdateVehiculeFleet = (fleetId: number, vehiculeId: number) => {
  const { t } = useTranslation();
  const { refetch } = useGetAllVehiculesFleet(fleetId);
  return useMutation({
    mutationFn: async (updateVehicule: RequestUpdateVehiculeFleet) => {
      const data = await axios.put(
        `companies/${fleetId}/vehicles/${vehiculeId}/update`,
        updateVehicule
      );
      return data.data;
    },
    onSuccess: (_) => {
      refetch();
      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorAddVehicle.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

interface RequestUpdateVehicule {
  // required
  vin: string;
  number: string;
  brand_model_id: number;
  // not required
  identification_id?: number;
  picture?: string;
  type?: string;
  gravage?: string;
  register_step?: number;
  boite?: string;
  puissance?: string;
  carburant?: string;
  mise_en_circulation?: string;
  commissioning_date?: string;
}

export const useUpdateVehicule = (
  fleetId: number,
  vehiculeId: number,
  onSuccess?: () => void
) => {
  const { t } = useTranslation();
  const { refetch } = useGetAllVehiculesFleet(fleetId);
  return useMutation({
    mutationFn: async (updateVehicule: RequestUpdateVehicule) => {
      const data = await axios.put(
        `/vehicle/update/${vehiculeId}`,
        updateVehicule
      );
      return data.data;
    },
    onSuccess: (_) => {
      refetch();
      toast.success(t("common.changeSaved", "Change saved"));
      onSuccess && onSuccess();
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorAddVehicle.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export const useDeleteVehicule = (fleetId: number, vehiculeId: number) => {
  const { t } = useTranslation();
  const { refetch } = useGetAllVehiculesFleet(fleetId);
  return useMutation({
    mutationFn: async () => {
      const data = await axios.delete(`/vehicles/${vehiculeId}`);
      return data.data;
    },
    onSuccess: (_) => {
      refetch();

      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useGetAllVehiculesDocuments = (vehicleId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesKeys.documents(vehicleId),
    queryFn: async (): Promise<TDocument[]> => {
      const data = await axios.get(`/vehicles/${vehicleId}/documents`);
      return data?.data;
    },
  });

  return { documents: data ?? [], ...res };
};

export const useGetAllVehiculesEvents = (vehicleId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesKeys.documents(vehicleId),
    queryFn: async (): Promise<TEvent[]> => {
      const data = await axios.get(`/vehicles/${vehicleId}/events`);
      return data?.data;
    },
  });

  return { events: data ?? [], ...res };
};

export const useGetVehiculesEventsById = (
  vehicleId: number,
  eventsId: number
) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesKeys.eventsById(vehicleId, eventsId),
    queryFn: async (): Promise<TEvent> => {
      const data = await axios.get(`/vehicles/${vehicleId}/events/${eventsId}`);
      return data?.data;
    },
  });

  return { event: data ?? {}, ...res };
};

interface RequestCreateVehiculeEvents {
  name: string;
  datetime: string;
  user_id: number;
  event_type_id: number;
  address_id: number;
  note: string;
  url_report: string;
}

export const useCreateVehiculeEvents = (vehicleId: number) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (createVehiculeEvent: RequestCreateVehiculeEvents) => {
      const data = await axios.post(
        `/vehicle/${vehicleId}/events`,
        createVehiculeEvent
      );
      return data.data?.events;
    },
    onSuccess: (data) => {
      const keys = vehiculesKeys.events(vehicleId);
      const prev = queryClient.getQueryData<TEvent[]>(keys);
      if (prev && data) {
        queryClient.setQueryData<TEvent[]>(keys, [...prev, data]);
      }
      toast.success(t("common.save", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useGetDocumentsForAnEventOfAVehicle = (
  vehicleId: number,
  eventId: number
) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesKeys.eventsDocuments(vehicleId, eventId),
    queryFn: async (): Promise<TDocument[]> => {
      const data = await axios.get(
        `/vehicle/${vehicleId}/events/${eventId}/documents`
      );
      return data?.data;
    },
  });

  return { documents: data ?? [], ...res };
};

export const useGetDocumentsForAnEventOfAVehicleById = (
  vehicleId: number,
  eventId: number,
  documentId: number
) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesKeys.eventsDocumentsById(vehicleId, eventId, documentId),
    queryFn: async (): Promise<TDocument> => {
      const data = await axios.get(
        `/vehicles/${vehicleId}/events/${eventId}/documents/${documentId}`
      );
      return data?.data;
    },
  });

  return { document: data ?? {}, ...res };
};

interface RequestUpdateAnEventForVehicule {
  name: string;
  datetime: string;
  user_id: number;
  event_type_id: number;
  address_id: number;
  note: string;
  url_report: string;
}

export const useUpdateAnEventForVehicule = (
  vehiculeId: number,
  eventId: number
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (updateVehicule: RequestUpdateAnEventForVehicule) => {
      const data = await axios.put(
        `/vehicles/${vehiculeId}}/events/${eventId}`,
        updateVehicule
      );
      return data.data?.event;
    },
    onSuccess: (data) => {
      const keys = vehiculesKeys.events(vehiculeId);
      const prev = queryClient.getQueryData<TEvent[]>(keys);
      if (prev && data) {
        const res = prev.map((p) => {
          if (p.id === data.id) {
            return data;
          }
          return p;
        });
        queryClient.setQueryData<TEvent[]>(keys, res);
      }
      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useDeleteEvents = (vehiculeId: number, eventsId: number) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const data = await axios.delete(
        `/vehicles/${vehiculeId}/events/${eventsId}`
      );
      return data.data;
    },
    onSuccess: (_) => {
      const keys = vehiculesKeys.events(vehiculeId);
      const prev = queryClient.getQueryData<TEvent[]>(keys);
      if (prev) {
        const res = prev.filter((event) => event.id !== eventsId);
        queryClient.setQueryData<TEvent[]>(keys, res);
      }
      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

interface RequestAssignVehicleToEmployee {
  start_date: string;
  end_date: string;
}

export const useAssignVehicleToEmployee = (
  fleetId: number,
  employeeId: number,
  vehicleId: number,
  onSend?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (assignVehicle: RequestAssignVehicleToEmployee) => {
      assignVehicle = {
        start_date: transformDate(assignVehicle.start_date),
        end_date: transformDate(assignVehicle.end_date),
      };
      const data = await axios.post(
        `/fleets/${fleetId}/employees/${employeeId}/vehicles/${vehicleId}/assign`,
        assignVehicle
      );
      return data.data;
    },
    onSuccess: (_) => {
      toast.success(
        t("collaborator.vehicleWellAffected", "Vehicle well affected")
      );
      onSend && onSend();
      const employeeKeysRefetch = employeeKeys.all(fleetId);
      queryClient.refetchQueries(employeeKeysRefetch as any);
    },
    onError: (err: any) => {
      toast.error(
        t(
          `vehicule.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export const useUnAssignVehicleToEmployee = (
  fleetId: number,
  employeeId: number,
  vehicleId: number,
  onClose?: () => void
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const data = await axios.delete(
        `/fleets/${fleetId}/employees/${employeeId}/vehicles/${vehicleId}/unassigned`
      );
      return data.data;
    },
    onSuccess: (_) => {
      toast.success(
        t("collaborator.vehicleWellRemoved", "Vehicle well removed")
      );
      onClose && onClose();
      const employeeKeysRefetch = employeeKeys.all(fleetId);
      queryClient.refetchQueries(employeeKeysRefetch as any);
    },
    onError: (err: any) => {
      toast.error(
        t(
          `vehicule.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export const useVehicleFleetNumber = (fleetId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: vehiculesKeys.vehiculeNumber(fleetId),
    queryFn: async (): Promise<TVehiculeOne> => {
      const data = await axios.get(`/fleets/${fleetId}/vehicle-by-number`);
      return data?.data;
    },
    staleTime: 10000,
  });
  return { vehiculeNumber: data, ...res };
};
