import { FC, useState } from "react";
import { Icon } from "../../../components/Icon/Icon";
import { ModalCarteAndBadge } from "../../../containers/ModalCarteAndBadge/ModalCarteAndBadge";
import { useTranslation } from "react-i18next";
import { ModalDisabledBadge } from "./ModalDisabledBadge/ModalDisabledBadge";
import { typeOfCardOrBadge } from "../../../api/badge";

type props = {
  badge: TBadge;
};

export const ButtonsActionBadges: FC<props> = ({ badge }) => {
  const { t } = useTranslation();

  const [openModalAddBadge, setOpenModalAddBadge] = useState(false);
  const [currentBadge, setCurrentBadge] = useState<TBadge | null>(null);
  const [openDisabledBadge, setOpenDisabledBadge] = useState(false);

  const onCLoseModalBadge = () => {
    //  toast.error(t("carteBadge.disabledBadgeForce", { name: "COLLAB_X" }))
    setCurrentBadge(null);
    setOpenModalAddBadge(false);
    setOpenDisabledBadge(false);
  };

  return (
    <>
      <ModalCarteAndBadge
        open={openModalAddBadge}
        currentCartOrBadge={currentBadge}
        title={
          currentBadge
            ? t("collaborator.modifyBadge", "Modify badge")
            : t("collaborator.newBadge", "New badge")
        }
        supportTypes={typeOfCardOrBadge.badge}
        onCancel={onCLoseModalBadge}
        onClose={onCLoseModalBadge}
      />
      <ModalDisabledBadge
        currentBadge={currentBadge}
        open={openDisabledBadge}
        onCancel={onCLoseModalBadge}
        onClose={onCLoseModalBadge}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "15px",
          width: "100%",
        }}
      >
        <Icon
          name={"pencil"}
          width={"24px"}
          height={"24px"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setCurrentBadge(badge as TBadge);
            setOpenModalAddBadge(true);
          }}
        />
        <Icon
          name={"trash"}
          width={"24px"}
          height={"24px"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setOpenDisabledBadge(true);
            setCurrentBadge(badge as TBadge);
          }}
        />
      </div>
    </>
  );
};
