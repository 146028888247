import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  divVehicule: {
    display: "flex",
    flexDirection: "column",
    gap: "13px",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
    "& .MuiButtonBase-root": {
      alignSelf: "center",
    },
  },

  divVehicleHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divBlue: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    minWidth: "50px",
    height: "30px",
    borderRadius: "50px",
    backgroundColor: colors.primary,
  },

  divCardInfo: {
    display: "flex",
    flexDirection: "row",
    gap: "13px",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default useStyles;
