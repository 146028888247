import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((_) => ({
  menuItem: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& > div": {
      marginLeft: "10px",
    },
    height: 40,
    borderRadius: 15,
    marginBottom: 10,
    "&:hover": {
      backgroundColor: "#312D2D26",
    },
  },
}));

export default useStyles;
