import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import useStyles from "./styles";
import { CustomTextInput } from "../../../../components/CustomTextInput/CustomTextInput";
import { CustomSearch } from "../../../../components/CustomSearch/CustomSearch";
import { CustomTextDatePicker } from "../../../../components/CustomTextDatePicker/CustomTextDatePicker";
import { useFormik } from "formik";
import {
  initialValuesSinister,
  validationSchemaCreateSinister,
} from "./validationSchema";
import dayjs from "dayjs";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useGetAllVehiculesFleet } from "../../../../api/vehicule";
import { CustomTextSelect } from "../../../../components/CustomTextSelect/CustomTextSelect";
import {
  SinisterStatus,
  getSinisterValueByKeySinisterStatus,
} from "../../types";
import { useCreateSinister } from "../../../../api/sinistre";

type Props = {
  onClickCreate?: () => void;
};

export const StepCreateClaims: FC<Props> = ({ onClickCreate }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { vehicules } = useGetAllVehiculesFleet(
    getCurrentFleetId(),
    1,
    5000,
    "",
    "F"
  );

  const [selectedVehicleId, setSelectedVehicleId] = useState<number | null>(
    null
  );

  const onSuccessCreate = () => {
    onClickCreate && onClickCreate();
  };

  const { mutate: createSinister, isPending } = useCreateSinister(
    getCurrentFleetId(),
    selectedVehicleId ?? -1,
    onSuccessCreate
  );

  const formik = useFormik({
    initialValues: initialValuesSinister,
    validationSchema: validationSchemaCreateSinister,
    onSubmit: (values) => {
      createSinister({
        name: values.name ?? "",
        description: values.description ?? "",
        mileage: values.mileage ?? "",
        sinister_status:
          getSinisterValueByKeySinisterStatus(
            values.sinister_status as SinisterStatus
          ) ?? "B",
        sinister_at: values.sinister_at ?? "",
      });
    },
  });

  const onChangeTextFieldVehicle = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.trim().length === 0) {
      setSelectedVehicleId(null);
    }
  };

  const onChangeAutoCompletVehicle = (_: any, vehicle: any) => {
    setSelectedVehicleId(vehicle.vehicleId);
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerSearch}>
        <div className={classes.divInput}>
          <CustomTextInput
            label={`${t("claims.titleOfTheClaim", "Title of the claim")} *`}
            placeholder={t("claims.titleOfTheClaim", "Title of the claim")}
            name="name"
            value={formik?.values?.name}
            onChange={formik.handleChange}
          />
          <CustomSearch
            options={vehicules}
            getOptionLabel={(option: any) =>
              `${option?.brand} ${option?.brandModel} - ${option?.immatriculation}`
            }
            label={`${t("claims.vehicleSearch", "Vehicle search")} *`}
            placeholder={t("common.find", "Find")}
            onChange={onChangeAutoCompletVehicle}
            onChangeTextField={onChangeTextFieldVehicle}
          />
          <CustomTextDatePicker
            label={`${t("claims.dateOfLoss", "Date of loss")} *`}
            value={formik.values.sinister_at as any}
            onChange={(value) =>
              formik?.setFieldValue(
                "sinister_at",
                dayjs(value).format("YYYY-MM-DD")
              )
            }
          />
          <CustomTextInput
            label={`${t("claims.mileage", "Mileage")} *`}
            placeholder={`${t("claims.mileage", "Mileage")} *`}
            type={"number"}
            name="mileage"
            value={formik?.values?.mileage}
            onChange={formik.handleChange}
          />
          <CustomTextSelect
            label={`${t("vehicule.status", "Status")} *`}
            options={Object.keys(SinisterStatus)}
            placeholder={t("vehicule.status", "Status")}
            name="sinister_status"
            value={formik?.values?.sinister_status}
            onChange={formik.handleChange}
          />
          <CustomTextInput
            label={`${t("claims.comment", "Comment")} *`}
            placeholder={t("common.yourDetailsHere", "Your details here")}
            rows={5}
            name="description"
            value={formik?.values?.description}
            onChange={formik.handleChange}
          />
        </div>
        <CustomButton
          onClick={handleSave}
          label={t("claims.createTheClaim", "Create the claim")}
          variant={formik.isValid && formik.dirty ? "primary" : "disabled"}
          width={"fit-content"}
          height={"40px"}
          loading={isPending}
        />
      </div>
    </div>
  );
};
