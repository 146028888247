import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  container: {
    width: "60%",
    alignSelf: "center",
  },
  containerInput: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    "& > div": {
      flex: 1,
    },
  },
  containerRequire: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    padding: "0px 15px 0px 15px",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  containerAllButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
});

export default useStyles;
