import useStyles from "./styles";
import { useState } from "react";
import { items } from "./DataSideBar";
import { MenuItem } from "./MenuItem/MenuItem";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useGetOneCompanie } from "../../api/companies";
import { getCurrentFleetId } from "../../utils/getCurrentFleet";
import { motion } from "framer-motion";

const SIDEBAR_SIZE = 65;
const SIDEBAR_SIZE_COLLAPSED = 240;

type Props = {
  children?: React.ReactNode;
  styles?: any;
};

export const Sidebar: React.FC<Props> = ({ children, styles }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { companie, isPending, isError } = useGetOneCompanie(
    getCurrentFleetId()
  );
  const onCloseDrawer = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className={styles?.sidebarRoot}>
      {/* Sidebar */}
      <div className={classes.drawerPositioner}>
        <div
          className={classes.container}
          style={{ width: isCollapsed ? SIDEBAR_SIZE_COLLAPSED : SIDEBAR_SIZE }}
        >
          <div className={classes.iconCollapse} onClick={onCloseDrawer}>
            {isCollapsed ? "<<" : ">>"}
          </div>
          <div
            className={
              isCollapsed ? classes.heading : classes.headingNotCollapsed
            }
          >
            {isCollapsed && !isPending && !isError && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  marginBottom: 16,
                  display: "flex",
                  justifyContent: "center",
                }}
                transition={{ duration: 1 }}
              >
                <img
                  src={companie?.logo}
                  alt="logo"
                  width={"100%"}
                  height={"fit-content"}
                  className={classes.logo}
                />
              </motion.div>
            )}

            {/* <CustomTextField
              isSearchable
              placeholder={i18n.t("sidebar.search", "Search")}
              styles={{
                textfield: isCollapsed
                  ? classes.searchTextfield
                  : classes.textfieldNotCollapsed,
              }}
            /> */}
          </div>
          <div className={classes.menuItemContainer}>
            <div>
              {items.slice(0, 5)?.map((item, index) => (
                <MenuItem
                  key={index}
                  iconName={item?.iconName}
                  isCollapsed={isCollapsed}
                  text={item?.label}
                  width={item?.width}
                  height={item?.height}
                  onClick={() => {
                    setIsCollapsed(false);
                    item?.to && navigate(item.to);
                  }}
                />
              ))}
            </div>
            <div>
              {items.slice(5, 10)?.map((item, index) => (
                <MenuItem
                  key={index}
                  iconName={item?.iconName}
                  isCollapsed={isCollapsed}
                  text={item?.label}
                  onClick={() => {
                    setIsCollapsed(false);
                    item?.to && navigate(item.to);
                  }}
                  width={item?.width}
                  height={item?.height}
                />
              ))}
            </div>
            <div>
              {items.slice(10, 11)?.map((item, index) => (
                <MenuItem
                  key={index}
                  iconName={item?.iconName}
                  isCollapsed={isCollapsed}
                  text={item?.label}
                  width={item?.width}
                  height={item?.height}
                  onClick={() => {
                    setIsCollapsed(false);
                    item?.to && navigate(item.to);
                  }}
                />
              ))}
              <MenuItem
                key={"logout"}
                iconName={"logout"}
                isCollapsed={isCollapsed}
                text={t("sidebar.logout", "Logout")}
                width={24}
                height={24}
                onClick={() => {
                  localStorage.clear();
                  window?.location?.reload();
                  setIsCollapsed(false);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* child */}
      <div
        className={styles?.sidebarRightRoot}
        style={{ marginLeft: SIDEBAR_SIZE }}
      >
        {children}
      </div>
    </div>
  );
};
