import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import useStyles from "./styles";
import { FC, useState } from "react";
import { HeaderDocuments } from "./HeaderDocuments/HeaderDocuments";
import { CustomTextInput } from "../../../../components/CustomTextInput/CustomTextInput";
import { CustomTextSelect } from "../../../../components/CustomTextSelect/CustomTextSelect";
import { useFormik } from "formik";
import {
  initialValuesVehicleDocument,
  validationSchemaCreateVehicleDocument,
} from "./validationSchemaAddDocument";
import toast from "react-hot-toast";
import { typeOfDocument, useCreateDocument } from "../../../../api/document";
import { uploadFileMultiple } from "../../../../utils/firebaseConfig";
import { useSelector } from "react-redux";
import { getPeriodicity } from "../../../../utils/periodicity";

type Props = {
  onClickPrevious?: () => void;
  onClickSaveAndNext?: () => void;
};

export const StepAddDocuments: FC<Props> = ({
  onClickPrevious,
  onClickSaveAndNext,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const newlyCreatedVehicle = useSelector(
    (state: any) => state.vehicleReducer.vehicule
  );
  const { mutate: createDocument, isPending } =
    useCreateDocument(onClickSaveAndNext);

  const [isLoading, setIsLoading] = useState(false);

  const [uploadedFileContrat, setUploadedFileContrat] = useState<File | null>(
    null
  );
  const [uploadedFileGreyCard, setUploadedFileGreyCard] = useState<File | null>(
    null
  );
  const [uploadedFileInsurrance, setUploadedFileInsurrance] =
    useState<File | null>(null);

  const formik = useFormik({
    initialValues: initialValuesVehicleDocument,
    validationSchema: validationSchemaCreateVehicleDocument,
    onSubmit: async (values) => {
      if (
        uploadedFileContrat === null ||
        uploadedFileGreyCard === null ||
        uploadedFileInsurrance === null
      ) {
        toast.error(t("collaborator.missingDocument", "Missing document"));
      } else {
        setIsLoading(true);
        try {
          const urlUploadedFileContrat = (await uploadFileMultiple(
            uploadedFileContrat
          )) as string;

          const urlUploadedFileGreyCard = (await uploadFileMultiple(
            uploadedFileGreyCard
          )) as string;

          const urlUploadedFileInsurrance = (await uploadFileMultiple(
            uploadedFileInsurrance
          )) as string;

          createDocument({
            name: "Contrat",
            vehicle_id: newlyCreatedVehicle?.id,
            type: typeOfDocument.contrat,
            scan_id: urlUploadedFileContrat,
          });
          createDocument({
            name: "Carte grise",
            vehicle_id: newlyCreatedVehicle?.id,
            type: typeOfDocument.carte_grise,
            scan_id: urlUploadedFileGreyCard,
            number: values.cardNumber ?? "",
          });
          createDocument({
            name: "Certificat d'assurance",
            vehicle_id: newlyCreatedVehicle?.id,
            type: typeOfDocument.certificat_d_assurance,
            scan_id: urlUploadedFileInsurrance,
            number: values.policeNumber ?? "",
            cost: parseInt(values.cost ?? ""),
            periodicity_id: getPeriodicity(values.periodicity ?? "Mensuel"),
          });
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoading(false);
        }
      }
    },
  });

  const onDropContrat = (acceptedFiles: File[]) => {
    setUploadedFileContrat(acceptedFiles[0]);
  };

  const onDropGreyCard = (acceptedFiles: File[]) => {
    setUploadedFileGreyCard(acceptedFiles[0]);
  };

  const onDropGreyInsurrance = (acceptedFiles: File[]) => {
    setUploadedFileInsurrance(acceptedFiles[0]);
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerDocuments}>
        <div className={classes.containerContrat}>
          <HeaderDocuments
            title={t("contrat.contrat", "Contract")}
            maxSize={"20 Mo"}
            acceptFormat={"PNG - JPG - JPEG - PDF"}
            accept={{
              "image/png": [".png"],
              "image/jpeg": [".jpg", ".jpeg"],
              "application/pdf": [".pdf"],
            }}
            onDrop={onDropContrat}
            uploadedFile={uploadedFileContrat}
          />
          <HeaderDocuments
            title={t("panel.document.greyCard", "Grey card")}
            onDrop={onDropGreyCard}
            acceptFormat={"PNG - JPG - JPEG - PDF"}
            accept={{
              "image/png": [".png"],
              "image/jpeg": [".jpg", ".jpeg"],
              "application/pdf": [".pdf"],
            }}
            uploadedFile={uploadedFileGreyCard}
            children={
              <CustomTextInput
                label={`${t("contrat.number", "Number")} *`}
                placeholder={"123456789"}
                name="cardNumber"
                value={formik?.values?.cardNumber}
                onChange={formik.handleChange}
              />
            }
          />
        </div>
        <HeaderDocuments
          onDrop={onDropGreyInsurrance}
          uploadedFile={uploadedFileInsurrance}
          acceptFormat={"PNG - JPG - JPEG - PDF"}
          accept={{
            "image/png": [".png"],
            "image/jpeg": [".jpg", ".jpeg"],
            "application/pdf": [".pdf"],
          }}
          title={t(
            "panel.document.insuranceCertificate",
            "Insurance certificate"
          )}
          children={
            <div>
              <CustomTextInput
                label={`${t("contrat.policeNumber", "Police number")} *`}
                placeholder={"123456789"}
                name="policeNumber"
                value={formik?.values?.policeNumber}
                onChange={formik.handleChange}
              />
              <div className={classes.containerGreyCardInput}>
                <CustomTextInput
                  label={`${t("contrat.cost", "Cost")} *`}
                  type="number"
                  placeholder={t("contrat.amount", "Amount")}
                  name="cost"
                  value={formik?.values?.cost}
                  onChange={formik.handleChange}
                />
                <CustomTextSelect
                  label={`${t("common.periodicity", "Periodicity")} *`}
                  options={["Journalier", "Mensuel", "Annuel"]}
                  placeholder={t("vehicule.monthly", "Monthly")}
                  name="periodicity"
                  value={formik?.values?.periodicity}
                  onChange={formik.handleChange}
                />
              </div>
            </div>
          }
        />
      </div>
      <div className={classes.containerAllButton}>
        <CustomButton
          onClick={onClickPrevious}
          label={t("vehicule.previous", "Previous")}
          variant={"default"}
          width={"fit-content"}
          height={"40px"}
        />
        <div className={classes.containerButton}>
          <CustomButton
            label={t("vehicule.save", "Save")}
            width={"fit-content"}
            height={"40px"}
            variant={formik.isValid && formik.dirty ? "default" : "disabled"}
          />
          <CustomButton
            loading={isPending || isLoading}
            label={t("vehicule.saveAndNext", "Save and next")}
            variant={formik.isValid && formik.dirty ? "primary" : "disabled"}
            width={"fit-content"}
            onClick={handleSave}
            height={"40px"}
          />
        </div>
      </div>
    </div>
  );
};
