import { FC } from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import { DocumentsPanel } from "../../../../components/DocumentsPanel/DocumentsPanel";
import Confetti from "react-confetti";
import {
  tranformDataDocuments,
  transformDataCollab,
  transformDataContrat,
  transformDataVehicle,
} from "./data";
import { useWindowSize } from "react-use";
import { useGetOneEmployee } from "../../../../api/employee";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useSelector } from "react-redux";
import {
  useGetOneVehicle,
  useGetVehiculesByEmployee,
} from "../../../../api/vehicule";
import { useGetDocumentByVehicleId } from "../../../../api/document";
import { useGetContratByVehicleId } from "../../../../api/contrat";

type Props = {
  onClickSave?: () => void;
  onClickPrevious?: () => void;
};

export const StepValidationVehicule: FC<Props> = ({
  onClickSave,
  onClickPrevious,
}) => {
  const classes = useStyles();
  const { width, height } = useWindowSize();
  const { t } = useTranslation();

  const newlySelectedEmployee = useSelector(
    (state: any) => state.employeeReducer.employee
  );
  const newlyCreatedVehicle = useSelector(
    (state: any) => state.vehicleReducer.vehicule
  );

  const { employee } = useGetOneEmployee(
    getCurrentFleetId(),
    newlySelectedEmployee?.user_id ?? -1
  );

  const { vehicules } = useGetVehiculesByEmployee(
    getCurrentFleetId(),
    newlySelectedEmployee?.user_id ?? -1
  );

  const { vehicule } = useGetOneVehicle(
    getCurrentFleetId(),
    newlyCreatedVehicle?.id ?? -1
  );

  const { documents } = useGetDocumentByVehicleId(
    newlyCreatedVehicle?.id ?? -1
  );

  const { contrats } = useGetContratByVehicleId(
    getCurrentFleetId(),
    newlyCreatedVehicle?.id ?? -1
  );

  return (
    <>
      <Confetti width={width} height={height} />
      <div className={classes.containerDocuments}>
        <DocumentsPanel
          title={t("vehicule.vehicule", "Vehicle")}
          descriptions={transformDataVehicle(vehicule)}
        />
        <DocumentsPanel
          title={t("vehicule.contract", "contract")}
          descriptions={transformDataContrat(contrats[contrats.length - 1])}
        />
        <DocumentsPanel
          title={t("documents.documents", "Documents")}
          descriptions={tranformDataDocuments(documents ?? [])}
        />
        <DocumentsPanel
          title={t("panel.collaborator.collaborator", "Collaborator")}
          descriptions={transformDataCollab(
            employee,
            vehicules[vehicules.length - 1]
          )}
        />
      </div>

      <div className={classes.containerButton}>
        <CustomButton
          onClick={onClickPrevious}
          label={t("vehicule.previous", "Previous")}
          variant={"default"}
          width={"fit-content"}
          height={"40px"}
        />
        <CustomButton
          onClick={onClickSave}
          label={t("vehicule.seeTheVehicle", "See the vehicle")}
          variant={"primary"}
          width={"fit-content"}
          height={"40px"}
        />
      </div>
    </>
  );
};
