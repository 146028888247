import { useTranslation } from "react-i18next";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { Sidebar } from "../../../containers/Sidebar/Sidebar";
import useStyles from "./styles";
import i18n from "../../../i18n/i18n";
import { CustomStepper } from "../../../components/CustomStepper/CustomStepper";
import { useState } from "react";
import { StepCreateClaims } from "./StepCreateClaims/StepCreateClaims";
import { StepDetailsAddPictures } from "./StepDetailsAddPictures/StepDetailsAddPictures";
import { StepAddDocuments } from "./StepAddDocuments/StepAddDocuments";
import { StepValidationClaims } from "./StepValidationClaims/StepValidationClaims";

type Props = {};

const steps = [
  i18n.t("claims.nameOfDisaster", "Name of disaster"),
  // i18n.t("claims.vehicleSearch", "Vehicle search"),
  // i18n.t("claims.detailsOfTheIncident", "Details of the incident"),
  i18n.t("claims.addPictures", "Add pictures"),
  i18n.t("common.documents", "Documents"),
  i18n.t("common.validation", "Validation"),
];

export const CreateClaims: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [currentStep, setCurrentStep] = useState<number>(0);

  return (
    <Sidebar
      styles={{
        sidebarRightRoot: classes.vehiculeRoot,
        sidebarRoot: classes.contentMain,
      }}
    >
      <div className={classes.container}>
        <div className={classes.containerRoot}>
          <CustomTypography
            variant="h2"
            isUppercase
            text={t("claims.addAClaim", "Add a claim")}
          />
        </div>
        <div className={classes.subContainer}>
          <CustomStepper steps={steps} activeStep={currentStep} />
          <div className={classes.subContainerStepper}>
            <div className={classes.subsubContainer}>
              {currentStep === 0 && (
                <CustomTypography
                  isUppercase
                  text={t("claims.titleOfTheClaim", "Title of the claim")}
                  variant={"h3"}
                />
              )}
              {/* {currentStep === 1 && (
                <CustomTypography
                  isUppercase
                  text={t("claims.vehicleSearch", "Vehicle search")}
                  variant={"h3"}
                />
              )} */}
              {currentStep === 1 && (
                <CustomTypography
                  isUppercase
                  text={t("claims.addPictures", "Add pictures")}
                  variant={"h3"}
                />
              )}
              {currentStep === 2 && (
                <CustomTypography
                  isUppercase
                  text={t(
                    "claims.detailsOfTheIncident",
                    "Details of the incident"
                  )}
                  variant={"h3"}
                />
              )}
              {currentStep === 3 && (
                <CustomTypography
                  isUppercase
                  text={t("claims.addDocuments", "Add documents")}
                  variant={"h3"}
                />
              )}
              {currentStep === 4 && (
                <CustomTypography
                  isUppercase
                  text={t(
                    "claims.validationOfTheClaimsForm",
                    "Validation of the claims form"
                  )}
                  variant={"h3"}
                />
              )}
              <div className={classes.content}>
                {currentStep === 0 && (
                  <StepCreateClaims onClickCreate={() => setCurrentStep(1)} />
                )}
                {/* {currentStep === 1 && (
                  <StepSearchClaims
                    onClickAffectCar={() => setCurrentStep(2)}
                  />
                )}
                {currentStep === 2 && (
                  <StepDetailsClaims
                    onClickPrevious={() => setCurrentStep(1)}
                    onClickSaveAndNext={() => setCurrentStep(3)}
                  />
                )} */}
                {currentStep === 1 && (
                  <StepDetailsAddPictures
                    onClickPrevious={() => setCurrentStep(0)}
                    onClickSaveAndNext={() => setCurrentStep(2)}
                  />
                )}
                {currentStep === 2 && (
                  <StepAddDocuments
                    onClickPrevious={() => setCurrentStep(1)}
                    onClickSaveAndNext={() => setCurrentStep(3)}
                  />
                )}
                {currentStep === 3 && (
                  <StepValidationClaims
                    onClickPrevious={() => setCurrentStep(2)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};
