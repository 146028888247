import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import axios from "./axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

export const sinisterKeys = {
  all: (fleetId: number, page: number, per_page: number, filter: string) =>
    [
      "siniters",
      "idFleet",
      fleetId,
      "page",
      page,
      "per_page",
      per_page,
      "filter",
      filter,
    ] as const,
  detailSinister: (fleetId: number, sinisterId: number) =>
    ["sinitersDetail", "idFleet", fleetId, "idSinister", sinisterId] as const,
  docs: (fleetId: number, vehicleId: number, sinisterId: number) =>
    [
      "siniterDocs",
      "idFleet",
      fleetId,
      "vehicle",
      vehicleId,
      "sinisterId",
      sinisterId,
    ] as const,
  employee: (fleetId: number, vehicleId: number, sinisterId: number) =>
    [
      "employeeDetails",
      "idFleet",
      fleetId,
      "vehicle",
      vehicleId,
      "sinisterId",
      sinisterId,
    ] as const,
};

interface ResponseGetSinistres {
  data: TSinister[];
}

export const useGetAllSinisters = (
  fleetId: number,
  page: number = 1,
  per_page: number = 10,
  filter: string = ""
) => {
  const { data, error, ...res } = useQuery({
    queryKey: sinisterKeys.all(fleetId, page, per_page, filter),
    queryFn: async (): Promise<ResponseGetSinistres> =>
      axios.get(`/fleets/${fleetId}/sinisters`, {
        // params: {
        //   page,
        //   per_page,
        //   filter,
        // },
      }),
    // une minute
    staleTime: 0,
    // placeholderData: keepPreviousData,
  });
  return {
    sinistres: data?.data ?? [],
    // totalCount: data?.data?.sinistres?.total,
    ...res,
  };
};

export interface RequestCreateSinister {
  name: string;
  description: string;
  mileage: string;
  sinister_status: string;
  sinister_at: string;
}

export const useCreateSinister = (
  fleetId: number,
  vehicleId: number,
  onSave?: () => void
) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return useMutation({
    mutationFn: async (createSinister: RequestCreateSinister) => {
      const data = await axios.post(
        `/fleets/${fleetId}/vehicles/${vehicleId}/sinister`,
        createSinister
      );
      return data.data;
    },
    onSuccess: (data) => {
      toast.success(t("common.save", "Change saved"));
      onSave && onSave();

      dispatch({ type: "NEWLY_CREATED_SINISTER", data: data });
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorAddSinister.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export interface RequestUpdateSinister {
  name: string;
  description: string;
  sinister_status: string;
  sinister_at: string;
}

export const useUpdateSinister = (
  fleetId: number,
  vehicleId: number,
  sinisterId: number,
  onSave?: () => void
) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (updateSinister: RequestUpdateSinister) => {
      const data = await axios.put(
        `/fleets/${fleetId}/vehicles/${vehicleId}/sinister/${sinisterId}`,
        updateSinister
      );
      return data.data;
    },
    onSuccess: (_) => {
      toast.success(t("common.save", "Change saved"));

      onSave && onSave();
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorAddSinister.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export interface RequestUpdateSinisterStatus {
  sinister_status: string;
}

export const useUpdateSinisterStatus = (
  fleetId: number,
  vehicleId: number,
  sinisterId: number,
  onSave?: () => void
) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (updateSinister: RequestUpdateSinisterStatus) => {
      const data = await axios.put(
        `/fleets/${fleetId}/vehicles/${vehicleId}/sinister/${sinisterId}/status`,
        updateSinister
      );
      return data.data;
    },
    onSuccess: (_) => {
      toast.success(t("common.save", "Change saved"));

      onSave && onSave();
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorAddSinister.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export interface RequestSinisterAddImages {
  imgUrls: string[];
}

export const useSinisterAddImages = (
  fleetId: number,
  vehicleId: number,
  sinisterId: number,
  onSave?: () => void
) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (addImages: RequestSinisterAddImages) => {
      const data = await axios.post(
        `/fleets/${fleetId}/vehicles/${vehicleId}/sinister/${sinisterId}/add-images`,
        addImages
      );
      return data.data;
    },
    onSuccess: (_) => {
      toast.success(t("common.save", "Change saved"));
      onSave && onSave();
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorAddSinister.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

type IDocs = {
  doc_url: string;
  doc_name: string;
  doc_type: string;
};

export interface RequestSinisterAddDocs {
  docs: IDocs[];
}

export const useSinisterAddDocs = (
  fleetId: number,
  vehicleId: number,
  sinisterId: number,
  onSave?: () => void
) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (addDocs: RequestSinisterAddDocs) => {
      const data = await axios.post(
        `/fleets/${fleetId}/vehicles/${vehicleId}/sinister/${sinisterId}/add-docs`,
        addDocs
      );
      return data.data;
    },
    onSuccess: (_) => {
      toast.success(t("common.save", "Change saved"));
      onSave && onSave();
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorAddSinister.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export const useGetSinisterDocuments = (
  fleetId: number,
  vehicleId: number,
  sinisterId: number
) => {
  const { data, error, ...res } = useQuery({
    queryKey: sinisterKeys.docs(fleetId, vehicleId, sinisterId),
    queryFn: async (): Promise<TSinisterDoc[]> => {
      const data = await axios.get(
        `/fleets/${fleetId}/vehicles/${vehicleId}/sinister/${sinisterId}/docs`
      );
      return data?.data;
    },
  });
  return { sinisterDocs: data ?? [], ...res };
};

export const useGetSinisterEmployee = (
  fleetId: number,
  vehicleId: number,
  sinisterId: number
) => {
  const { data, error, ...res } = useQuery({
    queryKey: sinisterKeys.employee(fleetId, vehicleId, sinisterId),
    queryFn: async (): Promise<TSinisterEmployee> => {
      const data = await axios.get(
        `/fleets/${fleetId}/vehicles/${vehicleId}/sinister/${sinisterId}/employee`
      );
      return data?.data;
    },
  });
  return { sinisterEmployee: data, ...res };
};

export const useGetSinisterById = (fleetId: number, sinisterId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: sinisterKeys.detailSinister(fleetId, sinisterId),
    queryFn: async (): Promise<TSinister[]> => {
      const data = await axios.get(
        `/fleets/${fleetId}/sinisters/${sinisterId}`
      );
      return data?.data;
    },
  });
  return { sinisterDetail: data?.[0], ...res };
};

export const useDeleteSinister = (fleetId: number, sinisterId: number) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async () => {
      const data = await axios.delete(
        `/fleets/${fleetId}/sinisters/${sinisterId}`
      );
      return data.data;
    },
    onSuccess: (_) => {
      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorAddSinister.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};
