import { CustomChip } from "../../../components/CustomChip/CustomChip";
import { getKeyLabelByValue, getStatusFromValues } from "../../../utils/types";
import { ButtonsListVehicle } from "./ButtonsListVehicle";
export const columnsListVehicle = [
  { header: "ID", field: "vehicleId" },
  { header: "Immatriculation", field: "immatriculation" },
  { header: "VIN", field: "vin" },
  { header: "Marque", field: "brand" },
  { header: "Modele", field: "brandModel" },
  { header: "Département", field: "companyDepartment" },
  { header: "Nom du collaborateur", field: "lastname" },
  { header: "Prénom du collaborateur", field: "firstname" },
  {
    header: "Statut",
    field: "status",
    render: (rowData: Record<string, any>) => {
      return (
        <CustomChip
          label={getKeyLabelByValue(rowData?.fleetVehicleStatut) ?? "F"}
          value={getStatusFromValues(rowData?.fleetVehicleStatut)}
        />
      );
    },
  },
  {
    header: "",
    field: "",
    render: (rowData: Record<string, any>) => {
      return <ButtonsListVehicle vehicle={rowData as TVehicule} />;
    },
  },
];
