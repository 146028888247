import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { colors } from "../../../../styles/colors";
import { CustomButtonTable } from "../../../../components/CustomButtonTable/CustomButtonTable";
import { CustomIconButton } from "../../../../components/CustomIconButton/CustomIconButton";
import { useState } from "react";
import { useGetVehiculesByEmployee } from "../../../../api/vehicule";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useNavigate, useParams } from "react-router";
import { CustomModalAffectVehicle } from "./CustomModalAffectVehicle";
import { CustomModalDisablevehicle } from "./CustomModalDisablevehicle";
import dayjs from "dayjs";

type Props = {};

export const VehiculeAffected: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openRemove, setOpenRemove] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const navigate = useNavigate();
  const { id = "" } = useParams();
  const userId = parseInt(id);

  const { vehicules } = useGetVehiculesByEmployee(getCurrentFleetId(), userId);

  const onCloseEditModal = () => {
    setOpenEdit(false);
  };

  const onCloseDisablevehicle = () => {
    setOpenRemove(false);
  };

  return (
    <>
      <CustomModalDisablevehicle
        openRemove={openRemove}
        onCancel={onCloseDisablevehicle}
        onClose={onCloseDisablevehicle}
      />
      <CustomModalAffectVehicle
        openEdit={openEdit}
        onCancel={onCloseEditModal}
        onClose={onCloseEditModal}
      />
      <div className={classes.containerVehicule}>
        <div className={classes.divHeader}>
          <CustomTypography
            text={t("collaborator.affectedVehicle", "Affected vehicle")}
            variant="h5"
            color={colors.primary}
            isUppercase
          />
        </div>
        <div className={classes.containerNumber}>
          <CustomTypography
            text={`${
              vehicules[vehicules.length - 1]?.immatriculation ??
              t("vehicule.noVehicleAffected", "No vehicle affected")
            }`}
            variant="h4"
            isUppercase
          />
        </div>
        <div className={classes.containerButton}>
          <div className={classes.divText}>
            <div>
              <CustomTypography
                text={t("vehicule.vin", "Vin")}
                variant="link"
              />
              <CustomTypography
                text={`${
                  vehicules[vehicules.length - 1]?.vin ??
                  t("vehicule.vin", "Vin")
                }`}
                variant="default"
                fontWeight="bold"
              />
            </div>
            <div>
              <CustomTypography
                text={t("collaborator.dateOfAssignment", "Date of assignment")}
                variant="link"
              />
              <CustomTypography
                text={`${
                  vehicules[vehicules.length - 1]?.start_date
                    ? dayjs(vehicules[vehicules.length - 1].start_date).format(
                        "DD-MM-YYYY"
                      )
                    : t("collaborator.dateOfAssignment", "Date of assignment")
                }`}
                variant="default"
                fontWeight="bold"
              />
            </div>
            <div>
              <CustomTypography
                text={t(
                  "collaborator.assignmentEndDate",
                  "Assignment end date"
                )}
                variant="link"
              />
              <CustomTypography
                text={`${
                  vehicules[vehicules.length - 1]?.end_date
                    ? dayjs(vehicules[vehicules.length - 1].end_date).format(
                        "DD-MM-YYYY"
                      )
                    : t("collaborator.assignmentEndDate", "Assignment end date")
                }`}
                variant="default"
                fontWeight="bold"
              />
            </div>
          </div>
          <div className={classes.divButton}>
            <CustomButtonTable
              variant={"primary"}
              textVariant={"link"}
              label={t("collaborator.seeTheVehicle", "See the vehicle")}
              textTransform={"none"}
              width={"fit-content"}
              fontWeight={"bold"}
              textDecoration={"none"}
              iconName={"eye"}
              onClick={() =>
                vehicules[vehicules.length - 1]?.vehicleId &&
                navigate(
                  `/vehicule/update/${
                    vehicules[vehicules.length - 1].vehicleId
                  }`
                )
              }
            />
            <div className={classes.divButtonIcon}>
              <CustomIconButton
                iconName={"pencil"}
                widthIcon={"30px"}
                heightIcon={"30px"}
                onClick={() => setOpenEdit(true)}
              />
              <CustomIconButton
                iconName={"trash"}
                widthIcon={"30px"}
                heightIcon={"30px"}
                backgroundColor={colors.tertiary}
                onClick={() => setOpenRemove(true)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
