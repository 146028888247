import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  containerDocuments: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    justifyContent: "space-between",
    overflowX: "auto",
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    justifyContent: "space-between",
  },
});

export default useStyles;
