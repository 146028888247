import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { Sidebar } from "../../containers/Sidebar/Sidebar";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { InformationGeneral } from "./InformationGeneral/InformationGeneral";
import { ContainerTVA } from "./ContainerTVA/ContainerTVA";
import { Communication } from "./Communication/Communication";

import { ParametreService } from "./ParametreService/ParametreService";
type Props = {};

export const Parametre: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Sidebar
      styles={{
        sidebarRightRoot: classes.vehiculeRoot,
        sidebarRoot: classes.contentMain,
      }}
    >
      <div className={classes.containerHeader}>
        <CustomTypography
          variant="h2"
          text={t("setting.setting", "Setting")}
          isUppercase
        />
      </div>
      <div className={classes.divContainer1}>
        <InformationGeneral />
        <ContainerTVA />
      </div>
      <div className={classes.divContainer1}>
        <Communication />
        <ParametreService />
      </div>
    </Sidebar>
  );
};
