export enum SinisterStatus {
  "En cours" = "B",
  "Fermé" = "C",
  "Déclaré" = "D",
}

export const getSinisterValueByKeySinisterStatus = (
  key: string
): string | undefined => {
  return SinisterStatus[key as keyof typeof SinisterStatus];
};

export const getSinisterKeyLabelByValue = (
  value: "B" | "C" | "D"
): string | undefined => {
  return Object.entries(SinisterStatus).find(
    ([_, enumValue]) => enumValue === value
  )?.[0];
};
