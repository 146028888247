import dayjs from "dayjs";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import i18n from "../../../i18n/i18n";
// import { getPeriodicityLabelByValue } from "../../../utils/periodicity";
import { ButtonsActionBadges } from "./ButtonsActionBadges";

export const columnsBadges = [
  {
    header: `${i18n.t("collaborator.choiceOfType", "Choice of type")}`,
    field: "cardType",
  },
  {
    header: `${i18n.t("collaborator.choiceOfBrand", "Choice of brand")}`,
    field: "cardBrand",
  },
  {
    header: `${i18n.t("panel.vehicule.dateOfEmission", "Date of emission")}`,
    field: "",
     render: (data: Record<string, any>) => {
      return (
        <CustomTypography
          variant="default"
          text={data?.release_date ? dayjs(data.release_date).format('DD-MM-YYYY') : ""}
        />
      );
    },
  },
  { header: `${i18n.t("common.number", "Number")}`, field: "number" },
  {
    header: `${i18n.t("common.billing", "Billing")}`,
    field: "",
    render: (data: Record<string, any>) => {
      return (
        <CustomTypography
          variant="default"
          // text={getPeriodicityLabelByValue(data.name) ?? ""}
          text={data.name}
        />
      );
    },
  },
  {
    header: "",
    field: "",
    render: (data: Record<string, any>) => {
      return <ButtonsActionBadges badge={data as TBadge} />;
    },
  },
];
