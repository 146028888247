import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  subContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "57px",
    minWidth: "202px",
    borderRadius: "15px",
    padding: "20px",
    backgroundColor: colors.WHITE,
    flex: 1,
  },
});

export default useStyles;
