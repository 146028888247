import { FC } from "react";
import { CustomTypography } from "../CustomTypography/CustomTypography";

import { colors } from "../../styles/colors";
import useStyles from "./styles";

export type subDocuments = {
  subTitle?: string;
  description?: string;
};

type Props = {
  title?: string;
  descriptions?: subDocuments[];
  minHeight?: string | number;
  maxHeight?: string | number;
};

export const DocumentsPanel: FC<Props> = ({
  title,
  descriptions,
  minHeight,
  maxHeight,
}) => {
  const classes = useStyles({ minHeight, maxHeight });
  return (
    <div className={classes.containerSubDocuments}>
      <CustomTypography
        text={title ?? ""}
        variant={"h4"}
        isUppercase
        color={colors.primary}
      />
      {descriptions?.map((document, index) => (
        <div key={index}>
          <CustomTypography text={document?.subTitle ?? ""} variant={"link"} />
          <CustomTypography
            text={document?.description ?? ""}
            variant={"default"}
            fontWeight={"bold"}
          />
        </div>
      ))}
    </div>
  );
};
