import { CSSProperties } from "react";
import { ReactComponent as Currency } from "../../assets/svg/07-currency.svg";
import { ReactComponent as Eco } from "../../assets/svg/08-eco.svg";
import { ReactComponent as Map } from "../../assets/svg/09-map.svg";
import {
  TbDashboard,
  TbUserCircle,
  TbCar,
  TbInfoSquareRounded,
  TbCarCrash,
  TbCertificate,
  TbLicense,
  TbSteeringWheel,
  TbMap2,
  TbStars,
  TbHelp,
  TbSettings,
  TbLogout,
  TbTrash,
  TbPencil,
  TbCards,
  TbShieldCheck,
  TbEye,
  TbFileStack,
  TbBell,
  TbChevronRight,
  TbSunHigh,
  TbSnowflake,
  TbPlus,
  TbChevronDown,
  TbChevronUp,
  TbCheck,
  TbPhoto,
  TbCertificate2,
  TbX,
  TbSearch,
  TbReload,
  TbBellRinging2,
  TbDots,
  TbMailOpened,
  TbMail,
  TbToggleLeft,
  TbToggleRight,
  TbCopy,
  TbPaperclip,
  TbFileInfo,
} from "react-icons/tb";

type Props = {
  name: string;
  width?: number | string;
  height?: number | string;
  animation?: number | string;
  color?: any;
  style?: CSSProperties;
  onClick?: () => void;
};

// Aligner ces icons par ordres alphabétiques
export const Icon: React.FC<Props> = ({
  name,
  width,
  height,
  color,
  animation,
  style,
  onClick,
}) => {
  switch (name) {
    case "bell":
      return <TbBell style={{ width, height, color }} />;
    case "bell2":
      return <TbBellRinging2 style={{ width, height, color }} />;

    case "copy":
      return (
        <TbCopy style={{ width, height, color, ...style }} onClick={onClick} />
      );
    case "collaborateur":
      return <TbUserCircle style={{ width, height, color }} />;
    case "cards":
      return <TbCards style={{ width, height }} />;
    case "certificate":
      return <TbCertificate style={{ width, height, color }} />;
    case "certificate2":
      return <TbCertificate2 style={{ width, height, color }} />;
    case "chevronRight":
      return <TbChevronRight style={{ width, height }} />;
    case "chevronDown":
      return <TbChevronDown style={{ width, height }} />;
    case "chevronUp":
      return <TbChevronUp style={{ width, height }} />;
    case "check":
      return <TbCheck style={{ width, height, color }} />;
    case "close":
      return <TbX style={{ width, height, color }} />;

    case "dashboard":
      return <TbDashboard style={{ width, height }} />;
    case "dots":
      return <TbDots style={{ width, height }} />;

    case "eye":
      return <TbEye style={{ width, height, color }} />;

    case "fileStack":
      return <TbFileStack style={{ width, height }} />;
    case "fileInfo":
      return <TbFileInfo style={{ width, height, color }} />;

    case "help":
      return <TbHelp style={{ width, height }} />;

    case "license":
      return <TbLicense style={{ width, height, color }} />;
    case "logout":
      return <TbLogout style={{ width, height }} />;

    case "map2":
      return <TbMap2 style={{ width, height }} />;
    case "mailOpened":
      return <TbMailOpened style={{ width, height, color }} />;
    case "mail":
      return <TbMail style={{ width, height, color }} />;

    case "notification":
      return <TbInfoSquareRounded style={{ width, height }} />;

    case "pencil":
      return (
        <TbPencil
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );
    case "plus":
      return (
        <TbPlus style={{ width, height, color, ...style }} onClick={onClick} />
      );
    case "photo":
      return <TbPhoto style={{ width, height, color }} />;
    case "paperClip":
      return (
        <TbPaperclip
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );

    case "reload":
      return <TbReload style={{ width, height, color, animation }} />;

    case "sinistre":
      return <TbCarCrash style={{ width, height }} />;
    case "stars":
      return <TbStars style={{ width, height }} />;
    case "steeringWheel":
      return <TbSteeringWheel style={{ width, height }} />;
    case "search":
      return <TbSearch style={{ width, height, color }} />;
    case "settings":
      return <TbSettings style={{ width, height }} />;
    case "shieldCheck":
      return <TbShieldCheck style={{ width, height }} />;
    case "sunHigh":
      return <TbSunHigh style={{ width, height }} />;
    case "snowFlake":
      return <TbSnowflake style={{ width, height }} />;

    case "trash":
      return (
        <TbTrash style={{ width, height, color, ...style }} onClick={onClick} />
      );
    case "toggleRight":
      return (
        <TbToggleRight
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );
    case "toggleLeft":
      return (
        <TbToggleLeft
          style={{ width, height, color, ...style }}
          onClick={onClick}
        />
      );

    case "vehicule":
      return <TbCar style={{ width, height, color }} />;

    case "currency":
      return <Currency width={width} height={height} />;
    case "eco":
      return <Eco width={width} height={height} />;
    case "map":
      return <Map width={width} height={height} />;

    default:
      return null;
  }
};
