import dayjs from "dayjs";
import { subDocuments } from "../../../../components/DocumentsPanel/DocumentsPanel";
import i18n from "../../../../i18n/i18n";
import { getPeriodicityByValue } from "../../../../utils/periodicity";

export const transformDataVehicle = (vehicle: TVehiculeOne | undefined) => {
  return [
    {
      subTitle: i18n.t("vehicule.vin", "Vin"),
      description: `${vehicle?.vin ?? ""}`,
    },
    {
      subTitle: "Immatriculation",
      description: `${vehicle?.immatriculation ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.brand", "Brand"),
      description: `${vehicle?.brand ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.model", "Model"),
      description: `${vehicle ? JSON.parse(vehicle?.brandModel)?.name : ""}`,
    },
    {
      subTitle: i18n.t("vehicule.gearBox"),
      description: `${vehicle?.boite ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.fiscalePower"),
      description: `${vehicle?.puissance ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.carburant"),
      description: `${vehicle?.carburant ?? ""}`,
    },
  ] as subDocuments[];
};

export const transformDataContrat = (contrat: Tcontrat | undefined) => {
  return [
    {
      subTitle: i18n.t("vehicule.contractNumber", "Contract number"),
      description: `${contrat?.contract_number ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.durationInMonths", "Duration in months"),
      description: `${contrat?.duration ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.dateOfEntry", "Date of entry"),
      description: `${dayjs(contrat?.start_date).format("DD-MM-YYYY") ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.releaseDate", "Release date"),
      description: `${dayjs(contrat?.end_date).format("DD-MM-YYYY") ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.kmOnContract", "Km on contact"),
      description: `${contrat?.contract_km ?? ""} km`,
    },
    {
      subTitle: i18n.t(
        "vehicule.numberOfWinterTires",
        "Number of winter tires"
      ),
      description: `${contrat?.number_winter_tire ?? ""}`,
    },
    {
      subTitle: i18n.t(
        "vehicule.numberOfSummerTires",
        "Number of summer tires"
      ),
      description: `${contrat?.number_summer_tire ?? ""}`,
    },
    {
      subTitle: i18n.t("vehicule.rentExcludingTax", "Rent excluding tax"),
      description: `${contrat?.rental_tax ?? ""}`,
    },
    {
      subTitle: i18n.t("common.periodicity", "Periodicity"),
      description: `${
        getPeriodicityByValue(contrat?.periodicity_id ?? -1) ?? ""
      }`,
    },
  ] as subDocuments[];
};

export const tranformDataDocuments = (documents: TDocument[]) => {
  return documents
    .map((document) => {
      // let description = `
      //    <img src=${document.scan_id} alt="document" width="100" height="70"/> <br/>
      //   `;

      let description = "";

      if (document.number !== null) {
        description += `${document.number} <br/>`;
      }
      if (document.cost !== null) {
        description += `${document.cost} <br/>`;
      }
      description += `${getPeriodicityByValue(document.periodicity_id)}`;
      return {
        subTitle: document.name,
        description: description,
      };
    })
    .filter((document) => document.subTitle !== "Contrat") as subDocuments[];
};

export const transformDataCollab = (
  employee: TEmployeeOne | undefined,
  vehicule: TVehicule
) => {
  return [
    { subTitle: "ID Codenekt", description: employee?.company_id },
    {
      subTitle: i18n.t("collaborator.name", "Name"),
      description: `${employee?.lastname ?? ""}`,
    },
    {
      subTitle: i18n.t("collaborator.firstName", "Firstname"),
      description: `${employee?.firstname ?? ""}`,
    },
    {
      subTitle: i18n.t("collaborator.email", "Email"),
      description: `${employee?.company_email ?? ""}`,
    },
    {
      subTitle: i18n.t("collaborator.category", "Category"),
      description: `${employee?.department ?? ""}`,
    },
    {
      subTitle: i18n.t("collaborator.dateOfAssignment", "Date of assignment"),
      description: `${
        vehicule?.start_date
          ? dayjs(vehicule.start_date).format("DD-MM-YYYY")
          : ""
      }`,
    },
    {
      subTitle: i18n.t("collaborator.assignmentEndDate", "Assignment end date"),
      description: `${
        vehicule?.end_date ? dayjs(vehicule.end_date).format("DD-MM-YYYY") : ""
      }`,
    },
  ] as subDocuments[];
};
