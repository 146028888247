import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles((_) => ({
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "12px",
    backgroundColor: colors.primary,
  },
  logo: {
    width: "226px",
    height: "130px",
  },
  text: {
    color: colors.WHITE,
    marginTop: "21px",
    marginBottom: "23px",
    textAlign: "center",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
    width: "350px",
  },
  subContainer2: {
    width: "190px",
  },
}));
export default useStyles;
