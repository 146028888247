import { FC } from "react";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { colors } from "../../styles/colors";
import useStyles from "./styles";

type props = {
  label: string;
  value: string;
};

export const CustomCost: FC<props> = ({ label, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.subContainer}>
      <CustomTypography text={label} variant={"h5"} color={colors.primary} />
      <CustomTypography text={value} variant={"h2"} />
    </div>
  );
};
