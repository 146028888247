import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles({
  divInfosCollab: {
    display: "flex",
    flexDirection: "column",
    padding: "15px 0px 15px 0px",
    gap: "20px",
  },
  divInfosCollabDashboard: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },

  divDate: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  },
  divImat: {
    display: "flex",
    flexDirection: "row",
    height: "40px",
    padding: "10px",
    justifyContent: "space-between",
    backgroundColor: colors.default,
    alignItems: "center",
    "& > div": { flex: 1 },
  },
});

export default useStyles;
