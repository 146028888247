import { combineReducers } from "redux"; // Imports: Reducers

import vehicleReducer from "./vehicleReducer";
import employeeReducer from "./employeeReducer";
import sinisterReducer from "./sinisterReducer";

// Redux: Root Reducer
const rootReducer = combineReducers({
  vehicleReducer: vehicleReducer,
  employeeReducer: employeeReducer,
  sinisterReducer: sinisterReducer,
});

export default rootReducer;
