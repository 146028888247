import { FormControl, OutlinedInput } from "@mui/material";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import useStyles from "./styles";
import { ChangeEventHandler } from "react";

type Props = {
  placeholder?: string;
  label?: string;
  marginBottom?: string | number;
  rows?: string | number;
  onChange?:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  value?: unknown;
  name?: string;
  type?: string;
  disabled?: boolean;
};

export const CustomTextInput: React.FC<Props> = ({
  placeholder,
  label,
  marginBottom,
  rows,
  value,
  name,
  type,
  disabled,
  onChange,
}) => {
  const classes = useStyles({ marginBottom, rows });

  return (
    <div className={classes.container}>
      {label && (
        <div className={classes.containerLabel}>
          <CustomTypography
            text={label}
            variant={"link"}
            textDecoration={"none"}
          />
        </div>
      )}
      <FormControl className={classes.formControl} variant="outlined">
        <OutlinedInput
          fullWidth
          onChange={onChange}
          name={name}
          value={value}
          type={type}
          placeholder={placeholder}
          multiline={rows ? true : false}
          rows={rows}
          disabled={disabled}
        />
      </FormControl>
    </div>
  );
};
