import { FC } from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import { DocumentsPanel } from "../../../../components/DocumentsPanel/DocumentsPanel";
import {
  transformDataSinister,
  transformDataSinisterDetail,
  transformDataVehicle,
} from "./data";
import { DocumentsPanelPictures } from "../../../../components/DocumentsPanelPictures/DocumentsPanelPictures";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { colors } from "../../../../styles/colors";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { CustomTypographyLabelValue } from "../../../../components/CustomTypographyLabelValue/CustomTypographyLabelValue";
import {
  useGetSinisterById,
  useGetSinisterDocuments,
  useGetSinisterEmployee,
} from "../../../../api/sinistre";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useSelector } from "react-redux";
import { useGetOneVehicle } from "../../../../api/vehicule";
import { useNavigate } from "react-router";

type Props = {
  onClickPrevious?: () => void;
};

export const StepValidationClaims: FC<Props> = ({ onClickPrevious }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { vehicle_id, id: sinisterId } = useSelector(
    (state: any) => state.sinisterReducer.sinisterNewlyCreated
  );
  const { sinisterDetail } = useGetSinisterById(
    getCurrentFleetId(),
    sinisterId
  );
  const { sinisterEmployee } = useGetSinisterEmployee(
    getCurrentFleetId(),
    vehicle_id,
    sinisterId
  );
  const { sinisterDocs } = useGetSinisterDocuments(
    getCurrentFleetId(),
    vehicle_id,
    sinisterId
  );
  const { vehicule } = useGetOneVehicle(getCurrentFleetId(), vehicle_id);
  const images = sinisterDocs
    .filter((file: any) => file.document_type.name !== "CST")
    .map((file) => file.scan_id);
  const files = sinisterDocs.filter(
    (file: any) => file.document_type.name === "CST"
  );

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <div className={classes.containerDocuments}>
          <DocumentsPanel
            title={t("claims.sinister", "Sinister")}
            descriptions={transformDataSinister(
              sinisterDetail,
              sinisterEmployee
            )}
            minHeight={"0px"}
          />
          <div className={classes.sinisterCommentary}>
            <DocumentsPanel
              title={t("common.details", "Details")}
              descriptions={transformDataSinisterDetail(sinisterDetail)}
              minHeight={"0px"}
            />
          </div>

          <DocumentsPanel
            title={t("vehicule.vehicule", "Vehicle")}
            descriptions={transformDataVehicle(vehicule)}
            minHeight={"0px"}
          />
        </div>
        <div className={classes.containerDocuments}>
          <DocumentsPanelPictures
            title={t("claims.pictures", "Pictures")}
            images={images}
            minHeight={"0px"}
          />
          {/* div resume documents */}
          <div className={classes.divDocuments}>
            <div>
              <CustomTypography
                text={t("common.documents", "Documents")}
                variant={"h4"}
                color={colors.primary}
                isUppercase
              />
            </div>
            <div className={classes.divContent}>
              {files.map((file: any) => (
                <div className={classes.subContainer2} key={file?.id}>
                  <IconWithBackground iconName={"cards"} />
                  <CustomTypographyLabelValue
                    label={"Nom du fichier"}
                    value={file?.name ?? ""}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className={classes.containerButton}>
          <CustomButton
            onClick={onClickPrevious}
            label={t("common.previous", "Previous")}
            variant={"default"}
            width={"fit-content"}
            height={"40px"}
          />
          <CustomButton
            onClick={() => navigate(`/claims/update/${sinisterId}`)}
            label={t("claims.seeTheDisaster", "see The Disaster")}
            variant={"primary"}
            width={"fit-content"}
            height={"40px"}
          />
        </div>
      </div>
    </div>
  );
};
