import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { colors } from "../../../../styles/colors";
import { CustomButtonTable } from "../../../../components/CustomButtonTable/CustomButtonTable";
import { CustomDropZoneUpload } from "../../../../components/CustomDropZoneUpload/CustomDropZoneUpload";
import { CustomButtonDropZone } from "../../../../components/CustomButtonDropZone/CustomButtonDropZone";
import { CustomTextInput } from "../../../../components/CustomTextInput/CustomTextInput";
import { useSelector } from "react-redux";
import { useSinisterAddDocs } from "../../../../api/sinistre";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { uploadFileMultiple } from "../../../../utils/firebaseConfig";

type Props = {
  onClickPrevious?: () => void;
  onClickSaveAndNext?: () => void;
};

interface TDoc {
  file: File | null;
  doc_name: string;
  doc_type: string;
}

export const StepAddDocuments: FC<Props> = ({
  onClickPrevious,
  onClickSaveAndNext,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { vehicle_id, id: sinisterId } = useSelector(
    (state: any) => state.sinisterReducer.sinisterNewlyCreated
  );

  const { mutate: addDocs } = useSinisterAddDocs(
    getCurrentFleetId(),
    vehicle_id,
    sinisterId,
    onClickSaveAndNext
  );

  const [customDropZones, setCustomDropZones] = useState<TDoc[]>([
    { file: null, doc_name: "", doc_type: "" },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  const handleAddCustomDropZone = () => {
    setCustomDropZones((prevDropZones) => [
      ...prevDropZones,
      { file: null, doc_name: "", doc_type: "" }, // Initialiser description à vide
    ]);
  };

  const onDrop = (acceptedFiles: File[]) => {
    setCustomDropZones((prevDropZones) => {
      let newDropZones = [...prevDropZones];
      let fileIndex = 0;

      // Trouver les indices null et les remplacer par les fichiers acceptés
      newDropZones = newDropZones.map((dropZone) => {
        if (dropZone.file === null && fileIndex < acceptedFiles.length) {
          const fileToAdd = {
            file: acceptedFiles[fileIndex],
            doc_name: "",
            doc_type: acceptedFiles[fileIndex].type,
          }; // Initialiser description à vide
          fileIndex += 1;
          return fileToAdd;
        }
        return dropZone;
      });
      // Si tous les null sont remplacés et qu'il reste des fichiers, les ajouter à la fin
      if (fileIndex < acceptedFiles.length) {
        newDropZones = newDropZones.concat(
          acceptedFiles
            .slice(fileIndex)
            .map((file) => ({ file, doc_name: "", doc_type: file.type })) // Initialiser description à vide
        );
      }
      return newDropZones;
    });
  };

  const handleChangeDescription = (index: number, value: string) => {
    setCustomDropZones((prevDropZones) => {
      // Créer une copie profonde de l'array pour éviter la mutation directe
      const newDropZones = prevDropZones.map((dropZone, i) => {
        if (i === index) {
          return { ...dropZone, doc_name: value };
        }
        return dropZone;
      });
      return newDropZones;
    });
  };

  const onSaveAndNext = async () => {
    const newFileToUpload = customDropZones.filter(
      (file) => file?.file !== null
    );
    if (newFileToUpload.length > 0) {
      setIsLoading(true);
      try {
        // Attendre ici que Promise.all soit résolu avant de continuer
        const dataToSet = await Promise.all(
          newFileToUpload.map(async (fileDoc) => {
            const urlUploadedFile =
              fileDoc?.file && (await uploadFileMultiple(fileDoc.file));
            return {
              doc_url: urlUploadedFile as string,
              doc_name: fileDoc.doc_name,
              doc_type: "CST",
            };
          })
        );
        addDocs({ docs: dataToSet });
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.divPhoto}>
        <div className={classes.divSubPhoto}>
          {/* left */}
          <div className={classes.divLeft}>
            <CustomTypography
              text={t("claims.pictures", "Pictures")}
              variant="h4"
              isUppercase
            />
            <div className={classes.divFormat}>
              <div>
                <CustomTypography
                  text={`${t("common.acceptedFormats", "Accepted formats")} :`}
                  variant="link"
                  fontWeight={"bold"}
                />
                <CustomTypography
                  text={"PNG - JPG - JPEG - PDF"}
                  variant="link"
                />
              </div>
              <div>
                <CustomTypography
                  text={`${t("common.maxSize", "Max size")} :`}
                  variant="link"
                  fontWeight={"bold"}
                />
                <CustomTypography text={"10 Mo"} variant="link" />
              </div>
            </div>
            <CustomTypography
              text={t(
                "claims.instructions",
                "Please upload your photo. Click on + to add more or on the delete icon to delete all."
              )}
              variant="link"
              color={colors.greydark}
            />
            <CustomButtonTable
              variant={"tertiary"}
              textVariant={"link"}
              label={t("common.deleteAll", "Delete all")}
              textTransform={"uppercase"}
              width={"fit-content"}
              fontWeight={"bold"}
              textDecoration={"none"}
              iconName={"trash"}
              onClick={() =>
                setCustomDropZones([{ file: null, doc_name: "", doc_type: "" }])
              }
            />
          </div>
          {/* right */}
          <div className={classes.divRight}>
            <div className={classes.divRightSection}>
              {customDropZones.map((file, index) => (
                <div className={classes.divRightSection2} key={index}>
                  <CustomDropZoneUpload
                    width={"377px"}
                    height={"100px"}
                    iconHeight={"25.33px"}
                    iconWidth={"25.33px"}
                    borderRadius={"12px"}
                    accept={{
                      "image/png": [".png"],
                      "image/jpeg": [".jpg", ".jpeg"],
                      "application/pdf": [".pdf"],
                    }}
                    uploadedFile={file.file}
                    onDrop={onDrop}
                  />
                  <CustomTextInput
                    label={`${t(
                      "claims.fileNameOrDescription",
                      "File name or description"
                    )} *`}
                    placeholder={t(
                      "claims.fileNameOrDescription",
                      "File name or description"
                    )}
                    value={file.doc_name}
                    onChange={(e) =>
                      handleChangeDescription(index, e.target.value)
                    }
                  />
                </div>
              ))}
            </div>
            {/* button */}
            <CustomButtonDropZone
              handleAddCustomDropZone={handleAddCustomDropZone}
            />
          </div>
        </div>
      </div>
      <div className={classes.containerAllButton}>
        <CustomButton
          onClick={onClickPrevious}
          label={t("common.previous", "Previous")}
          variant={"default"}
          width={"fit-content"}
          height={"40px"}
        />
        <div className={classes.containerButton}>
          <CustomButton
            label={t("common.saveButton", "Save")}
            variant={"default"}
            width={"fit-content"}
            height={"40px"}
          />
          <CustomButton
            label={t("common.saveAndNext", "Save and next")}
            variant={"primary"}
            width={"fit-content"}
            onClick={onSaveAndNext}
            loading={isLoading}
            height={"40px"}
          />
        </div>
      </div>
    </div>
  );
};
