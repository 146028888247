import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  carIconContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  vehiculeRoot: {
    padding: "20px 30px",
    height: "calc(100vh - 40px)",
    backgroundColor: colors.bgcolor,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    height: "calc(100vh - 140px)",
    overflow: "auto",
  },
  subContainerLeft: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },

  finalItem: {
    display: "flex",
    gap: 15,
  },
  rightItem: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
  },
  subContainerRight: {
    display: "flex",
    flexDirection: "column",
    gap: 15,
    width: "100%",
  },
  containerCar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // maxWidth: 1283,
    width: "100%",
  },
  containerPneumatique: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  },
}));

export default useStyles;
