import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  InputAdornment,
  TextField,
} from "@mui/material";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import useStyles from "./styles";
import { Icon } from "../Icon/Icon";
import { colors } from "../../styles/colors";
import { ChangeEventHandler, SyntheticEvent } from "react";

type Props = {
  placeholder?: string;
  label?: string;
  marginBottom?: string | number;
  options: readonly unknown[];
  width?: string | number;
  helperTextError?: string;
  value?: any
  defaultValue?: any
  getOptionLabel?: (option: unknown) => string;
  onChange?: (
    event: SyntheticEvent<Element, Event>,
    value: {},
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined
  ) => void;
  onChangeTextField?:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
};

export const CustomSearch: React.FC<Props> = ({
  placeholder,
  label,
  marginBottom,
  options,
  value,
  defaultValue,
  getOptionLabel,
  width,
  helperTextError,
  onChange,
  onChangeTextField,
}) => {
  const classes = useStyles({
    marginBottom,
    width,
    isError:
      helperTextError && helperTextError?.trim()?.length > 0 ? true : false,
  });

  return (
    <div className={classes.container}>
      {label && (
        <div className={classes.containerLabel}>
          <CustomTypography
            text={label}
            variant={"link"}
            textDecoration={"none"}
          />
        </div>
      )}

      <Autocomplete
        freeSolo
        fullWidth
        disableClearable
        options={options}
        getOptionLabel={getOptionLabel}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        renderInput={(params: any) => (
          <TextField
            className={classes.formControl}
            placeholder={placeholder}
            helperText={helperTextError}
            onChange={onChangeTextField}
            {...params}
            // SelectProps={{
            //   MenuProps: {
            //     anchorOrigin: {
            //       vertical: "bottom",
            //       horizontal: "left",
            //     },
            //     getContentAnchorEl: null,
            //   },
            // }}
            InputProps={{
              ...params.InputProps,
              // type: "search",
              endAdornment: (
                <InputAdornment position="end">
                  <Icon
                    name="search"
                    width={"20px"}
                    height={"20px"}
                    color={colors.darkblack}
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};
