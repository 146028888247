import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  },
});

export default useStyles;
