import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

const useStyles = createUseStyles((_) => ({
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: colors.primary,
  },
  logo: {
    width: "226px",
    height: "130px",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "350px",
    marginTop: "36px",
  },
  customLabel: {
    margin: "9px 16px",
    "& .MuiTypography-root": {
      color: colors.WHITE,
      fontFamily: "Poppins-Regular",
      fontSize: 13,
    },
  },
  customCheckbox: {
    "& .MuiSvgIcon-root": {
      fontSize: 13,
      color: colors.WHITE,
    },
  },
  forgotPassword: {
    textAlign: "center",
    marginTop: "17px",
    cursor: "pointer",
  },
  subContainer2: {
    width: "190px",
  },
}));

export default useStyles;
