import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import useStyles from "./styles";
import { colors } from "../../styles/colors";
import { isMobile } from "react-device-detect";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";

type Props = {};

export const RedirectEmployee: React.FC<Props> = () => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const linkUser = queryParams.get("linkUser");

  const handleGotToCodenekt = () => {
    if (isMobile) {
      const queryString = `codenekt://send?${
        window?.location?.href?.split("?")[1]
      }`;
      window.location.href = queryString;
    } else {
      toast.error("Vous devez être sur un appareil mobile pour continuer");
    }
  };

  const handleDownloadCodenekt = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=com.codenekt&gl=FR";
  };

  const handleDownloadAppleStore = () => {
    window.open(
      "https://apps.apple.com/fr/app/codenekt/id1571102682?utm_medium=email&_hsmi=156463109&_hsenc=p2ANqtz-8JNQHNchrQwvHDZM7Ol_J8rw4x524W4fBmkEyQ-JPVpYP4nnrS--4X3Zejfc8GlVo5Tbh1skocF8u8FsaLCaIxXPGJew&utm_content=156463109&utm_source=hs_email",
      "_blank"
    );
  };

  return (
    <div className={classes.container}>
      <Logo className={classes.logo} />

      <div className={classes.subContainer2}>
        <CustomTypography
          variant={"link"}
          textDecoration={"none"}
          color={colors.WHITE}
          styles={{ containerText: classes.forgotPassword }}
          text={"Si vous avez déja l'appication CodeNekt, cliquez ici"}
        />

        <CustomButton
          label={"Ouvrir Codenekt"}
          height={"40px"}
          onClick={handleGotToCodenekt}
          variant={"white"}
        />

        {!linkUser && (
          <>
            <CustomTypography
              variant={"link"}
              textDecoration={"none"}
              color={colors.WHITE}
              styles={{ containerText: classes.forgotPassword }}
              text={
                "Si vous n'avez pas encore l'appication CodeNekt, téléchargé ici"
              }
            />
            <CustomButton
              label={"Codenekt sur playstore"}
              height={"40px"}
              onClick={handleDownloadCodenekt}
              variant={"white"}
            />
            <CustomButton
              label={"Codenekt sur applestore"}
              height={"40px"}
              onClick={handleDownloadAppleStore}
              variant={"white"}
            />
          </>
        )}
      </div>
    </div>
  );
};
