import useStyles from "./styles";
import { CustomModal } from "../../../../components/CustomModal/CustomModal";
import { useTranslation } from "react-i18next";
import { CustomTextInput } from "../../../../components/CustomTextInput/CustomTextInput";
import { CustomTextSelect } from "../../../../components/CustomTextSelect/CustomTextSelect";
import { CustomTextDatePicker } from "../../../../components/CustomTextDatePicker/CustomTextDatePicker";
import { FC, useEffect } from "react";
import { useParams } from "react-router";
import {
  useCreateContratVehicle,
  useGetContratByVehicleId,
  useUpdateContratVehicle,
} from "../../../../api/contrat";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useFormik } from "formik";
import {
  initialValuesVehicleContrat,
  validationSchemaCreateVehicleContrat,
} from "../../CreateVehicule/StepContract/validationSchemaContrat";
import {
  getPeriodicity,
  getPeriodicityByValue,
} from "../../../../utils/periodicity";
import dayjs from "dayjs";

type props = {
  opeModal: boolean;
  onClose: () => void;
  onCancel: () => void;
};

export const ModalUpdateVehicleContrat: FC<props> = ({
  opeModal,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const vehicleId = parseInt(id);

  const { contrats } = useGetContratByVehicleId(getCurrentFleetId(), vehicleId);

  const { mutate: updateVehicle, isPending } = useUpdateContratVehicle(
    getCurrentFleetId(),
    vehicleId,
    contrats[0]?.id,
    onClose
  );

  const { mutate: createContratVehicle, isPending: isLoading } =
    useCreateContratVehicle(getCurrentFleetId(), vehicleId, onClose);

  useEffect(() => {
    if (contrats?.[0]?.id) {
      formik.setFieldValue("contractNumber", contrats[0].contract_number);
      formik.setFieldValue("durationInMonths", contrats[0].duration);
      formik.setFieldValue("kmOnContract", contrats[0].contract_km);
      formik.setFieldValue(
        "numberOfWinterTires",
        String(contrats[0].number_winter_tire)
      );
      formik.setFieldValue(
        "numberOfSummerTires",
        String(contrats[0].number_summer_tire)
      );
      formik.setFieldValue("dateOfEntry", String(contrats[0].start_date));
      formik.setFieldValue("releaseDate", String(contrats[0].end_date));
      formik.setFieldValue("rentExcludingTax", contrats[0].rental_tax);
      formik.setFieldValue("owner_company_id", contrats[0].owner_company_id);
      formik.setFieldValue(
        "periodicity",
        getPeriodicityByValue(contrats[0].periodicity_id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contrats?.[0]?.id]);

  const formik = useFormik({
    initialValues: initialValuesVehicleContrat,
    validationSchema: validationSchemaCreateVehicleContrat,
    onSubmit: (values) => {
      const contrat = {
        contract_number: values.contractNumber ?? "",
        duration: values.durationInMonths ?? 0,
        start_date: values.dateOfEntry ?? "",
        end_date: dayjs(values.releaseDate).format("YYYY-MM-DD") ?? "",
        contract_km: values.kmOnContract ?? 0,
        rental_tax: values.rentExcludingTax ?? 0,
        periodicity_id: getPeriodicity(String(values.periodicity)) ?? "",
        number_winter_tire: values?.numberOfWinterTires ?? 0,
        number_summer_tire: values?.numberOfSummerTires ?? 0,
        owner_company_id: Number(values.owner_company_id),
      };
      if (contrats?.[0]?.id) {
        updateVehicle(contrat);
      } else {
        createContratVehicle(contrat);
      }
    },
  });

  useEffect(() => {
    if (formik?.values?.durationInMonths && formik?.values?.dateOfEntry) {
      const date = dayjs(formik?.values?.dateOfEntry)
        .add(formik?.values?.durationInMonths, "month")
        .subtract(1, "day");

      formik?.setFieldValue("releaseDate", dayjs(date));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.durationInMonths, formik?.values?.dateOfEntry]);

  const handleSave = () => {
    formik.handleSubmit();
  };

  const arraysNumber: string[] = Array.from({ length: 10 }, (_, index) =>
    (index + 1).toString()
  );
  return (
    <CustomModal
      width={"663px"}
      open={opeModal}
      onCancel={onCancel}
      title={t("vehicule.editContrat", "Edit contrat")}
      onClose={onClose}
      onSave={handleSave}
      loadingSave={isPending || isLoading}
    >
      <div className={classes.containerText}>
        <div className={classes.divModalText}>
          <CustomTextInput
            label={`${t("vehicule.contractNumber", "Contract number")} *`}
            placeholder={t("vehicule.contractNumber", "Contract number")}
            name="contractNumber"
            value={formik?.values?.contractNumber}
            onChange={formik.handleChange}
          />
          <CustomTextInput
            label={`${t("vehicule.durationInMonths", "Duration in months")} *`}
            placeholder={t("vehicule.durationInMonths", "Duration in months")}
            type="number"
            name="durationInMonths"
            value={formik?.values?.durationInMonths}
            onChange={formik.handleChange}
            marginBottom={"0px"}
          />
        </div>

        <div className={classes.divModalText}>
          <CustomTextDatePicker
            label={`${t("vehicule.dateOfEntry", "Date of entry")} *`}
            value={dayjs(formik?.values?.dateOfEntry) as any}
            onChange={(value) =>
              formik?.setFieldValue(
                "dateOfEntry",
                dayjs(value).format("YYYY-MM-DD")
              )
            }
          />
          <CustomTextDatePicker
            label={`${t("vehicule.releaseDate", "Release date")} *`}
            value={dayjs(formik?.values?.releaseDate) as any}
            onChange={(value) =>
              formik?.setFieldValue(
                "releaseDate",
                dayjs(value).format("YYYY-MM-DD")
              )
            }
          />
        </div>

        <CustomTextInput
          label={`${t("vehicule.kmOnContract", "Km on contract")} *`}
          placeholder={t("vehicule.kmOnContract", "Km on contract")}
          type="number"
          name="kmOnContract"
          value={formik?.values?.kmOnContract}
          onChange={formik.handleChange}
          marginBottom={"0px"}
        />
        <div className={classes.divModalText}>
          <CustomTextSelect
            label={t("vehicule.numberOfWinterTires", "Number of winter tires")}
            options={arraysNumber}
            placeholder={t(
              "vehicule.numberOfWinterTires",
              "Number of winter tires"
            )}
            name="numberOfWinterTires"
            value={formik.values.numberOfWinterTires}
            onChange={formik.handleChange}
          />
          <CustomTextSelect
            label={t("vehicule.numberOfSummerTires", "Number of summer tires")}
            options={arraysNumber}
            placeholder={t(
              "vehicule.numberOfSummerTires",
              "Number of summer tires"
            )}
            name="numberOfSummerTires"
            value={formik.values.numberOfSummerTires}
            onChange={formik.handleChange}
          />
        </div>
        <div className={classes.divModalText}>
          <CustomTextInput
            label={`${t("vehicule.rentExcludingTax", "Rent exluding tax")} *`}
            placeholder={t("vehicule.rentExcludingTax", "Rent exluding tax")}
            name="rentExcludingTax"
            type="number"
            value={formik?.values?.rentExcludingTax}
            onChange={formik.handleChange}
          />
          <CustomTextSelect
            label={`${t("common.periodicity", "Periodicity")} *`}
            options={["Journalier", "Mensuel", "Annuel"]}
            placeholder={t("vehicule.monthly", "Monthly")}
            name="periodicity"
            value={formik?.values?.periodicity}
            onChange={formik.handleChange}
          />
        </div>
      </div>
    </CustomModal>
  );
};
