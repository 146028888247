import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { Sidebar } from "../../../containers/Sidebar/Sidebar";
import useStyles from "./styles";
import { CustomTable } from "../../../components/CustomTable/CustomTable";
import { CustomButtonTable } from "../../../components/CustomButtonTable/CustomButtonTable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getCurrentFleetId } from "../../../utils/getCurrentFleet";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";
import { useGetAllEmployeeWithVehicle } from "../../../api/employee";
import { columnsCollaborator } from "./columnsCollaborator";

type Props = {};

export const ListCollaborator: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { employees, isPending } = useGetAllEmployeeWithVehicle(
    getCurrentFleetId()
  );

  return (
    <>
      {isPending && <CustomLoader isActive={isPending} />}
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.containerHeader}>
          <CustomTypography
            variant="h2"
            text={t("collaborator.collaboratorList", "Collaborator list")}
            isUppercase
          />
          <CustomButtonTable
            variant={"primary"}
            textVariant={"link"}
            label={t("collaborator.addACollaborator", "Add a collaborator")}
            width={"fit-content"}
            fontWeight={"bold"}
            textDecoration={"none"}
            iconName={"plus"}
            padding={"0px 15px"}
            textTransform="uppercase"
            onClick={() => navigate("/collaborator/create")}
          />
        </div>
        <CustomTable
          columns={columnsCollaborator}
          data={employees}
          isSearchable
          tableMaxHeight={"520px"}
        />
      </Sidebar>
    </>
  );
};
