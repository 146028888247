import dayjs from "dayjs";
import i18n from "../../../../i18n/i18n";

type documents = {
  icoName: string;
  title: string;
  subTitle: string;
};

export const transformDataCards = (cardsAndBadges: TCardsAndBadges[] | []) => {
  return cardsAndBadges.map((cardOrbadge) => {
    return {
      icoName: "cards",
      title: i18n.t("collaborator.cards", "Cards"),
      subTitle: `${cardOrbadge.fleet_card.fleet_card_type.type} <br/>
                    ${cardOrbadge.fleet_card.fleet_card_type.marque} <br/> 
                    ${
                      cardOrbadge.fleet_card.release_date
                        ? dayjs(cardOrbadge.fleet_card.release_date).format(
                            "DD-MM-YYYY"
                          )
                        : ""
                    }`,
    };
  }) as documents[];
};
