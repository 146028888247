import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 0px 15px 0px",
  },
  vehiculeRoot: {
    padding: "30px 30px",
    overflowY: "auto",
    height: "calc(100vh - 60px)",
    backgroundColor: colors.bgcolor,
  },
  divContent: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },

  divContentTCO: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  },
  divSearch: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    alignItems: "flex-end",
    "&>div": {
      marginBottom: 0,
      "& .MuiFormControl-root": {
        marginTop: 0,
      },
    },
  },
  subContainer2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: "202px",
    borderRadius: "15px",
    padding: "20px",
    backgroundColor: colors.WHITE,
    flex: 1,
    gap: "15px",
    height: "fit-content",
  },

  divResultSearch: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "30px 0px 0px 0px",
  },
  divTextResult: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default useStyles;
