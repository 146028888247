import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles((_) => ({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  vehiculeRoot: {
    overflowY: "auto",
    padding: "20px 30px",
    height: "calc(100vh - 40px)",
    backgroundColor: colors.bgcolor,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "35px",
    height: "calc(100vh - 140px)",
    overflow: "auto",
  },

  divbuttonHeader: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  },
}));

export default useStyles;
