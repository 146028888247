import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { Icon } from "../../../../components/Icon/Icon";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { colors } from "../../../../styles/colors";
import { dataDocuments } from "./data";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
// @ts-ignore
import { Lightbox } from "react-modal-image";
import { useState } from "react";
import { useGetDocumentByEmployee } from "../../../../api/document";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useParams } from "react-router";

type Props = {};

export const DocumentsCollaborator: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const userId = parseInt(id);

  const { documents } = useGetDocumentByEmployee(getCurrentFleetId(), userId);
  const [openImage, setOpenImage] = useState(false);
  const [currentDocumentToShow, setCurrentDocumentToShow] = useState<
    string | undefined
  >(undefined);

  const onClickDocument = (id: string) => {
    switch (id) {
      // select carte d'identité
      case "1":
        const documentIdentity = documents?.find(
          (document) => document.type === "indentity"
        );
        if (documentIdentity) {
          setCurrentDocumentToShow(documentIdentity.scan_id);
          setOpenImage(true);
        }
        break;
      // select permis
      case "2":
        const document = documents?.find(
          (document) => document.type === "license_card"
        );
        if (document) {
          setCurrentDocumentToShow(document.scan_id);
          setOpenImage(true);
        }
        break;

      default:
        break;
    }
  };

  const onCloseModalImage = () => {
    setOpenImage(false);
  };

  return (
    <>
      {openImage && (
        <div className={classes.modalImageCustom}>
          <Lightbox
            medium={currentDocumentToShow}
            large={currentDocumentToShow}
            small={currentDocumentToShow ?? ""}
            alt="Document"
            hideDownload
            hideZoom
            // @ts-ignore
            onClose={onCloseModalImage}
          />
        </div>
      )}

      <div className={classes.container}>
        <div className={classes.containerHeader}>
          <CustomTypography
            isUppercase
            text={t("panel.document.documents", "Documents")}
            variant={"h5"}
            styles={{ containerText: classes.contratTitle }}
          />
          {/* <CustomIconButton
            iconName={"pencil"}
            widthIcon={"20px"}
            heightIcon={"20px"}
          /> */}
        </div>
        {dataDocuments.map((document, index) => (
          <div className={classes.subContainer} key={index}>
            <div className={classes.subContainer2}>
              <IconWithBackground iconName={document.icoName} />
              <div>
                <CustomTypography
                  text={document.title}
                  isUppercase
                  variant="link"
                  fontWeight={"bold"}
                />
                <CustomTypography text={document.subTitle} variant="link" />
              </div>
            </div>
            <IconButton
              className={classes.iconButton}
              onClick={() => onClickDocument(document.id)}
            >
              <Icon
                name="eye"
                width={"24px"}
                height={"24px"}
                color={colors.BLACK}
              />
            </IconButton>
          </div>
        ))}
      </div>
    </>
  );
};
