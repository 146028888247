import { useTranslation } from "react-i18next";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import useStyles from "./styles";
import { Sidebar } from "../../../containers/Sidebar/Sidebar";
import { CustomButtonTable } from "../../../components/CustomButtonTable/CustomButtonTable";
import { Icon } from "../../../components/Icon/Icon";
import { VehiculeSection } from "./VehiculeSection/VehiculeSection";
import { CostSection } from "./CostSection/CostSection";
import { Documents } from "./Documents/Documents";
import { Mileage } from "./Mileage/Mileage";
import { Pneumatique } from "./Pneumatique/Pneumatique";
import { Collaborator } from "./Collaborator/Collaborator";
import { CustomTable } from "../../../components/CustomTable/CustomTable";
import { columnsClaims, columnsHistory } from "./data";
import { useNavigate, useParams } from "react-router";
import { CustomIconButton } from "../../../components/CustomIconButton/CustomIconButton";
import { colors } from "../../../styles/colors";
import { getCurrentFleetId } from "../../../utils/getCurrentFleet";
import { useGetAllEvents } from "../../../api/event";

type Props = {};

export const UpdateVehicule: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id = "" } = useParams();
  const vehicleId = parseInt(id);

  const { events } = useGetAllEvents(getCurrentFleetId(), vehicleId, 1, 10);

  return (
    <Sidebar
      styles={{
        sidebarRightRoot: classes.vehiculeRoot,
        sidebarRoot: classes.contentMain,
      }}
    >
      <div className={classes.container}>
        <div className={classes.containerCar}>
          <div>
            <CustomTypography
              variant="h2"
              text={t("vehicule.vehicule", "Car")}
              lineHeight={"47px"}
            />
            <div className={classes.carIconContainer}>
              <Icon name={"vehicule"} height={24} width={24} />
              <CustomTypography variant="default" text={`ID: ${vehicleId}`} />
            </div>
          </div>
          <CustomButtonTable
            // variant={"secondary"}
            variant="default"
            textVariant={"link"}
            label={t(
              "panel.disableTheVehicle.disableTheVehicle",
              "DISABLE THE VEHICLE"
            )}
            width={"fit-content"}
            fontWeight={"bold"}
            textDecoration={"none"}
            iconName={"trash"}
          />
        </div>
        <div className={classes.subContainer}>
          <div className={classes.subContainerLeft}>
            <VehiculeSection />
            <CostSection />
            <div className={classes.finalItem}>
              <Documents />
              <div className={classes.rightItem}>
                <Mileage />
              </div>
            </div>
          </div>
          <div className={classes.subContainerRight}>
            <div className={classes.containerPneumatique}>
              <Pneumatique />
              <Collaborator />
            </div>
            <CustomTable
              columns={columnsHistory}
              data={events}
              tableMaxHeight={"200px"}
              tableMinHeight={"200px"}
              titleHeader={t(
                "panel.historyOfInterventions.historyOfInterventions",
                "HISTORY OF INTERVENTIONS"
              )}
              // labelButtonHeader={t(
              //   "panel.mileage.relaunchTheEmployee",
              //   "Relaunch the employee"
              // )}
              // iconNameButton={"bell"}
              componentHeader={
                <CustomIconButton
                  backgroundColor={colors.greydark}
                  iconName={"plus"}
                  widthIcon={"20px"}
                  heightIcon={"20px"}
                />
              }
              labelButtonFooter={t(
                "panel.historyOfInterventions.seeAllInterventions",
                "See all interventions"
              )}
              showIconEye
              showPagination={false}
            />
            <CustomTable
              titleHeader={t("panel.claims.claims", "Claims")}
              columns={columnsClaims}
              data={[]}
              labelButtonFooter={t(
                "panel.claims.seeAllClaims",
                "See all claims"
              )}
              componentHeader={
                <CustomIconButton
                  iconName={"plus"}
                  widthIcon={"20px"}
                  heightIcon={"20px"}
                  onClick={() => navigate("/claims/create")}
                />
              }
              showIconEye
              tableMaxHeight={"228px"}
              tableMinHeight={"228px"}
              showPagination={false}
              onClickButtonFooter={() => navigate("/claims/list")}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};
