import useStyles from "./styles";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { CustomIconButton } from "../../../../components/CustomIconButton/CustomIconButton";
import { useTranslation } from "react-i18next";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { CustomButtonModal } from "../../../../components/CustomButtonModal/CustomButtonModal";
import { useNavigate, useParams } from "react-router";
import { useGetOneVehicle } from "../../../../api/vehicule";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";

type Props = {};

export const Collaborator: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id = "" } = useParams();
  const vehicleId = parseInt(id);
  const { vehicule } = useGetOneVehicle(getCurrentFleetId(), vehicleId);

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <CustomTypography
          isUppercase
          text={t("panel.collaborator.collaborator", "Collaborator")}
          variant={"h5"}
          styles={{ containerText: classes.contratTitle }}
        />
        <CustomIconButton
          iconName={"plus"}
          widthIcon={"20px"}
          heightIcon={"20px"}
          onClick={() => navigate("/collaborator/create")}
        />
      </div>
      <div className={classes.subContainerIcon}>
        <div className={classes.subContainer2}>
          <IconWithBackground iconName={"collaborateur"} />
          <div>
            <CustomTypography
              text={`${vehicule?.firstname ?? ""} ${
                vehicule?.lastname ?? ""
              }`}
              //  t(
              //   "panel.collaborator.nameAndFirstName",
              //   "Name and firstname"
              // )
              isUppercase
              variant="link"
              fontWeight={"bold"}
            />

            <CustomTypography
              isUppercase
              text={`ID ${
                vehicule?.userId ??
                t("panel.collaborator.collaborator", "Collaborator")
              }`}
              variant="link"
              lineHeight={"19px"}
            />
          </div>
        </div>
        <div className={classes.subContainer2}>
          <CustomButtonModal
            variant={"default"}
            textVariant={"link"}
            label={t(
              "panel.collaborator.seeCollaborator",
              "See the collaborator"
            )}
            onClick={() =>
              vehicule?.userId &&
              navigate(`/collaborator/update/${vehicule.userId}`)
            }
            width={"fit-content"}
            textDecoration={"none"}
            iconName={"eye"}
            padding={"0px 10px 0px 10px"}
          />
        </div>
      </div>
    </div>
  );
};
