import { FC } from "react";
import { CustomModal } from "../../../../components/CustomModal/CustomModal";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import useStyles from "./styles";
import { useParams } from "react-router";
import {
  useGetVehiculesByEmployee,
  useUnAssignVehicleToEmployee,
} from "../../../../api/vehicule";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";

type props = {
  openRemove: boolean;
  onClose: () => void;
  onCancel: () => void;
};

export const CustomModalDisablevehicle: FC<props> = ({
  openRemove,
  onCancel,
  onClose,
}) => {
  const { id = "" } = useParams();
  const userId = parseInt(id);

  const classes = useStyles();
  const { t } = useTranslation();

  const { vehicules } = useGetVehiculesByEmployee(getCurrentFleetId(), userId);
  const { mutate: disabled } = useUnAssignVehicleToEmployee(
    getCurrentFleetId(),
    userId,
    vehicules[0]?.vehicleId ?? -1,
    onClose
  );

  const onConfirmDisabled = () => {
    if (vehicules[0]?.vehicleId) {
      disabled();
    } else {
      toast.error(t("collaborator.noVehicle", "No vehicle to remove"));
    }
  };

  return (
    <CustomModal
      open={openRemove}
      onCancel={onCancel}
      title={t("collaborator.collaborator", "Collaborator")}
      onClose={onClose}
      onSave={() => toast.success(t("common.changeSaved", "Change saved"))}
      isConfirmation
      textConfirmation={t("common.disabled", "Disabled")}
      onConfirm={onConfirmDisabled}
    >
      <div className={classes.textModalContainer}>
        <CustomTypography
          text={t(
            "collaborator.removeWarning",
            "</br>You are about to collect the employee's vehicle. </br></br>Do you confirm this action?"
          )}
          variant="default"
        />
      </div>
    </CustomModal>
  );
};
