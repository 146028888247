import { useTranslation } from "react-i18next";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { Sidebar } from "../../../containers/Sidebar/Sidebar";
import useStyles from "./styles";
import i18n from "../../../i18n/i18n";
import { CustomStepper } from "../../../components/CustomStepper/CustomStepper";
import { useState } from "react";
import { StepSearchCollaborator } from "./StepSearchCollaborator/StepSearchCollaborator";
import { StepFileCollaborator } from "./StepFileCollaborator/StepFileCollaborator";
import { StepCardAndBadge } from "./StepCardAndBadge/StepCardAndBadge";
import { StepAffectation } from "./StepAffectation/StepAffectation";
import { StepValidationCollab } from "./StepValidationCollab/StepValidationCollab";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";

type Props = {};

const steps = [
  i18n.t("collaborator.search", "Search"),
  i18n.t("collaborator.collaboratorFile", "Collaborator file"),
  i18n.t("collaborator.vehicleAssignment", "Vehicle assignment"),
  i18n.t("collaborator.cardsAndBadges", "Cards and badges"),
  i18n.t(
    "collaborator.validationOfTheEmployeeProfile",
    "Validation of the employee profile"
  ),
];

export const CreateCollaborator: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentEmployee, setCurrentEmployee] =
    useState<TEmployeeWithoutVehicle | null>(null);

  const onChangeCurrentEmployee = (
    employee: TEmployeeWithoutVehicle | null
  ) => {
    setCurrentEmployee(employee);
  };

  const onClickSendStep0 = () => {
    if (currentEmployee === null) {
      toast.error(t("required.checkEmployeeName", "Name is required"));
    } else {
      setCurrentStep(1);
    }
  };
  return (
    <Sidebar
      styles={{
        sidebarRightRoot: classes.vehiculeRoot,
        sidebarRoot: classes.contentMain,
      }}
    >
      <div className={classes.container}>
        <div className={classes.containerRoot}>
          <CustomTypography
            variant="h2"
            isUppercase
            text={t("collaborator.addACollaborator", "Add a collaborator")}
          />
        </div>
        <div className={classes.subContainer}>
          <CustomStepper steps={steps} activeStep={currentStep} />
          <div className={classes.subContainerStepper}>
            <div className={classes.subsubContainer}>
              {currentStep === 1 && (
                <CustomTypography
                  isUppercase
                  text={t("collaborator.collaboratorFile", "Collaborator file")}
                  variant={"h3"}
                />
              )}
              {currentStep === 2 && (
                <CustomTypography
                  isUppercase
                  text={t(
                    "collaborator.vehicleAssignment",
                    "Vehicle assignment"
                  )}
                  variant={"h3"}
                />
              )}
              {currentStep === 3 && (
                <CustomTypography
                  isUppercase
                  text={t(
                    "avantage.benefitsCardsAndBadges",
                    "Benefits cards and badges"
                  )}
                  variant={"h3"}
                />
              )}
              {currentStep === 4 && (
                <CustomTypography
                  isUppercase
                  text={t(
                    "collaborator.validationOfTheEmployeeProfile",
                    "validation of the employee profile"
                  )}
                  variant={"h3"}
                />
              )}
              <div className={classes.content}>
                {/* content of create collaborator */}
                {currentStep === 0 && (
                  <StepSearchCollaborator
                    onClickSend={onClickSendStep0}
                    onChangeCurrentEmployee={onChangeCurrentEmployee}
                  />
                )}
                {currentStep === 1 && (
                  <StepFileCollaborator
                    currentEmployee={currentEmployee}
                    onClickPrevious={() => setCurrentStep(0)}
                    onClickSaveAndNext={setCurrentStep}
                  />
                )}
                {currentStep === 2 && (
                  <StepAffectation
                    onClickSend={() => setCurrentStep(3)}
                    currentEmployee={currentEmployee}
                  />
                )}
                {currentStep === 3 && (
                  <StepCardAndBadge
                    onClickPrevious={() => setCurrentStep(2)}
                    onClickSaveAndNext={() => setCurrentStep(4)}
                    currentEmployee={currentEmployee}
                  />
                )}
                {currentStep === 4 && (
                  <StepValidationCollab
                    onClickPrevious={() => setCurrentStep(3)}
                    onClickSave={() =>
                      navigate(`/collaborator/update/${currentEmployee?.user_id}`)
                    }
                    currentEmployee={currentEmployee}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
};
