import { ChangeEvent, FC, useState } from "react";
import { CustomSearch } from "../../../../components/CustomSearch/CustomSearch";
import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import useStyles from "./styles";
import { CustomTextDatePicker } from "../../../../components/CustomTextDatePicker/CustomTextDatePicker";
import {
  useAssignVehicleToEmployee,
  useGetAllVehiculesFleet,
} from "../../../../api/vehicule";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import toast from "react-hot-toast";
import dayjs from "dayjs";

type Props = {
  currentEmployee: TEmployeeWithoutVehicle | null;
  onClickSend?: () => void;
};

export const StepAffectation: FC<Props> = ({
  currentEmployee,
  onClickSend,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { vehicules } = useGetAllVehiculesFleet(
    getCurrentFleetId(),
    1,
    5000,
    "",
    "F"
  );

  const [selectedVehicleId, setSelectedVehicleId] = useState<number | null>(
    null
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const { mutate: assign, isPending } = useAssignVehicleToEmployee(
    getCurrentFleetId(),
    currentEmployee?.user_id || 0,
    selectedVehicleId || 0,
    onClickSend
  );

  const onChangeTextFieldVehicle = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.trim().length === 0) {
      setSelectedVehicleId(null);
    }
  };

  const onChangeAutoCompletVehicle = (_: any, vehicle: any) => {
    setSelectedVehicleId(vehicle.vehicleId);
  };

  const onClickSave = () => {
    if (currentEmployee === null) {
      toast.error("La séléction du collaborateur est obligatoire");
      return;
    } else if (
      selectedVehicleId === null ||
      selectedStartDate === null ||
      selectedEndDate === null
    ) {
      toast.error(t("required.checkFields", "All fields are required"));
    } else {
      assign({
        start_date: dayjs(selectedStartDate).format("YYYY-MM-DD"),
        end_date: dayjs(selectedEndDate).format("YYYY-MM-DD"),
      });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.containerSearch}>
        <CustomSearch
          options={vehicules}
          getOptionLabel={(option: any) =>
            `${option?.brand} ${option?.brandModel} - ${option?.immatriculation}`
          }
          label={`${t("collaborator.findAvehicle", "Find vehicle")} *`}
          placeholder={t("common.find", "Find")}
          marginBottom={"0px"}
          onChange={onChangeAutoCompletVehicle}
          onChangeTextField={onChangeTextFieldVehicle}
        />
        <CustomTextDatePicker
          label={`${t(
            "collaborator.assignmentStartDate",
            "Assignment start date"
          )} *`}
          value={selectedStartDate}
          onChange={(value) => setSelectedStartDate(value)}
        />
        <CustomTextDatePicker
          label={`${t(
            "collaborator.assignmentEndDate",
            "Assignment end date"
          )} *`}
          value={selectedEndDate}
          onChange={(value) => setSelectedEndDate(value)}
        />
        <CustomButton
          loading={isPending}
          onClick={onClickSave}
          label={t("collaborator.assignVehicle", "Assign vehicle")}
          variant={"primary"}
          width={"fit-content"}
          height={"40px"}
        />
      </div>
    </div>
  );
};
