export enum MintStatus {
  IGNORED = "ignored",
  TO_BE_VALIDATED = "to-be-validated",
  TO_BE_MINTED = "to-be-minted",
  MINTING = "minting",
  MINTED = "minted",
  FAILED = "failed",
}

//  ON_ORDER
//  AFFECTED
//  FREE
//  WAITING_FOR_VALIDATION

export enum VehicleStatus {
  "En commande" = "O",
  "Affecté" = "A",
  "Libre" = "F",
  "En attente de validation" = "W",
}

export const getValueByKeyVehicleStatus = (key: string): string | undefined => {
  return VehicleStatus[key as keyof typeof VehicleStatus];
};

export const getKeyLabelByValue = (
  value: "O" | "A" | "F" | "W"
): string | undefined => {
  return Object.entries(VehicleStatus).find(
    ([_, enumValue]) => enumValue === value
  )?.[0];
};

export const getStatusFromValues = (
  value: string
): "COMMAND" | "AFFECTED" | "FREE" | "ACTIF" | "WAITING" => {
  switch (value) {
    case "O":
      return "COMMAND";

    case "A":
      return "AFFECTED";

    case "F":
      return "FREE";

    case "W":
      return "WAITING";

    default:
      return "FREE";
  }
};
