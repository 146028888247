import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  divSinisterInfos: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "15px",
    borderRadius: "15px",
    backgroundColor: colors.WHITE,
    // width: "579px",
    width: "100%",
  },
  divCollaborator: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divInfosSinister: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    "& > div": {
      flex: 1,
    },
  },
  divInfosSinisterColums: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
}));

export default useStyles;
