import { ReactComponent as Logo } from "../../assets/svg/logo.svg";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import VerificationInput from "react-verification-input";
import { useState } from "react";
import { useLocation } from "react-router";
import { useConfirmCode } from "../../api/user";

type Props = {};

export const EnterCodeForgotPassword: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email");

  const { mutate: verifyCode, isPending } = useConfirmCode();

  const [code, setCode] = useState<string>("");

  const handleConfirm = () => {
    verifyCode({ email: email ?? "", code });
  };

  return (
    <div className={classes.container}>
      <Logo className={classes.logo} />
      <CustomTypography
        variant="link"
        textDecoration={"none"}
        styles={{ containerText: classes.text }}
        text={t(
          "login.enterCodeReceivedInEmail",
          "nter the code received by email"
        )}
      />
      <div className={classes.subContainer}>
        <VerificationInput
          classNames={{ container: classes.verificationCode }}
          onChange={(value) => setCode(value)}
        />
        <div className={classes.subContainer2}>
          <CustomButton
            onClick={handleConfirm}
            height={"40px"}
            variant={"white"}
            label={t("login.newPassword", "VALIDATE")}
            loading={isPending}
          />
        </div>
      </div>
    </div>
  );
};
