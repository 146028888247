import { createUseStyles } from "react-jss";
const useStyles = createUseStyles((_) => ({
  textModalContainer: {
    padding: "0px 0px 29px 0px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
}));

export default useStyles;
