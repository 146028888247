import { colors } from "../../styles/colors";
import useStyles from "./styles";
import { CustomTypography } from "../CustomTypography/CustomTypography";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { useDropzone } from "react-dropzone";

type props = {
  onDrop: (acceptedFiles: File[]) => void;
};

export const CustomUploadButton: FC<props> = ({ onDrop }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "text/csv": [".csv"],
    },
    multiple: false,
    maxSize: 10 * 1024 * 1024, // 10 megabytes in bytes
  });

  return (
    <div className={classes.divUpload} {...getRootProps()}>
      <input {...getInputProps()} />
      <CustomTypography
        text={t("common.import", "Import")}
        variant={"h5"}
        color={colors.WHITE}
        isUppercase
      />
    </div>
  );
};
