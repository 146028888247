import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../components/CustomButton/CustomButton";
import { CustomToggle } from "../../../components/CustomToggle/CustomToggle";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import useStyles from "./styles";

export const Communication = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.divCom}>
      <CustomTypography text={"Communication"} variant={"h3"} isUppercase />
      <div className={classes.divCom2}>
        <div className={classes.divToggle}>
          <CustomToggle isActive />
          <CustomTypography
            text={"Type d’événement / notifications"}
            variant={"default"}
          />
        </div>
        <div className={classes.divToggle}>
          <CustomToggle />
          <CustomTypography
            text={"Type d’événement / notifications"}
            variant={"default"}
          />
        </div>
        <div className={classes.divToggle}>
          <CustomToggle isActive />
          <CustomTypography
            text={"Type d’événement / notifications"}
            variant={"default"}
          />
        </div>
        <div className={classes.divToggle}>
          <CustomToggle />
          <CustomTypography
            text={"Type d’événement / notifications"}
            variant={"default"}
          />
        </div>
        <CustomButton
          label={t("common.saveButton", "Save")}
          variant={"primary"}
          width={"fit-content"}
          height={"40px"}
        />
      </div>
    </div>
  );
};
