import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type Props = {
  isSearchable?: boolean;
  showPagination?: boolean;
  isBackgroundUnique?: boolean;
  isTitleHeader?: boolean;
};

const useStyles = createUseStyles((_) => ({
  container: {
    borderRadius: "15px",
    padding: "20px",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
    "& .MuiTableContainer-root .MuiTable-root": {
      "& .MuiTableHead-root": {
        "& .MuiTableCell-root": {
          height: "40px",
          padding: "0px 10px",
          textTransform: "uppercase",
          textWrap: "nowrap",
        },
      },
      "& .MuiTableBody-root": {
        "& .MuiTableCell-root": {
          height: "40px",
          padding: "0px 10px",
          border: 0,
          textWrap: "nowrap",
        },
        "& .MuiTableRow-root:nth-child(even)": {
          backgroundColor: (props: Props) =>
            props.isBackgroundUnique ? colors.WHITE : "#F9F9F9",
        },
      },
    },
  },
  formControl: {
    "&.MuiFormControl-root": {
      "& input": {
        padding: "8px",
        paddingLeft: "16px",
        "&::placeholder": {
          color: colors.BLACK,
          fontFamily: "Poppins-Bold",
          fontSize: "10px",
        },
      },
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputBase-root.MuiOutlinedInput-root": {
        fontFamily: "Poppins-Regular",
        backgroundColor: colors.default,
        borderRadius: "50px",
        fontSize: "11px",
      },
    },
  },
  contratTitle: {
    color: colors.primary,
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: (props: Props) =>
      props.isTitleHeader ? "space-between" : "flex-end",
    marginBottom: "9px",
  },
  footerContainer: {
    marginTop: "13px",
    display: "flex",
    alignItems: "center",
    flexDirection: (props: Props) =>
      props.showPagination ? "row-reverse" : "row",
    justifyContent: "space-between",
  },
  containerTable: {},
  iconSort: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    cursor: "pointer",
  },
  componentHeader: {
    marginLeft: "10px",
    marginRight: "10px",
  },
  divSearchAndHeader: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
}));

export default useStyles;
