import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles({
  divCom: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    padding: "15px",
    gap: "15px",
    backgroundColor: colors.WHITE,
    height: "fit-content",
  },
  divCom2: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  divToggle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
  },
});

export default useStyles;
