import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { Sidebar } from "../../../containers/Sidebar/Sidebar";
import useStyles from "./styles";
import { CustomTable } from "../../../components/CustomTable/CustomTable";
import { useTranslation } from "react-i18next";

import { CustomButtonTable } from "../../../components/CustomButtonTable/CustomButtonTable";
import { useState } from "react";
import { CustomMenuEnchorNotification } from "../../../components/CustomEnchorNotification/CustomMenuEnchorNotification/CustomMenuEnchorNotification";
import { useGetAllNotification } from "../../../api/notification";
import { getCurrentFleetId } from "../../../utils/getCurrentFleet";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";
import { columnsNotification } from "./notificationColumns";

type Props = {};

export const ListNotification: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { notifications, isPending } = useGetAllNotification(
    getCurrentFleetId()
  );
console.log('notifications', notifications)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {isPending && <CustomLoader isActive={isPending} />}
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.containerHeader}>
          <CustomTypography
            variant="h2"
            text={t("sidebar.notifications", "Notifications")}
            isUppercase
          />
        </div>
        <CustomTable
          columns={columnsNotification}
          data={notifications}
          isSearchable
          showSelectedField
          tableMaxHeight={"520px"}
          isBackgroundUnique
          componentHeader={
            <>
              <CustomButtonTable
                variant={"primary"}
                textVariant={"link"}
                label={t("common.groupeActions", "Groupe actions")}
                width={"fit-content"}
                fontWeight={"bold"}
                textDecoration={"none"}
                iconName={"chevronDown"}
                padding={"0px 15px"}
                textTransform="uppercase"
                endIcon
                onClick={handleClick}
              />
              <CustomMenuEnchorNotification
                anchorEl={anchorEl}
                open={open}
                handleClose={handleClose}
              />
            </>
          }
        />
      </Sidebar>
    </>
  );
};
