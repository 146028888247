import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import axios from "./axios";

export const userKeys = {
  currentUser: () => ["currentUser"] as const,
  newUser: () => ["newUser"] as const,
};

export interface RequestLogin {
  email: string;
  password: string;
}

export const useUserLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return useMutation({
    mutationKey: userKeys.currentUser(),
    mutationFn: (newUser: RequestLogin) => {
      return axios.post("/user/login", JSON.stringify(newUser));
    },
    onSuccess: (data) => {
      const { data: user } = data;
      if (user?.company_users[0]?.company_role === "FLEET_MANAGER") {
        localStorage.setItem("currentUser", JSON.stringify(user));
        localStorage.setItem("token", user?.sessions?.[0]?.token);
        toast.success(t("login.success", "Authentication successful!"));
        window?.location?.reload();
        navigate("/dashboard");
      } else {
        toast.error(t("login.accessDenied", "Access denied"));
      }
    },
    onError: (err: any) => {
      toast.error(
        t(`errorLogin.${err?.response?.data?.message}`, "Erreur identifiant")
      );
    },
  });
};

export interface RequestRegisterFleetUser {
  email: string;
  password: string;
  code: string;
  "password-confirmation": string;
  company: string;
}

export const useRegisterFleetUser = () => {
  const { t } = useTranslation();
  return useMutation({
    mutationKey: userKeys.newUser(),
    mutationFn: async (user: RequestRegisterFleetUser) => {
      const data = await axios.post("/user/register-fleet-user", user);
      return data;
    },
    onSuccess: (_) => {
      toast.success(t("login.registered", "You are registered"));
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorLogin.${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export interface RequestSendCode {
  email: string;
}

export const useSendCode = (email: string) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return useMutation({
    mutationKey: userKeys.newUser(),
    mutationFn: (user: RequestSendCode) => {
      return axios.post("/user/send-code", user);
    },
    onSuccess: () => {
      navigate(`/enter_code_forgot_password?email=${email}`);
      toast.success(t("common.emailSend", "Email send"));
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorLogin.${err?.response?.data?.message}`,
          "Une erreur est survenue"
        )
      );
    },
  });
};

export interface RequestConfirmCode {
  email: string;
  code: string;
}

export const useConfirmCode = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return useMutation({
    mutationKey: userKeys.newUser(),
    mutationFn: (user: RequestConfirmCode) => {
      return axios.post("/user/add", user);
    },
    onSuccess: (data: any) => {
      localStorage.setItem("token", data?.data?.token);
      navigate(`/forgot_password?idUser=${data?.data?.id}`);
      toast.success(t("common.validCode", "Valid code"));
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorLogin.${err?.response?.data?.message}`,
          "Une erreur est survenue"
        )
      );
    },
  });
};

export interface RequestConfirmUpdatePassword {
  id: number;
  password: string;
}

export const useConfirmUdpatePassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return useMutation({
    mutationKey: userKeys.newUser(),
    mutationFn: (user: RequestConfirmUpdatePassword) => {
      return axios.put("/user/update", user);
    },
    onSuccess: (_) => {
      localStorage.clear();
      navigate("/");
      toast.success(t("login.passwordReseted", "Password reset"));
    },
    onError: (err: any) => {
      toast.error(
        t(
          `errorLogin.${err?.response?.data?.message}`,
          "Une erreur est survenue"
        )
      );
    },
  });
};
