import { createUseStyles } from "react-jss";
type Props = {
  backgroundColor?: string;
  width?: string | number;
  height?: string | number;
};
const useStyles = createUseStyles({
  divCardinfo: {
    width: (props: Props) => (props.width ? props.width : "143px"),
    height: (props: Props) => (props.height ? props.height : "103.5px"),
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    backgroundColor: (props: Props) =>
      props.backgroundColor ? props.backgroundColor : undefined,
  },
  odometer: {
    overflow: "hidden",
    height: "22px",
    lineHeight: "16px",
  },
  item: {
    display: "inline-block",
    writingMode: "vertical-rl",
    textOrientation: "upright",
    verticalAlign: "top",
  },
});
export default useStyles;
