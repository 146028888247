import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { transformDataCards } from "./data";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import {
  typeOfCardOrBadge,
  useGetAllCardsAndBadgeFleetByEmployee,
} from "../../../../api/badge";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useParams } from "react-router";
import { CustomIconButton } from "../../../../components/CustomIconButton/CustomIconButton";
import { ModalAddCard } from "./ModalAddCard";
import { useState } from "react";
import { useGetOneEmployee } from "../../../../api/employee";
type Props = {};

export const CarteCollaborator: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const userId = parseInt(id);

  const { employee } = useGetOneEmployee(getCurrentFleetId(), userId);

  const { cardsAndBadges } = useGetAllCardsAndBadgeFleetByEmployee(
    getCurrentFleetId(),
    employee?.id ?? userId
  );

  const [openModal, setOpenModal] = useState(false);

  const onClose = () => {
    setOpenModal(false);
  };

  const cardsForCurrentEmployee = cardsAndBadges.filter(
    (cards) =>
      cards.fleet_card.fleet_card_type.support_type === typeOfCardOrBadge.card
  );

  return (
    <>
      <ModalAddCard opeModal={openModal} onCancel={onClose} onClose={onClose} />
      <div className={classes.container}>
        <div className={classes.containerHeader}>
          <CustomTypography
            isUppercase
            text={t("collaborator.cards", "Cards")}
            variant={"h5"}
            styles={{ containerText: classes.contratTitle }}
          />
          <CustomIconButton
            iconName={"plus"}
            widthIcon={"20px"}
            heightIcon={"20px"}
            onClick={() => setOpenModal(true)}
          />
        </div>
        {transformDataCards(cardsForCurrentEmployee).map((document, index) => (
          <div className={classes.subContainer} key={index}>
            <div className={classes.subContainer2}>
              <IconWithBackground iconName={document.icoName} />
              <div>
                <CustomTypography
                  text={document.title}
                  isUppercase
                  variant="link"
                  fontWeight={"bold"}
                />
                <CustomTypography text={document.subTitle} variant="link" />
              </div>
            </div>
            {/* <IconButton className={classes.iconButton}>
            <Icon
              name="eye"
              width={"24px"}
              height={"24px"}
              color={colors.BLACK}
            />
          </IconButton> */}
          </div>
        ))}
      </div>
    </>
  );
};
