import useStyles from "./styles";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { useTranslation } from "react-i18next";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { useParams } from "react-router";
import { useGetContratByVehicleId } from "../../../../api/contrat";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";

type Props = {};

export const Pneumatique: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const vehicleId = parseInt(id);

  const { contrats } = useGetContratByVehicleId(getCurrentFleetId(), vehicleId);

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <CustomTypography
          isUppercase
          text={t("panel.tires.tires", "Tires")}
          variant={"h5"}
          styles={{ containerText: classes.contratTitle }}
        />
        {/* <CustomIconButton
          iconName={"pencil"}
          widthIcon={"20px"}
          heightIcon={"20px"}
        /> */}
      </div>
      <div className={classes.subContainerIcon}>
        <div className={classes.subContainer2}>
          <IconWithBackground iconName={"sunHigh"} />
          <div>
            <CustomTypography
              text={t("panel.tires.summerTires", "Summer tires")}
              isUppercase
              variant="link"
              fontWeight={"bold"}
              lineHeight={"8px"}
            />
            {contrats?.[0]?.number_summer_tire && (
              <CustomTypography
                text={`${t("panel.tires.consumed", "Consumed")}: ${
                  contrats?.[0]?.number_summer_tire
                }`}
                variant="link"
              />
            )}

            {/* <CustomTypography
              text={`${t("panel.tires.remaining", "Remaining")}: 02`}
              variant="link"
              lineHeight={"6px"}
            /> */}
          </div>
        </div>
        <div className={classes.subContainer2}>
          <IconWithBackground iconName={"snowFlake"} />
          <div>
            <CustomTypography
              text={t("panel.tires.winterTires", "Winter tires")}
              isUppercase
              variant="link"
              fontWeight={"bold"}
              lineHeight={"8px"}
            />
            {contrats?.[0]?.number_winter_tire && (
              <CustomTypography
                text={`${t("panel.tires.consumed", "Consumed")}: ${
                  contrats?.[0]?.number_winter_tire
                }`}
                variant="link"
              />
            )}

            {/* <CustomTypography
              text={`${t("panel.tires.remaining", "Remaining")}: 02`}
              variant="link"
              lineHeight={"6px"}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
