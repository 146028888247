import { useTranslation } from "react-i18next";
import { CustomButton } from "../../../components/CustomButton/CustomButton";
import { CustomTextSelect } from "../../../components/CustomTextSelect/CustomTextSelect";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import useStyles from "./styles";

export const ContainerTVA = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.divContainerTVA}>
      <CustomTypography
        text={t("setting.companyVAT", "company VAT")}
        variant={"h3"}
        isUppercase
      />
      <div className={classes.divButtonTVA}>
        <CustomTextSelect
          label={t("setting.selectTheVATrate", "Select the VAT rate")}
          placeholder={"20%"}
        />
        <CustomButton
          label={t("common.apply", "Apply")}
          variant={"primary"}
          width={"fit-content"}
          height={"40px"}
        />
      </div>
    </div>
  );
};
