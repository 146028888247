import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  vehiculeRoot: {
    padding: "30px 30px",
    overflowY: "auto",
    height: "calc(100vh - 60px)",
    backgroundColor: colors.bgcolor,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  subContainer: {
    borderRadius: "15px",
    padding: "15px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    backgroundColor: colors.WHITE,
  },
  containerRoot: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "94px",
    justifyContent: "space-between",
  },
  subContainerStepper: {
    borderRadius: "15px",
    padding: "30px 10px 30px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    backgroundColor: colors.bgcolor,
  },
  subsubContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "25px 15px 25px 15px",
    gap: "10px",
    alignItems: "center",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
    gap: "20px",
    width: "100%",
  },
});

export default useStyles;
