import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { Sidebar } from "../../containers/Sidebar/Sidebar";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomButtonTable } from "../../components/CustomButtonTable/CustomButtonTable";
import { useState } from "react";
import { ModalCarteAndBadge } from "../../containers/ModalCarteAndBadge/ModalCarteAndBadge";
import { typeOfCardOrBadge } from "../../api/badge";
import Cards from "./Cards/Cards";
import Badges from "./Badges/Badges";

type Props = {};

export const CarteAndBadge: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openModalAddCard, setOpenModalAddCard] = useState(false);
  const [openModalAddbadge, setOpenModalAddbadge] = useState(false);

  return (
    <>
      <ModalCarteAndBadge
        open={openModalAddCard}
        currentCartOrBadge={null}
        title={t("collaborator.newCard", "New card")}
        supportTypes={typeOfCardOrBadge.card}
        onCancel={() => setOpenModalAddCard(false)}
        onClose={() => setOpenModalAddCard(false)}
      />
      <ModalCarteAndBadge
        open={openModalAddbadge}
        currentCartOrBadge={null}
        supportTypes={typeOfCardOrBadge.badge}
        title={t("collaborator.newBadge", "New badge")}
        onCancel={() => setOpenModalAddbadge(false)}
        onClose={() => setOpenModalAddbadge(false)}
      />
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.container}>
          <div className={classes.containerHeader}>
            <div>
              <CustomTypography
                variant="h2"
                text={t(
                  "carteBadge.listOfCardsAndBadges",
                  "List of cards and badges"
                )}
                lineHeight={"47px"}
                isUppercase
              />
            </div>
            <div className={classes.divbuttonHeader}>
              <CustomButtonTable
                variant={"secondary"}
                textVariant={"link"}
                label={t("carteBadge.addCard", "Add card")}
                textTransform={"uppercase"}
                width={"fit-content"}
                fontWeight={"bold"}
                textDecoration={"none"}
                iconName={"plus"}
                onClick={() => setOpenModalAddCard(true)}
              />
              <CustomButtonTable
                variant={"primary"}
                textVariant={"link"}
                label={t("carteBadge.addBadge", "Add badge")}
                textTransform={"uppercase"}
                width={"fit-content"}
                fontWeight={"bold"}
                textDecoration={"none"}
                iconName={"plus"}
                onClick={() => setOpenModalAddbadge(true)}
              />
            </div>
          </div>
          <div className={classes.subContainer}>
            <Cards />
            <Badges />
          </div>
        </div>
      </Sidebar>
    </>
  );
};
