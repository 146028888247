import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  subContainer: {
    width: "66%",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  containerDocuments: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    justifyContent: "space-between",
    overflowX: "auto",
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    justifyContent: "space-between",
  },
});

export default useStyles;
