import { FC } from "react";
import { CustomIconButton } from "../../../components/CustomIconButton/CustomIconButton";
import { colors } from "../../../styles/colors";
import useStyles from "./styles";
import { useNavigate } from "react-router";

type props = {
  collaborator: TEmployee;
};

export const ButtonsActionsCollaborator: FC<props> = ({ collaborator }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const onClickCollab = () => {
    navigate(`/collaborator/update/${collaborator.userId}`);
  };

  const onCLickVehicle = () => {
    navigate(`/vehicule/update/${collaborator.vehicleId}`);
  };

  return (
    <div className={classes.buttonsCollab}>
      {collaborator.vehicleId && (
        <CustomIconButton
          iconName={"vehicule"}
          widthIcon={"30px"}
          heightIcon={"30px"}
          backgroundColor={colors.primary}
          onClick={onCLickVehicle}
        />
      )}

      <CustomIconButton
        iconName={"collaborateur"}
        widthIcon={"30px"}
        heightIcon={"30px"}
        backgroundColor={colors.secondary}
        onClick={onClickCollab}
      />
    </div>
  );
};
