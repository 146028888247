import { CustomTextInput } from "../../../../components/CustomTextInput/CustomTextInput";
import { useTranslation } from "react-i18next";
import { CustomTextDatePicker } from "../../../../components/CustomTextDatePicker/CustomTextDatePicker";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import useStyles from "./styles";
import { CustomTextSelect } from "../../../../components/CustomTextSelect/CustomTextSelect";
import { FC, useEffect } from "react";
import { useFormik } from "formik";
import {
  initialValuesVehicleContrat,
  validationSchemaCreateVehicleContrat,
} from "./validationSchemaContrat";
import dayjs from "dayjs";
import { getPeriodicity } from "../../../../utils/periodicity";
import { useCreateContratVehicle } from "../../../../api/contrat";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

type Props = {
  onClickPrevious?: () => void;
  onClickSaveAndNext?: () => void;
};

export const StepContract: FC<Props> = ({
  onClickPrevious,
  onClickSaveAndNext,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const newlyCreatedVehicle = useSelector(
    (state: any) => state.vehicleReducer.vehicule
  );

  const selectedRentalCompany = useSelector(
    (state: any) => state.vehicleReducer.selectedRentalCompany
  );

  const { mutate: createContratVehicle, isPending } = useCreateContratVehicle(
    getCurrentFleetId(),
    newlyCreatedVehicle?.id,
    onClickSaveAndNext
  );
  const arraysNumber: string[] = Array.from({ length: 10 }, (_, index) =>
    (index + 1).toString()
  );

  const formik = useFormik({
    initialValues: initialValuesVehicleContrat,
    validationSchema: validationSchemaCreateVehicleContrat,
    onSubmit: (values) => {
      if (newlyCreatedVehicle?.id) {
        createContratVehicle({
          contract_number: values.contractNumber ?? "",
          duration: values.durationInMonths ?? 0,
          start_date: values.dateOfEntry ?? "",
          end_date:  dayjs(values.releaseDate).format("YYYY-MM-DD") ?? "",
          contract_km: values.kmOnContract ?? 0,
          rental_tax: values.rentExcludingTax ?? 0,
          periodicity_id: getPeriodicity(String(values.periodicity)) ?? "",
          number_winter_tire: values?.numberOfWinterTires ?? 0,
          number_summer_tire: values?.numberOfSummerTires ?? 0,
          owner_company_id:
            selectedRentalCompany.label.trim().length === 0
              ? getCurrentFleetId()
              : Number(selectedRentalCompany.value),
        });
      } else {
        toast.error(
          "Vous devez sélectionner un véhicule ou créer un nouveau avant de remplir le contrat"
        );
      }
    },
  });
  
  useEffect(() => {
    if (formik?.values?.durationInMonths && formik?.values?.dateOfEntry) {
      const date = dayjs(formik?.values?.dateOfEntry).add(
        formik?.values?.durationInMonths,
        "month"
      ).subtract(1, "day")
     
      formik?.setFieldValue("releaseDate", dayjs(date));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.durationInMonths, formik?.values?.dateOfEntry]);

  const handleSave = () => {
    formik.handleSubmit();
  };


  return (
    <div className={classes.container}>
      <div className={classes.containerInput}>
        <CustomTextInput
          label={`${t("vehicule.contractNumber", "Contract number")} *`}
          placeholder={t("vehicule.contractNumber", "Contract number")}
          name="contractNumber"
          value={formik?.values?.contractNumber}
          onChange={formik.handleChange}
        />
        <CustomTextInput
          label={`${t("vehicule.durationInMonths", "Duration in months")} *`}
          placeholder={t("vehicule.durationInMonths", "Duration in months")}
          type="number"
          name="durationInMonths"
          value={formik?.values?.durationInMonths}
          onChange={formik.handleChange}
        />
      </div>
      <div className={classes.containerInput}>
        <CustomTextDatePicker
          label={`${t("vehicule.dateOfEntry", "Date of entry")} *`}
          onChange={(value) =>
            formik?.setFieldValue(
              "dateOfEntry",
              dayjs(value).format("YYYY-MM-DD")
            )
          }
        />
        <CustomTextDatePicker
          label={`${t("vehicule.releaseDate", "Release date")} *`}
          value={dayjs(formik?.values?.releaseDate) as any}
          onChange={(value) =>
            formik?.setFieldValue(
              "releaseDate",
              dayjs(value).format("YYYY-MM-DD")
            )
          }
        />
      </div>
      <div className={classes.containerInput}>
        <CustomTextInput
          label={`${t("vehicule.kmOnContract", "Km on contract")} *`}
          placeholder={t("vehicule.kmOnContract", "Km on contract")}
          type="number"
          name="kmOnContract"
          value={formik?.values?.kmOnContract}
          onChange={formik.handleChange}
        />
        <div />
      </div>

      <div className={classes.containerInput}>
        <CustomTextSelect
          label={t("vehicule.numberOfWinterTires", "Number of winter tires")}
          options={arraysNumber}
          placeholder={t(
            "vehicule.numberOfWinterTires",
            "Number of winter tires"
          )}
          name="numberOfWinterTires"
          value={formik.values.numberOfWinterTires}
          onChange={formik.handleChange}
        />
        <CustomTextSelect
          label={t("vehicule.numberOfSummerTires", "Number of summer tires")}
          options={arraysNumber}
          placeholder={t(
            "vehicule.numberOfSummerTires",
            "Number of summer tires"
          )}
          name="numberOfSummerTires"
          value={formik.values.numberOfSummerTires}
          onChange={formik.handleChange}
        />
      </div>
      <div className={classes.containerInput}>
        <CustomTextInput
          label={`${t("vehicule.rentExcludingTax", "Rent exluding tax")} *`}
          placeholder={t("vehicule.rentExcludingTax", "Rent exluding tax")}
          name="rentExcludingTax"
          type="number"
          value={formik?.values?.rentExcludingTax}
          onChange={formik.handleChange}
        />
        <CustomTextSelect
          label={`${t("common.periodicity", "Periodicity")} *`}
          options={["Journalier", "Mensuel", "Annuel"]}
          placeholder={t("vehicule.monthly", "Monthly")}
          name="periodicity"
          value={formik?.values?.periodicity}
          onChange={formik.handleChange}
        />
      </div>
      <div className={classes.containerRequire}>
        {/* <CustomTypography
          variant={"link"}
          color={colors.primary}
          text={t("vehicule.allFieldsAreRequired", "All fields are required")}
        /> */}
      </div>
      <div className={classes.containerAllButton}>
        <CustomButton
          onClick={onClickPrevious}
          label={t("vehicule.previous", "Previous")}
          variant={"default"}
          width={"fit-content"}
          height={"40px"}
        />
        <div className={classes.containerButton}>
          <CustomButton
            label={t("vehicule.save", "Save")}
            width={"fit-content"}
            height={"40px"}
            onClick={handleSave}
            variant={formik.isValid && formik.dirty ? "default" : "disabled"}
          />
          <CustomButton
            loading={isPending}
            label={t("vehicule.saveAndNext", "Save and next")}
            width={"fit-content"}
            onClick={handleSave}
            height={"40px"}
            variant={formik.isValid && formik.dirty ? "primary" : "disabled"}
          />
        </div>
      </div>
    </div>
  );
};
