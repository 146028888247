import useStyles from "./styles";
import { useTranslation } from "react-i18next";

import { Sidebar } from "../../containers/Sidebar/Sidebar";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { colors } from "../../styles/colors";
import { CustomSearch } from "../../components/CustomSearch/CustomSearch";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { CustomCost } from "../../components/CustomCost/CustomCost";
import { ResultSearchVehicle } from "./ResultSearchVehicle/ResultSearchVehicle";
import { ResultSearchCollab } from "./ResultSearchCollab/ResultSearchCollab";
import { useState } from "react";

type Props = {};

export const TCO: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showResultVehicle, setShowResultVehicle] = useState(false);
  const [showResultCollab, setShowResultCollab] = useState(false);

  return (
    <Sidebar
      styles={{
        sidebarRightRoot: classes.vehiculeRoot,
        sidebarRoot: classes.contentMain,
      }}
    >
      <div className={classes.containerHeader}>
        <CustomTypography
          variant="h2"
          text={t("fleet.costOfTheFleet", "TCO - Cost of the fleet")}
          isUppercase
        />
      </div>
      <div className={classes.divContent}>
        <div className={classes.divContentTCO}>
          <CustomCost
            label={t(
              "panel.average.averageMonthlyCost",
              "AVERAGE MONTHLY COST"
            )}
            value={"€ 229,67"}
          />
          <CustomCost
            label={t("panel.average.totalCost", "TOTAL COST")}
            value={"€ 1224,56"}
          />
        </div>
        <div className={classes.divContentTCO}>
          <div className={classes.subContainer2}>
            <CustomTypography
              text={t("fleet.costPerVehicle", "Cost per vehicle")}
              variant={"h5"}
              color={colors.primary}
              isUppercase
            />
            <div className={classes.divSearch}>
              <CustomSearch
                options={[]}
                label={t("fleet.searchForAVehicle", "Search for a vehicle")}
                placeholder={"XX-000-XX AUDI A11"}
                onChange={() => {
                  setShowResultVehicle(true);
                }}
              />
              <CustomButton
                label={t("fleet.seeTheCost", "See the cost")}
                variant={"primary"}
                width={"fit-content"}
                height={"40px"}
              />
            </div>
            {showResultVehicle && (
              <ResultSearchVehicle
                onClose={() => setShowResultVehicle(false)}
              />
            )}
          </div>
          <div className={classes.subContainer2}>
            <CustomTypography
              text={t("fleet.costPerEmployee", "Cost per employee")}
              variant={"h5"}
              color={colors.primary}
              isUppercase
            />
            <div className={classes.divSearch}>
              <CustomSearch
                options={[]}
                label={t(
                  "fleet.searchForACollaborator",
                  "Search for a collaborator"
                )}
                placeholder={"Thierry Martin"}
                onChange={() => {
                  setShowResultCollab(true);
                }}
              />
              <CustomButton
                label={t("fleet.seeTheCost", "See the cost")}
                variant={"primary"}
                width={"fit-content"}
                height={"40px"}
              />
            </div>
            {showResultCollab && (
              <ResultSearchCollab onClose={() => setShowResultCollab(false)} />
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};
