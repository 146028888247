import { useQuery } from "@tanstack/react-query";
import axios from "./axios";

export const companiesKeys = {
  companie: () => ["companie"] as const,
  companieRental: () => ["companieRental"] as const,
  companieByID: (id: number) =>
    [...companiesKeys.companie(), "id", id] as const,
};

export const useGetOneCompanie = (id: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: companiesKeys.companieByID(id),
    queryFn: async (): Promise<TCompanie> => {
      const data = await axios.get(`/companies/${id}`);
      return data.data;
    },
  });
  if (error) {
  }
  return { companie: data, ...res };
};

export const useGetAllCompaniesRental = () => {
  const { data, error, ...res } = useQuery({
    queryKey: companiesKeys.companieRental(),
    queryFn: async (): Promise<TCompanie[]> => {
      const data = await axios.get(`/fleets/rental`);
      return data?.data;
    },
  });
  if (error) {
  }
  return { rentalCompanies: data ?? [], ...res };
};
