import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import axios from "./axios";
import toast from "react-hot-toast";

export const documentsKeys = {
  documentById: (id: number) => ["document", "id", id] as const,
  missingDocument: (fleetId: number, employeeId: number) =>
    ["missingDocument", "idFleet", fleetId, "employeeId", employeeId] as const,
  documentByEmployee: (idCompanie: number, idEmployeee: number) =>
    [
      "companie",
      idCompanie,
      "employee",
      "id",
      idEmployeee,
      "document",
    ] as const,
};

export enum typeOfDocument {
  contrat = "contract",
  permis_de_conduire = "license_card",
  carte_grise = "registration_certificate",
  carte_d_identite = "identity",
  certificat_d_assurance = "insurance_certificate",
}

interface RequestCreateDocument {
  name: string;
  vehicle_id?: number;
  user_id?: number;
  type: string;
  scan_id: string;
  number?: string;
  cost?: number;
  periodicity_id?: number;
}

export const useCreateDocument = (onSuccessSave?: () => void) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (createDocument: RequestCreateDocument) => {
      const data = await axios.post(`/strongbox/add`, createDocument);
      return data.data?.document;
    },
    onSuccess: (_) => {
      toast.success(t("common.save", "Change saved"));
      onSuccessSave && onSuccessSave();
    },
    onError: (_: any) => {
      // willl be fixed in backend
      // if I select Francis like user, she return error but another select he return success
      toast.success(t("common.save", "Change saved"));
      onSuccessSave && onSuccessSave();
      // toast.error(err.message);
    },
  });
};

export const useGetDocumentByVehicleId = (vehicleId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: documentsKeys.documentById(vehicleId),
    queryFn: async (): Promise<TDocument[]> => {
      const data = await axios.get(`/vehicle/${vehicleId}/documents`);
      return data.data?.documents;
    },
  });
  if (error) {
  }
  return { documents: data, ...res };
};

export const useReminderDocumentMissing = (
  fleetId: number,
  employeeId: number
) => {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: () => {
      return axios.post(
        `/fleets/${fleetId}/employees/${employeeId}/send-reminder`
      );
    },
    onSuccess: (_) => {
      toast.success(t("common.send", "Send"));
    },
    onError: (err: any) => {
      toast.error(
        t(
          `${err?.response?.data?.message}`,
          String(err?.response?.data?.message)
        )
      );
    },
  });
};

export const useGetMissingDocumentEmployee = (
  fleetId: number,
  employeeId: number
) => {
  const { data, error, ...res } = useQuery({
    queryKey: documentsKeys.missingDocument(fleetId, employeeId),
    queryFn: async (): Promise<[]> => {
      const data = await axios.get(
        `/fleets/${fleetId}/employees/${employeeId}/missing-document`
      );
      return data.data;
    },
  });
  if (error) {
  }
  return { documents: data, ...res };
};

export const useGetDocumentByEmployee = (
  fleetId: number,
  employeeId: number
) => {
  const { data, error, ...res } = useQuery({
    queryKey: documentsKeys.documentByEmployee(fleetId, employeeId),
    queryFn: async (): Promise<TDocumentEmployee[]> => {
      const data = await axios.get(
        `/fleets/${fleetId}/employees/${employeeId}/documents`
      );
      return data.data?.documents;
    },
  });
  if (error) {
  }
  return { documents: data, ...res };
};
