import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  menuEnchor: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  divMenuEnchor: {
    display: "flex",
    flexDirection: "row",
    gap: "8px",
    borderRadius: "3px",
    padding: "2px 5px 2px 5px",
  },
});

export default useStyles;
