import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import useStyles from "./styles";
import { Icon } from "../../../../components/Icon/Icon";
import { useTranslation } from "react-i18next";
import { FC } from "react";

type props = {
  image?: string;
  index: number;
};

export const SectionPictures: FC<props> = ({ image, index }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const onClickEye = () => {
    window.open(image, "_blank");
  };
  return (
    <div className={classes.divPictures}>
      <div className={classes.divImage}>
        <img src={image} alt="Photos" className={classes.img} />
        <div className={classes.divText}>
          <CustomTypography text={`PIC01010${index}`} variant="h4" />
          <CustomTypography
            text={t(
              "common.otherInformationIfAvailable",
              "Other information if available"
            )}
            variant="link"
          />
        </div>
      </div>
      <div className={classes.iconEye} onClick={onClickEye}>
        <Icon name="eye" width={"24px"} height={"24px"} />
      </div>
    </div>
  );
};
