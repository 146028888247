import { Icon } from "../Icon/Icon";
import useStyles from "./styles";

type Props = {
  iconName: string;
  width?: string;
  height?: string;
  iconColor?: string;
  backgroundColor?: string;
  onClick?: () => void;
};

export const IconWithBackground: React.FC<Props> = ({
  iconName,
  width = "18px",
  height = "18px",
  iconColor,
  backgroundColor,
  onClick,
}) => {
  const classes = useStyles({ backgroundColor });

  return (
    <div className={classes.containerIcon} onClick={onClick}>
      <Icon name={iconName} width={width} height={height} color={iconColor} />
    </div>
  );
};
