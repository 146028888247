import { createUseStyles } from "react-jss";
import { colors } from "../../../../../styles/colors";
const useStyles = createUseStyles({
  divContentCard: {
    width: "359px",
    borderRadius: "8px",
    padding: "25px 20px 25px 20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    height: "fit-content",
    backgroundColor: colors.WHITE,
    "& > div": { marginBottom: 0 },
  },
  divContainerAllCards: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "13px",
    maxHeight: "150px",
    overflowY: "scroll",
  },
  divAdd: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subContainerCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subContainer2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
  iconButton: { margin: 0, padding: 0 },

  divAway: {
    position: "absolute",
    top: 33,
    right: 0,
    left: -150,
    zIndex: 1,
    width: "359px",
    borderRadius: "8px",
    padding: "25px 20px 25px 20px",
    backgroundColor: colors.WHITE,
    boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.10)",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
});

export default useStyles;
