import { useTranslation } from "react-i18next"
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography"
import { CustomIconButton } from "../../../components/CustomIconButton/CustomIconButton"
import { CustomTypographyLabelValue } from "../../../components/CustomTypographyLabelValue/CustomTypographyLabelValue"
import useStyles from "./styles"

export const InformationGeneral = ()=> {
    const {t} = useTranslation()
    const classes = useStyles()
  return (
     <div className={classes.divInformationGeneral}>
       
          <div className={classes.textInformationGeneral}>
            <CustomTypography
              text={t("setting.generalInformation", "General information")}
              variant={"h3"}
              isUppercase
            />
            <CustomIconButton
              iconName={"pencil"}
              widthIcon={"30px"}
              heightIcon={"30px"}
            />
          </div>
       
          <div className={classes.divInfos}>
            <div className={classes.divInfos2}>
              <CustomTypographyLabelValue
                label={t("collaborator.name", "Name")}
                value={"NOM_UTILISATEUR"}
              />
              <CustomTypographyLabelValue
                label={t("collaborator.firstName", "Firstname")}
                value={"PRENOM_UTILISATEUR"}
              />
            </div>
            <div className={classes.divInfos2}>
              <CustomTypographyLabelValue label={"Id"} value={"123456"} />
              <CustomTypographyLabelValue
                label={t("collaborator.email", "Email")}
                value={"utilisateur@mail.com"}
                isUppercaseValue={false}
              />
            </div>
          </div>
        </div>
  )
}
