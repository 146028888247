import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../../components/CustomModal/CustomModal";
import { CustomSearch } from "../../../../components/CustomSearch/CustomSearch";
import { CustomTextDatePicker } from "../../../../components/CustomTextDatePicker/CustomTextDatePicker";
import toast from "react-hot-toast";
import { ChangeEvent, FC, useState } from "react";
import useStyles from "./styles";
import {
  useAssignVehicleToEmployee,
  useGetAllVehiculesFleet,
} from "../../../../api/vehicule";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import dayjs from "dayjs";
import { useParams } from "react-router";

type props = {
  openEdit: boolean;
  onClose: () => void;
  onCancel: () => void;
};

export const CustomModalAffectVehicle: FC<props> = ({
  openEdit,
  onClose,
  onCancel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { id = "" } = useParams();
  const userId = parseInt(id);

   const { vehicules } = useGetAllVehiculesFleet(
    getCurrentFleetId(),
    1,
    5000,
    "",
    "F"
  );

  const [selectedVehicleId, setSelectedVehicleId] = useState<number | null>(
    null
  );
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const { mutate: assign, isPending } = useAssignVehicleToEmployee(
    getCurrentFleetId(),
    userId,
    selectedVehicleId || 0,
    onClose
  );

  const onChangeTextFieldVehicle = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.trim().length === 0) {
      setSelectedVehicleId(null);
    }
  };

  const onChangeAutoCompletVehicle = (_: any, vehicle: any) => {
    setSelectedVehicleId(vehicle.vehicleId);
  };

  const onClickSave = () => {
    if (
      selectedVehicleId === null ||
      selectedStartDate === null ||
      selectedEndDate === null
    ) {
      toast.error(t("required.checkFields", "All fields are required"));
    } else {
      assign({
        start_date: dayjs(selectedStartDate).format("YYYY-MM-DD"),
        end_date: dayjs(selectedEndDate).format("YYYY-MM-DD"),
      });
    }
  };
  return (
    <CustomModal
      open={openEdit}
      onCancel={onCancel}
      title={t("collaborator.affectedNewVehicle", "Affected new vehicle")}
      onClose={onClose}
      onSave={onClickSave}
      loadingSave={isPending}
    >
      <div className={classes.containerText}>
        <CustomSearch
          options={vehicules}
          getOptionLabel={(option: any) =>
             `${option?.brand} ${option?.brandModel} - ${option?.immatriculation}`
          }
          label={`${t("collaborator.findAvehicle", "Find vehicle")} *`}
          placeholder={t("common.find", "Find")}
          marginBottom={"0px"}
          onChange={onChangeAutoCompletVehicle}
          onChangeTextField={onChangeTextFieldVehicle}
        />
        <CustomTextDatePicker
          label={`${t(
            "collaborator.assignmentStartDate",
            "Assignment start date"
          )} *`}
          value={selectedStartDate}
          onChange={(value) => setSelectedStartDate(value)}
          marginBottom={"0px"}
        />
        <CustomTextDatePicker
          label={`${t(
            "collaborator.assignmentEndDate",
            "Assignment end date"
          )} *`}
          value={selectedEndDate}
          onChange={(value) => setSelectedEndDate(value)}
          marginBottom={"0px"}
        />
      </div>
    </CustomModal>
  );
};
