import { useQuery } from "@tanstack/react-query";
import axios from "./axios";

export const TCOKeys = {
  month: (fleetId: number) => ["tco-month", "idFleet", fleetId] as const,
  vehicles: (fleetId: number) => ["tco-vehicles", "idFleet", fleetId] as const,
  tcoByVehicleMonthly: (vehicleId: number) =>
    ["tco-month-vehicle", "idVehicle", vehicleId] as const,
};

export const useGetVehiclesByStatus = (fleetId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: TCOKeys.vehicles(fleetId),
    queryFn: async (): Promise<TVehicleByStatus[]> => {
      const data = await axios.get(
        `/fleets/${fleetId}/fleet-vehicle-by-status`
      );
      return data?.data;
    },
  });

  return { vehiclesByStatus: data, ...res };
};

export const useGetTCOByCompany = (fleetId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: TCOKeys.month(fleetId),
    queryFn: async (): Promise<TCO> => {
      const data = await axios.get(`/companies/${fleetId}/tco-by-company`);
      return data?.data;
    },
  });

  return { tco: data, ...res };
};

export const useGetTotalTCOByVehicle = (fleetId: number, vehicleId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: TCOKeys.tcoByVehicleMonthly(vehicleId),
    queryFn: async (): Promise<TCO> => {
      const data = await axios.get(
        `/companies/${fleetId}/tco-by-vehicles/${vehicleId}`
      );
      return data?.data;
    },
  });

  return { totalTcoByVehicle: data, ...res };
};
