import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  vehiculeRoot: {
    overflowY: "auto",
    padding: "20px 30px",
    height: "calc(100vh - 40px)",
    backgroundColor: colors.bgcolor,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "94px",
    justifyContent: "space-between",
    // maxWidth: 1275,
    width: "100%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    height: "calc(100vh - 140px)",
    overflow: "auto",
  },
  iconContainerHeader: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  leftContent: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
  },
  containerBodyLeft: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  },
  divClaims: {
    width: "100%",
  },
  rightContent: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },

  textModalContainer: {
    padding: "0px 0px 29px 0px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },

  divDocument: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
}));

export default useStyles;
