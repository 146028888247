import { useTranslation } from "react-i18next";
import { CustomButtonTable } from "../../../../components/CustomButtonTable/CustomButtonTable";
import { CustomIconButton } from "../../../../components/CustomIconButton/CustomIconButton";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { CustomTypographyLabelValue } from "../../../../components/CustomTypographyLabelValue/CustomTypographyLabelValue";
import useStyles from "./styles";
import { useParams } from "react-router";
import {
  useGetSinisterById,
  useGetSinisterEmployee,
} from "../../../../api/sinistre";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";

export const SectionCollaboratorClaims = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id = "" } = useParams();
  // const navigate = useNavigate();
  const sinisterId = parseInt(id);
  const { sinisterDetail } = useGetSinisterById(
    getCurrentFleetId(),
    sinisterId
  );

  const { sinisterEmployee } = useGetSinisterEmployee(
    getCurrentFleetId(),
    sinisterDetail?.vehicle_id ?? -1,
    sinisterId
  );

  return (
    <div className={classes.divSinisterInfos}>
      <div className={classes.divCollaborator}>
        <CustomTypography
          text={t("panel.collaborator.collaborator", "Collaborator")}
          variant="h3"
          isUppercase
        />
        <CustomIconButton
          iconName={"pencil"}
          widthIcon={"30px"}
          heightIcon={"30px"}
        />
      </div>
      <div className={classes.divInfosSinister}>
        <div className={classes.divInfosSinisterColums}>
          <CustomTypographyLabelValue
            label={t("collaborator.name", "Name")}
            value={sinisterEmployee?.lastname ?? ""}
          />
          <CustomTypographyLabelValue
            label={t("collaborator.firstName", "Firstname")}
            value={sinisterEmployee?.firstname ?? ""}
          />
          <CustomTypographyLabelValue
            label={t(
              "collaborator.vehicleAllocationTime",
              "Vehicle allocation time"
            )}
            value={`${sinisterEmployee?.jours ?? ""} ${t(
              "common.days",
              "Days"
            )}`}
          />
        </div>
        <div className={classes.divInfosSinisterColums}>
          <CustomTypographyLabelValue
            label={t("collaborator.service", "Service")}
            value={sinisterEmployee?.department ?? ""}
          />
          <CustomTypographyLabelValue
            label={t("collaborator.email", "Email")}
            value={sinisterEmployee?.email ?? ""}
          />
        </div>
        <div className={classes.divInfosSinisterColums}>
          {sinisterEmployee?.site && (
            <CustomTypographyLabelValue
              label={"Site"}
              value={sinisterEmployee.site}
            />
          )}
          {sinisterEmployee?.permis && (
            <CustomTypographyLabelValue
              label={t("collaborator.licenseNumber", "License number")}
              value={sinisterEmployee.permis}
            />
          )}
        </div>
      </div>
      <CustomButtonTable
        variant={"primary"}
        textVariant={"link"}
        label={t("claims.seeTheCollaboratorFile", "See the collaborator file")}
        width={"fit-content"}
        fontWeight={"bold"}
        textDecoration={"none"}
        textTransform="uppercase"
        iconName={"eye"}
        // onClick={() =>  sinisterEmployee. && navigate(`/vehicule/update/${sinisterDetail.vehicle_id}`)}
      />
    </div>
  );
};
