import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { Sidebar } from "../../../containers/Sidebar/Sidebar";
import useStyles from "./styles";
import { CustomTable } from "../../../components/CustomTable/CustomTable";
import { CustomButtonTable } from "../../../components/CustomButtonTable/CustomButtonTable";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { getCurrentFleetId } from "../../../utils/getCurrentFleet";
import { useGetAllVehiculesFleet } from "../../../api/vehicule";
import { CustomLoader } from "../../../components/CustomLoader/CustomLoader";
import { columnsListVehicle } from "./columnsListVehicle";
import { useState } from "react";

type Props = {};

export const ListVehicule: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [resetPagination, setResetPagination] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { vehicules, totalCount, isPending } = useGetAllVehiculesFleet(
    getCurrentFleetId(),
    currentPage,
    10,
    searchTerm
  );
  const handleChangePageProps = (newPage: number) => {
    setCurrentPage(newPage + 1);
  };

  const onResetTable = () => {
    setCurrentPage(1);
    setResetPagination(!resetPagination);
  };

  return (
    <>
      {isPending && <CustomLoader isActive={isPending} />}
      <Sidebar
        styles={{
          sidebarRightRoot: classes.vehiculeRoot,
          sidebarRoot: classes.contentMain,
        }}
      >
        <div className={classes.containerHeader}>
          <CustomTypography
            variant="h2"
            text={t("vehicule.vehicleList", "Vehicle list")}
            isUppercase
          />
          <CustomButtonTable
            variant={"primary"}
            textVariant={"link"}
            label={t("vehicule.addVehicule", "Add vehicle")}
            width={"fit-content"}
            fontWeight={"bold"}
            textDecoration={"none"}
            iconName={"plus"}
            padding={"0px 15px"}
            textTransform="uppercase"
            onClick={() => navigate("/vehicule/create")}
          />
        </div>
        <CustomTable
          columns={columnsListVehicle}
          data={vehicules}
          resetPaginationTrigger={resetPagination}
          isSearchable
          isFilterFromBackend
          tableMaxHeight={"520px"}
          onClickButtonEye={() => navigate("/vehicule/update")}
          tablePagnationCount={totalCount}
          handleChangePageProps={handleChangePageProps}
          onChangeSearchTerm={(e) => {
            onResetTable();
            setTimeout(() => {
              setSearchTerm(e.target.value);
            }, 1000);
          }}
        />
      </Sidebar>
    </>
  );
};
