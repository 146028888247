export const getPeriodicity = (period: string): number => {
  switch (period) {
    case "Journalier":
      return 1;
    case "Mensuel":
      return 2;
    case "Annuel":
      return 3;

    default:
      return 2;
  }
};

export const getPeriodicityByValue = (period: number): string => {
  switch (period) {
    case 1:
      return "Journalier";
    case 2:
      return "Mensuel";
    case 3:
      return "Annuel";

    default:
      return "";
  }
};

export const getPeriodicityLabelByValue = (
  period: string
): "Journalier" | "Mensuel" | "Annuel" | null => {
  switch (period) {
    case "daily":
      return "Journalier";
    case "monthly":
      return "Mensuel";
    case "annualy":
      return "Annuel";

    default:
      return null;
  }
};
