import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles({
  info: {
    display: "flex",
    flexDirection: "column",
    fontSize: 12,
    width: "469px",
    backgroundColor: colors.WHITE,
    borderRadius: "15px",
    padding: "15px",
    gap: "15px",
  },
  subContainerInfo: {
    display: "flex",
    gap: "15px",
    flexDirection: "row",
  },
  code: {
    width: "182px",
    height: "182px",
    backgroundColor: "#EEEEEE",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  description: {
    flex: 1,
    marginLeft: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  paperTitle: {
    paddingBottom: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  mark: {
    fontSize: 12,
    lineHeight: "15px",
    display: "flex",
    "& > div": {
      flex: 1,
      "& > div": {
        marginBottom: 10,
      },
    },
  },
  subContainerContrat: {
    display: "flex",
    flexDirection: "row",
    minWidth: "439px",
    borderRadius: "10px",
    padding: 10,
    gap: "15px",
    backgroundColor: "#F8F8F8",
    "& > div": {
      flex: 1,
      padding: 10,
    },
  },
  contratTitle: {
    color: colors.primary,
  },
  containerContrat: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "13px",
  },
  containerText: {
    padding: "19px 15px 29px 15px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    "& > div": {
      marginBottom: 0,
    },
  },

  divModalText: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    "& > div": {
      flex: 1,
      marginBottom: 0,
    },
  },

  divRent: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 25px 0px 25px",
    "& .MuiTypography-root.MuiTypography-body1": {
      fontFamily: "Poppins-Regular",
      fontSize: "13px",
      color: colors.darkblack,
    },
  },
});

export default useStyles;
