import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "./axios";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export const notificationsKeys = {
  all: (fleetId: number) => ["notifications", "idFleet", fleetId] as const,
};

export const useGetAllNotification = (fleetId: number) => {
  const { data, error, ...res } = useQuery({
    queryKey: notificationsKeys.all(fleetId),
    queryFn: async (): Promise<TNotification[]> => {
      const dataTrue: TNotification[] = await axios.get(`/notification/list`, {
        params: { is_read: true },
      });
      const dataFalse: TNotification[] = await axios.get(`/notification/list`, {
        params: { is_read: false },
      });
      const data = [...dataTrue, ...dataFalse];
      return data;
    },
  });

  return { notifications: data ?? [], ...res };
};

export const useReadNotification = (fleetId: number) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (notification_id: number) => {
      const data = await axios.post(`/notification/read`, notification_id);
      return data.data?.notification;
    },
    onSuccess: (_) => {
      const keys = notificationsKeys.all(fleetId);
      queryClient.refetchQueries(keys as any);
      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useDeleteBulkNotification = (fleetId: number) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const data = await axios.delete(`/notification/delete`);
      return data.data;
    },
    onSuccess: (_) => {
      const keys = notificationsKeys.all(fleetId);
      queryClient.refetchQueries(keys as any);
      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
};

export const useDeleteOneNotification = (
  fleetId: number,
  notificationId: number
) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const data = await axios.delete(`/notification/delete/${notificationId}`);
      return data.data;
    },
    onSuccess: (_) => {
      const keys = notificationsKeys.all(fleetId);
      const prev = queryClient.getQueryData<TNotification[]>(keys);
      if (prev) {
        const res = prev.filter(
          (notification) => notification.id !== notificationId
        );
        queryClient.setQueryData<TNotification[]>(keys, res);
      }
      toast.success(t("common.changeSaved", "Change saved"));
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });
};
