import { FC } from "react";
import { Icon } from "../../../components/Icon/Icon";
import { IconButton } from "@mui/material";
import { colors } from "../../../styles/colors";
import { useNavigate } from "react-router";

type props = {
  sinister: TSinister;
};

export const ButtonsListSinister: FC<props> = ({ sinister }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/claims/update/${sinister.id}`);
  };

  return (
    <IconButton onClick={onClick}>
      <Icon name="eye" width={"24px"} height={"24px"} color={colors.BLACK} />
    </IconButton>
  );
};
