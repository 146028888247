import * as Yup from "yup";

export const validationSchemaCreateVehicleContrat = Yup.object().shape({
  contractNumber: Yup.string().nonNullable().required("Required field"),
  durationInMonths: Yup.string().nonNullable().required("Required field"),
  dateOfEntry: Yup.string().nonNullable().required("Required field"),
  releaseDate: Yup.string().nonNullable().required("Required field"),
  kmOnContract: Yup.string().nonNullable().required("Required field"),
  numberOfWinterTires: Yup.string().nullable().notRequired(),
  numberOfSummerTires: Yup.string().nullable().notRequired(),
  rentExcludingTax: Yup.string().nonNullable().required("Required field"),
  periodicity: Yup.string().nonNullable().required("Required field"),
  owner_company_id: Yup.string().nonNullable().notRequired(),
  // Ajoutez d'autres champs de validation si nécessaire
});

interface FormValuesVehicleContrat {
  contractNumber: string | null;
  durationInMonths: number | null;
  dateOfEntry: string | null;
  releaseDate: string | null;
  kmOnContract: number | null;
  numberOfWinterTires: number | null;
  numberOfSummerTires: number | null;
  rentExcludingTax: number | null;
  periodicity: string | null;
  owner_company_id: string | null;
}

export const initialValuesVehicleContrat = {
  contractNumber: null,
  durationInMonths: null,
  dateOfEntry: null,
  releaseDate: null,
  kmOnContract: null,
  numberOfWinterTires: null,
  numberOfSummerTires: null,
  rentExcludingTax: null,
  periodicity: null,
  owner_company_id: null,
} as FormValuesVehicleContrat;
