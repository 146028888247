import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    justifyContent: "center",
    alignItems: "center",
  },

  divPhoto: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    backgroundColor: colors.WHITE,
    borderRadius: "8px",
    padding: "25px 20px 25px 20px",
  },
  divSubPhoto: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: "675px",
    maxWidth: "675px",
  },

  divLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    maxWidth: "197px",
  },
  divFormat: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },

  divRight: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  divTrash: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  divAllPictures: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    flexWrap: "wrap",
  },

  containerAllButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "715px",
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
});

export default useStyles;
