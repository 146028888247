import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  divContentTCO: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  },

  divResultSearch: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "30px 0px 0px 0px",
  },
  divTextResult: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default useStyles;
