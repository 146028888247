import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "674px",
    minWidth: "674px",
  },
  content: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  contentSelect: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    justifyContent: "space-between",
    "& > div": {
      flex: 1,
      margin: 0,
    },
  },
  containerAllButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
});

export default useStyles;
