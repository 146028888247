import { useTranslation } from "react-i18next";
import { Icon } from "../../components/Icon/Icon";
import { CustomTypography } from "../../components/CustomTypography/CustomTypography";
import { CustomIconButton } from "../../components/CustomIconButton/CustomIconButton";
import useStyles from "./styles";
import { CustomTypographyLabelValue } from "../../components/CustomTypographyLabelValue/CustomTypographyLabelValue";
import { useState } from "react";
import { colors } from "../../styles/colors";
import { getCurrentFleetId } from "../../utils/getCurrentFleet";
import { useGetOneEmployee } from "../../api/employee";
import { ModalUpdateCollaborator } from "./ModalUpdateCollaborator/ModalUpdateCollaborator";

type Props = {
  isEditableIcon?: boolean;
  fullInfos?: boolean;
  userId: number;
};

export const ContainerCollaborator: React.FC<Props> = ({
  isEditableIcon,
  fullInfos,
  userId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { employee } = useGetOneEmployee(getCurrentFleetId(), userId);
  const [openModalCollabEdit, setOpenModalCollabEdit] = useState(false);

  const onCloseModal = () => {
    setOpenModalCollabEdit(false);
  };

  return (
    <>
      <ModalUpdateCollaborator
        employee={employee ?? null}
        open={openModalCollabEdit}
        onClose={onCloseModal}
        onCancel={onCloseModal}
      />
      <div className={classes.containerHeaderLeft}>
        <div className={classes.subContainerHeaderLeft}>
          {/* Images */}
          <div className={classes.divImages}>
            <Icon
              name={"photo"}
              height={"50px"}
              width={"50px"}
              color={colors.greydark}
            />
          </div>
          {/* Collaborateurs */}
          <div className={classes.divCollaborator}>
            <div className={classes.divCollaboratorText}>
              <CustomTypography
                text={t("collaborator.collaborator", "Collaborator")}
                variant={"h3"}
                isUppercase
              />
              {isEditableIcon && (
                <CustomIconButton
                  onClick={() => setOpenModalCollabEdit(true)}
                  iconName={"pencil"}
                  widthIcon={"30px"}
                  heightIcon={"30px"}
                />
              )}
            </div>
            <div className={classes.divContainerInfo}>
              <div className={classes.subContainerInfo}>
                <CustomTypographyLabelValue
                  label={t("collaborator.name", "Name")}
                  value={employee?.lastname ?? ""}
                />
                <CustomTypographyLabelValue
                  label={t("collaborator.firstName", "Firstname")}
                  value={employee?.firstname ?? ""}
                />
              </div>
              <div className={classes.subContainerInfo}>
                <CustomTypographyLabelValue
                  label={t("collaborator.service", "Service")}
                  value={employee?.department ?? ""}
                />
                <CustomTypographyLabelValue
                  label={t("collaborator.email", "Email")}
                  value={employee?.email ?? ""}
                />
              </div>
              {fullInfos && (
                <div className={classes.subContainerInfo}>
                  {/* <CustomTypography
                    text={t("collaborator.advantage", "Advantage")}
                    variant="link"
                    lineHeight="9px"
                  />
                  <CustomTypography
                    text={t("collaborator.denomination", "Denomination")}
                    variant="default"
                    fontWeight="bold"
                  />
                  <CustomTypography
                    text={`XXX € ${t("collaborator.monthly", "Monthly")}`}
                    variant="default"
                    fontWeight="bold"
                  /> */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
