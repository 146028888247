import { FC } from "react";
import { CustomTypography } from "../CustomTypography/CustomTypography";

import { colors } from "../../styles/colors";
import useStyles from "./styles";

type Props = {
  title?: string;
  images?: string[];
  minHeight?: string | number;
};

export const DocumentsPanelPictures: FC<Props> = ({
  title,
  images = [],
  minHeight,
}) => {
  const classes = useStyles({ minHeight });
  return (
    <div className={classes.containerSubDocuments}>
      <CustomTypography
        text={title ?? ""}
        variant={"h4"}
        isUppercase
        color={colors.primary}
      />
      <div className={classes.divContentPicture}>
        {images?.map((image, index) => (
          <div key={index} className={classes.divPictures}>
            <img src={image} width={"30px"} height={"30px"} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};
