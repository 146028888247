import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles({
  divDepartement: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    padding: "15px",
    gap: "15px",
    backgroundColor: colors.WHITE,
    height: "fit-content",
  },
  divButtonTVA: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    alignItems: "flex-end",
    "& > div": {
      margin: 0,
      flex: 1,
    },
  },
  divListService: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    padding: "15px",
  },
  serviceList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  divServiceList: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    flexWrap: "wrap",
  },
  divService: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "30px",
    borderRadius: "50px",
    padding: "3px 10px 3px 15px",
    gap: "5px",
    backgroundColor: colors.default,
  },
});

export default useStyles;
