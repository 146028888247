import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";
const useStyles = createUseStyles({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "15px 0px 15px 0px",
  },
  vehiculeRoot: {
    padding: "30px 30px",
    overflowY: "auto",
    height: "calc(100vh - 60px)",
    backgroundColor: colors.bgcolor,
  },

  divContainer1: {
    marginTop: "15px",
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    "& > div": {
      flex: 1,
    },
  },
});

export default useStyles;
