import { useTranslation } from "react-i18next";
import { CustomIconButton } from "../../../components/CustomIconButton/CustomIconButton";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { Icon } from "../../../components/Icon/Icon";
import { colors } from "../../../styles/colors";
import useStyles from "./styles";
import { CustomTextInput } from "../../../components/CustomTextInput/CustomTextInput";
import { CustomButton } from "../../../components/CustomButton/CustomButton";

export const ParametreService = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.divDepartement}>
      {/* title */}
      <CustomTypography
        text={t("setting.departement", "Departement")}
        variant={"h3"}
        isUppercase
      />
      {/* service */}
      <div className={classes.divButtonTVA}>
        <CustomTextInput
          label={t("setting.service", "Service")}
          placeholder={t("setting.placeholderService")}
        />
        <CustomButton
          label={t("common.add", "Add")}
          variant={"primary"}
          width={"fit-content"}
          height={"40px"}
        />
      </div>
      {/* liste des services */}
      <div className={classes.divListService}>
        <div className={classes.serviceList}>
          <CustomTypography
            text={t("setting.list", "List of your services")}
            variant={"default"}
            fontWeight="bold"
          />
          <CustomIconButton
            backgroundColor={colors.default}
            color={colors.darkblack}
            widthIcon={"25px"}
            heightIcon={"25px"}
            iconName="close"
          />
        </div>
        <div className={classes.divServiceList}>
          {Array.from({ length: 10 }).map((_, ind) => (
            <div className={classes.divService} key={ind}>
              <CustomTypography text={`SERVICE_${ind}`} variant={"link"} />
              <Icon name={"close"} width={"1(px"} height={"15px"} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
