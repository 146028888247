import { useTranslation } from "react-i18next";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import useStyles from "./styles";
import { CustomTextSelect } from "../../components/CustomTextSelect/CustomTextSelect";
import { CustomTextDatePicker } from "../../components/CustomTextDatePicker/CustomTextDatePicker";
import { CustomTextInput } from "../../components/CustomTextInput/CustomTextInput";
import { FC, ReactNode, useEffect } from "react";
import { useFormik } from "formik";
import { validationSchemaCreateCarteAndBadge } from "./validationSchemaCarteAndbadge";
import dayjs from "dayjs";
import { SelectChangeEvent } from "@mui/material";
import {
  typeOfCardOrBadge,
  useCreateBadgeOrCards,
  useUpdateBadgeOrCard,
} from "../../api/badge";
import { getCurrentFleetId } from "../../utils/getCurrentFleet";
import {
  getPeriodicity,
  getPeriodicityLabelByValue,
} from "../../utils/periodicity";
import toast from "react-hot-toast";

type props = {
  open: boolean;
  title: string;
  currentCartOrBadge: TCard | TBadge | null;
  supportTypes: typeOfCardOrBadge;
  onCancel?: () => void;
  onClose?: () => void;
  onSave?: () => void;
};

export const ModalCarteAndBadge: FC<props> = ({
  open,
  title,
  supportTypes,
  currentCartOrBadge,
  onCancel,
  onClose,
  onSave,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (currentCartOrBadge !== null) {
      formik.setFieldValue("choiceOfType", currentCartOrBadge.cardType);
      formik.setFieldValue("choiceOfBrand", currentCartOrBadge.cardBrand);
      formik.setFieldValue(
        "dateOfCirculation",
        dayjs(currentCartOrBadge.release_date)
      );
      formik.setFieldValue(
        "billing",
        getPeriodicityLabelByValue(String(currentCartOrBadge.name))
      );
      formik.setFieldValue("number", currentCartOrBadge.number);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCartOrBadge]);

  const initValues = () => {
    formik.setFieldValue("choiceOfType", null);
    formik.setFieldValue("choiceOfBrand", null);
    formik.setFieldValue("dateOfCirculation", null);
    formik.setFieldValue("billing", null);
    formik.setFieldValue("number", null);
    onSave && onSave();
    onClose && onClose();
  };

  const { mutate: createCardOrbadge, isPending } = useCreateBadgeOrCards(
    getCurrentFleetId(),
    initValues
  );

  const { mutate: updateCardOrBadge, isPending: isPendingUpdate } =
    useUpdateBadgeOrCard(getCurrentFleetId(), initValues);

  const formik = useFormik({
    initialValues: {
      choiceOfType: currentCartOrBadge?.cardType ?? null,
      choiceOfBrand: currentCartOrBadge?.cardBrand ?? null,
      dateOfCirculation: currentCartOrBadge?.release_date ?? null,
      billing: getPeriodicityLabelByValue(String(currentCartOrBadge?.name)),
      number: currentCartOrBadge?.number ?? null,
    },
    validationSchema: validationSchemaCreateCarteAndBadge,
    onSubmit: (values) => {
      if (currentCartOrBadge !== null) {
        updateCardOrBadge({
          badgeId: currentCartOrBadge.id,
          type: values.choiceOfType ?? "",
          marque: values.choiceOfBrand ?? "",
          billing_periodicity: getPeriodicity(values.billing ?? "Mensuel"),
          number: Number(values.number) ?? 0,
          release_date:
            dayjs(values.dateOfCirculation).format("YYYY-MM-DD") ?? "",
          support_type: supportTypes,
        });
      } else {
        createCardOrbadge({
          type: values.choiceOfType ?? "",
          marque: values.choiceOfBrand ?? "",
          billing_periodicity: getPeriodicity(values.billing ?? "Mensuel"),
          number: String(values.number) ?? 0,
          release_date: values.dateOfCirculation ?? "",
          support_type: supportTypes,
        });
      }
    },
  });

  const handleSelectCarburant = (
    e: SelectChangeEvent<unknown>,
    _: ReactNode
  ) => {
    formik.setFieldValue("choiceOfType", e.target.value as string);
    formik.setFieldValue("choiceOfBrand", null);
  };

  const handleSave = () => {
    const numberValue = String(formik.values.number).trim();

    if (
      formik.values.choiceOfType === null ||
      formik.values.choiceOfBrand === null ||
      formik.values.dateOfCirculation === null ||
      formik.values.billing === null ||
      numberValue?.length === 0 ||
      formik.values.number == null
    ) {
      toast.error(t("required.checkFields", "All fields are required"));
      return;
    } else if (/\D/.test(numberValue)) {
      // Check if numberValue contains non-digit characters
      toast.error(t("required.checkNumber", "Number must contain only digits"));
      return;
    } else {
      formik.handleSubmit();
    }
  };

  return (
    <CustomModal
      width={"417px"}
      open={open}
      onCancel={onCancel}
      title={title}
      onClose={onClose}
      onSave={handleSave}
      loadingSave={isPending || isPendingUpdate}
      labelConfirmation={t("common.add", "Add")}
    >
      <div className={classes.containerText}>
        <CustomTextSelect
          label={`${t("collaborator.choiceOfType", "Choice of type")} *`}
          placeholder={`${t("collaborator.choiceOfType", "Choice of type")} *`}
          options={
            supportTypes === typeOfCardOrBadge.card
              ? ["Carburant", "Recharge électrique", "Lavage"]
              : ["Péage"]
          }
          name="choiceOfType"
          value={formik.values.choiceOfType}
          onChange={handleSelectCarburant}
        />
        <CustomTextSelect
          key={formik.values.choiceOfType}
          label={`${t("collaborator.choiceOfBrand", "Choice of brand")} *`}
          placeholder={`${t(
            "collaborator.choiceOfBrand",
            "Choice of brand"
          )} *`}
          options={
            formik?.values?.choiceOfType === "Carburant"
              ? [
                  "Esso",
                  "Total",
                  "Shell",
                  "DKV",
                  "Mooncard",
                  "Easyfuel",
                  "E. Leclerc",
                  "Intermarché",
                  "Auchan",
                ]
              : [
                  "Chargemap",
                  "Izivia",
                  "Fulli",
                  "Freshmile",
                  "New Motion",
                  "Plugsurfing",
                  "Beev",
                ]
          }
          name="choiceOfBrand"
          value={formik.values.choiceOfBrand}
          onChange={formik.handleChange}
        />
        <CustomTextDatePicker
          label={`${t("panel.vehicule.dateOfEmission", "Date of emission")} *`}
          value={formik.values.dateOfCirculation as any}
          onChange={(value) =>
            formik?.setFieldValue(
              "dateOfCirculation",
              dayjs(value).format("YYYY-MM-DD")
            )
          }
        />
        <CustomTextSelect
          label={`${t("common.billing", "Billing")} *`}
          placeholder={`${t("common.billing", "Billing")} *`}
          options={["Journalier", "Mensuel", "Annuel"]}
          name="billing"
          value={formik.values.billing}
          onChange={formik.handleChange}
        />
        <CustomTextInput
          label={`${t("common.number", "Number")} *`}
          placeholder={`${t("common.number", "Number")} *`}
          name="number"
          // type={"number"}
          value={formik.values.number}
          onChange={formik.handleChange}
        />
      </div>
    </CustomModal>
  );
};
