import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles({
  container: {
    minWidth: "333px",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: colors.WHITE,
    width: "100%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subContainerIcon: {
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
    justifyContent: "space-between",
  },
  contratTitle: {
    color: colors.primary,
  },
  subContainer2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "10px",
  },
});

export default useStyles;
