import { createUseStyles } from "react-jss";
import { colors } from "../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  contentMain: {
    overflow: "hidden",
    height: "100vh",
  },
  vehiculeRoot: {
    overflowY: "auto",
    padding: "20px 30px",
    height: "calc(100vh - 40px)",
    backgroundColor: colors.bgcolor,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
  containerHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "94px",
    justifyContent: "space-between",
    // maxWidth: 1275,
    width: "100%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    height: "calc(100vh - 140px)",
    overflow: "auto",
  },
  textModalContainer: {
    padding: "0px 0px 29px 0px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  divLeft: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "100%",
  },
  divLeftBottom: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  },

  divCollaborator: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divInfosSinister: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
    "& > div": {
      flex: 1,
    },
  },
  divInfosSinisterColums: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  divRightSection: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "15px",
    padding: "15px",
    gap: "15px",
    backgroundColor: colors.WHITE,
    maxHeight: "454px",
    minHeight: "454px",
  },
  divSinister: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divPictureList: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    overflow: "auto",
  },
}));

export default useStyles;
