import { useTranslation } from "react-i18next";
import { CustomButtonTable } from "../../../components/CustomButtonTable/CustomButtonTable";
import { CustomCardInfo } from "../../../components/CustomCardInfo/CustomCardInfo";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { colors } from "../../../styles/colors";
import useStyles from "./style";
import { useNavigate } from "react-router";
import { getCurrentFleetId } from "../../../utils/getCurrentFleet";
import { useGetVehiclesByStatus } from "../../../api/tco";
import { VehicleStatus } from "../../../utils/types";
import { useGetAllVehiculesFleet } from "../../../api/vehicule";

export const Vehicule = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { vehiclesByStatus } = useGetVehiclesByStatus(getCurrentFleetId());
  const { totalCount } = useGetAllVehiculesFleet(getCurrentFleetId());

  const navigate = useNavigate();
  return (
    <div className={classes.divVehicule}>
      <div className={classes.divVehicleHeader}>
        <CustomTypography
          text={t("sidebar.car", "Car")}
          variant="h5"
          isUppercase
          color={colors.primary}
        />
        <div className={classes.divBlue}>
          <CustomTypography
            text={totalCount?.toString() ?? "00"}
            variant="h5"
            isUppercase
            color={colors.WHITE}
          />
        </div>
      </div>
      <div className={classes.divCardInfo}>
        <CustomCardInfo
          label={t("common.command", "Command")}
          value={
            vehiclesByStatus
              ?.find((v) => v.vehicle_status === VehicleStatus["En commande"])
              ?.count.toString() ?? "0"
          }
          backgroundColor={"#E7EFFF"}
        />
        <CustomCardInfo
          label={t("common.affected", "Affected")}
          value={
            vehiclesByStatus
              ?.find((v) => v.vehicle_status === VehicleStatus["Affecté"])
              ?.count.toString() ?? "0"
          }
          backgroundColor={"#C8FFD4"}
        />
      </div>
      <div className={classes.divCardInfo}>
        <CustomCardInfo
          label={t("common.free", "Free")}
          value={
            vehiclesByStatus
              ?.find((v) => v.vehicle_status === VehicleStatus["Libre"])
              ?.count.toString() ?? "0"
          }
          backgroundColor={"#F9ECFF"}
        />
        <CustomCardInfo
          label={t("common.waitingForValidation", "Waiting for validation")}
          value={
            vehiclesByStatus
              ?.find(
                (v) =>
                  v.vehicle_status === VehicleStatus["En attente de validation"]
              )
              ?.count.toString() ?? "0"
          }
          backgroundColor={"#FFD5B7"}
        />
      </div>
      <CustomButtonTable
        variant={"primary"}
        textVariant={"link"}
        label={t("notification.SeeAllVehicles", "See all vehicles")}
        textTransform={"uppercase"}
        width={"fit-content"}
        fontWeight={"bold"}
        textDecoration={"none"}
        iconName={"eye"}
        onClick={() => navigate("/vehicule/list")}
      />
    </div>
  );
};
