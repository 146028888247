import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  container: {
    width: "63%",
    alignSelf: "center",
  },
  containerDocuments: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "15px",
  },
  containerContrat: {
    display: "flex",
    flexDirection: "row",
    gap: "15px",
  },
  containerRequire: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    padding: "0px 15px 0px 15px",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  containerAllButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "5px",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  containerGreyCardInput: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    "& > div": {
      flex: 1,
    },
  },
});

export default useStyles;
