import { useTranslation } from "react-i18next";

import useStyles from "./styles";
import { FC } from "react";
import { CustomDropZoneUpload } from "../../../../../components/CustomDropZoneUpload/CustomDropZoneUpload";
import { CustomTypography } from "../../../../../components/CustomTypography/CustomTypography";
import { Accept, DropEvent, FileRejection } from "react-dropzone";

type Props = {
  title: string;
  maxSize?: string;
  acceptFormat?: string;
  children?: React.ReactNode;
  uploadedFile: File | null;
  accept?: Accept | undefined;
  onDrop?: <T extends File>(
    acceptedFiles: T[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
};

export const HeaderDocuments: FC<Props> = ({
  title,
  maxSize = "10 Mo",
  acceptFormat = "PNG - JPG - JPEG",
  accept,
  children,
  uploadedFile,
  onDrop,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.divContrat}>
      <div className={classes.headerContrat}>
        <div className={classes.contratDiv}>
          <CustomTypography text={title} variant="h4" isUppercase />
          <div className={classes.divFormatAccepted}>
            <CustomTypography
              text={t("contrat.acceptedFormats", "Accepted formats")}
              variant="link"
              fontWeight={"bold"}
              lineHeight={"4px"}
            />
            <CustomTypography text={acceptFormat} variant="link" />
          </div>
          <div className={classes.divFormatAccepted}>
            <CustomTypography
              text={t("contrat.maxSize", "Max size")}
              lineHeight={"4px"}
              variant="link"
              fontWeight={"bold"}
            />
            <CustomTypography text={maxSize} variant="link" />
          </div>
        </div>
        <CustomDropZoneUpload
          uploadedFile={uploadedFile}
          onDrop={onDrop}
          accept={accept}
        />
      </div>
      {children}
    </div>
  );
};
