import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  container: {
    // height: "calc(100vh - 462px)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  containerSearch: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
    gap: "15px",
    width: "33%",
    "& > div": {
      width: "100%",
      margin: 0,
    },
  },
});

export default useStyles;
