import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles((_) => ({
  divPictures: {
    width: "343px",
    height: "83px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px 30px 10px 10px",
    backgroundColor: colors.default,
  },
  divImage: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    alignItems: "center",
  },
  img: {
    width: "63px",
    height: "63px",
  },
  divText: {
    display: "flex",
    flexDirection: "column",
  },
  iconEye: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
}));

export default useStyles;
