import { createUseStyles } from "react-jss";
const useStyles = createUseStyles({
  subContainerContrat: {
    display: "flex",
    flexDirection: "column",
    minWidth: "439px",
    borderRadius: "10px",
    padding: 10,
    gap: "15px",
    backgroundColor: "#F8F8F8",
    "& > div": {
      flex: 1,
      padding: "0px 10px 10px 10px",
    },
  },
  flexParent: {
    display: "flex",
    flexDirection: "row",
    "& > div": {
      flex: 1,
    },
  },
  containerContrat: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    gap: "13px",
  },
  buttonEdit: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px 10px 0px 0px",
    padding: "0px !important",
  },
});

export default useStyles;
