import { Route, Routes as RoutesDOM } from "react-router-dom";
import { Login } from "../pages/Login/Login";
import { GeneratePassword } from "../pages/GeneratePassword/GeneratePassword";

import PrivateRoute from "./PrivateRoute";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { UpdateVehicule } from "../pages/Vehicule/UpdateVehicule/UpdateVehicule";
import { ListVehicule } from "../pages/Vehicule/ListVehicule/ListVehicule";
import { CreateVehicule } from "../pages/Vehicule/CreateVehicule/CreateVehicule";
import { UpdateCollaborator } from "../pages/Collaborator/UpdateCollaborator/UpdateCollaborator";
import { ListCollaborator } from "../pages/Collaborator/ListCollaborator/ListCollaborator";
import { CreateCollaborator } from "../pages/Collaborator/CreateCollaborator/CreateCollaborator";
import { ListNotification } from "../pages/Notification/ListNotification/ListNotification";
import { CreateClaims } from "../pages/Claims/CreateClaims/CreateClaims";
import { ListClaims } from "../pages/Claims/ListClaims/ListClaims";
import { UpdateClaims } from "../pages/Claims/UpdateClaims/UpdateClaims";
import { CarteAndBadge } from "../pages/CarteAndBadge/CarteAndBadge";
import { TCO } from "../pages/TCO/TCO";
import { Parametre } from "../pages/Parametre/Parametre";
import { RedirectEmployee } from "../pages/RedirectEmployee/RedirectEmployee";
import { EnterCodeForgotPassword } from "../pages/EnterCodeForgotPassword/EnterCodeForgotPassword";
import { ForgotPassword } from "../pages/ForgotPassword/ForgotPassword";
import { EnterEmailForgotPassword } from "../pages/EnterEmailForgotPassword/EnterEmailForgotPassword";

export const Routes = () => {
  const isAuthentificated = !!localStorage.getItem("token");

  return (
    <RoutesDOM>
      <Route path="/employee" element={<RedirectEmployee />} />
      <Route
        path="/"
        element={
          isAuthentificated ? (
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          ) : (
            <Login />
          )
        }
      />
      <Route path="/generate_password" element={<GeneratePassword />} />
      <Route
        path="/enter_email_forgot_password"
        element={<EnterEmailForgotPassword />}
      />
      <Route
        path="/enter_code_forgot_password"
        element={<EnterCodeForgotPassword />}
      />
      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/carteAndBadge"
        element={
          <PrivateRoute>
            <CarteAndBadge />
          </PrivateRoute>
        }
      />
      <Route path="/vehicule">
        <Route
          path="create"
          element={
            <PrivateRoute>
              <CreateVehicule />
            </PrivateRoute>
          }
        />
        <Route
          path="update/:id"
          element={
            <PrivateRoute>
              <UpdateVehicule />
            </PrivateRoute>
          }
        />
        <Route
          path="list"
          element={
            <PrivateRoute>
              <ListVehicule />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/collaborator">
        <Route
          path="create"
          element={
            <PrivateRoute>
              <CreateCollaborator />
            </PrivateRoute>
          }
        />
        <Route
          path="update/:id"
          element={
            <PrivateRoute>
              <UpdateCollaborator />
            </PrivateRoute>
          }
        />
        <Route
          path="list"
          element={
            <PrivateRoute>
              <ListCollaborator />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/notification">
        <Route
          path="list"
          element={
            <PrivateRoute>
              <ListNotification />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="/claims">
        <Route
          path="create"
          element={
            <PrivateRoute>
              <CreateClaims />
            </PrivateRoute>
          }
        />
        <Route
          path="update/:id"
          element={
            <PrivateRoute>
              <UpdateClaims />
            </PrivateRoute>
          }
        />
        <Route
          path="list"
          element={
            <PrivateRoute>
              <ListClaims />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path="/tco"
        element={
          <PrivateRoute>
            <TCO />
          </PrivateRoute>
        }
      />
      <Route
        path="/parametre"
        element={
          <PrivateRoute>
            <Parametre />
          </PrivateRoute>
        }
      />
    </RoutesDOM>
  );
};
