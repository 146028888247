import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import useStyles from "./styles";

export const InfosCollabDashboard = () => {
  const classes = useStyles();
  return (
    <div className={classes.divInfosCollab}>
      {Array.from({ length: 2 }).map((index: any) => (
        <div className={classes.divInfosCollabDashboard} key={index}>
          <div className={classes.divDate}>
            <CustomTypography
              text={"Du xx/xx/2022 au xx/xx/2023"}
              variant="link"
            />
            <CustomTypography text={"Durée : 36 Mois"} variant="link" />
          </div>
          <div className={classes.divImat}>
            <CustomTypography text={"Immat"} variant={"default"} isUppercase />
            <CustomTypography
              text={"Logo marque"}
              variant={"default"}
              isUppercase
            />
            <CustomTypography text={"Modele"} variant={"default"} isUppercase />
            <CustomTypography text={"Cat"} variant={"default"} isUppercase />
          </div>
        </div>
      ))}
    </div>
  );
};
