import { createUseStyles } from "react-jss";
const useStyles = createUseStyles((_) => ({
  containerText: {
    padding: "19px 15px 20px 15px",
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    "& > div": {
      marginBottom: 0,
    },
  },
}));

export default useStyles;
