import * as Yup from "yup";

export const validationSchemaUpdateVehicle = Yup.object().shape({
  vin: Yup.string().nullable(),
  immatriculation: Yup.string().nullable(),
  brand: Yup.object().nullable(),
  modele: Yup.object().nullable(),
  gearBox: Yup.string().nullable(),
  fiscalPower: Yup.string().nullable(),
  energy: Yup.string().nullable(),
  releaseDate: Yup.string().nullable(),
  status: Yup.string().nullable(),

  // Ajoutez d'autres champs de validation si nécessaire
});

export interface FormValuesVehicleUpdate {
  vin: string | null;
  immatriculation: string | null;
  brand: string | null;
  modele: string | null;
  gearBox: string | null;
  fiscalPower: string | null;
  energy: string | null;
  releaseDate: string | null;
  status: string | null;
}

export const initialValuesVehicleUpdate = {
  vin: null,
  immatriculation: null,
  brand: null,
  modele: null,
  gearBox: null,
  fiscalPower: null,
  energy: null,
  releaseDate: null,
  status: null,
} as FormValuesVehicleUpdate;
