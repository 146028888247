import { useTranslation } from "react-i18next";
import { CustomModal } from "../../../../components/CustomModal/CustomModal";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import toast from "react-hot-toast";
import useStyles from "./style";
import { FC } from "react";
import { useDeleteBadgeOrCard } from "../../../../api/badge";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";

type props = {
  open: boolean;
  currentCard: TCard | null;
  onCancel?: () => void;
  onClose?: () => void;
};

export const ModalDisabledCard: FC<props> = ({
  open,
  currentCard,
  onCancel,
  onClose,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { mutate: deleteCard } = useDeleteBadgeOrCard(
    getCurrentFleetId(),
    onClose
  );

  const onConfirm = () => {
    deleteCard({ badgeId: currentCard?.id ?? -1 });
  };

  return (
    <CustomModal
      open={open}
      onCancel={onCancel}
      title={""}
      onClose={onClose}
      onSave={() => toast.success(t("common.changeSaved", "Change saved"))}
      isConfirmation
      textConfirmation={t("common.confirm", "Confirm")}
      onConfirm={onConfirm}
    >
      <div className={classes.textModalContainer}>
        <CustomTypography
          text={t(
            "carteBadge.warningCard",
            "</br>You are about to deactivate the card.</br>You will not be able to go back.</br></br>Do you confirm this action ?"
          )}
          variant="default"
        />
      </div>
    </CustomModal>
  );
};
