import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles({
  container: {
    width: "60%",
    alignSelf: "center",
  },
  containerInput: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    "& > div": {
      flex: 1,
    },
  },
  containerRequire: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    padding: "0px 15px 0px 15px",
    justifyContent: "flex-end",
    marginBottom: "20px",
  },
  containerAllButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  },
  divRent: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 25px 0px 25px",
    "& .MuiTypography-root.MuiTypography-body1": {
      fontFamily: "Poppins-Regular",
      fontSize: "13px",
      color: colors.darkblack,
    },
  },

  divForm: {
    display: "flex",
    justifyContent: "center",
  },
});

export default useStyles;
