import useStyles from "./styles";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { colors } from "../../../../styles/colors";
import { useTranslation } from "react-i18next";
type Props = {};

export const HistoriqueContent: React.FC<Props> = () => {
  const classes = useStyles();
 
  const { t } = useTranslation();

  return (
    <div className={classes.containerHistorique}>
      <div>
        <CustomTypography
          text={t("collaborator.assignmentHistory", "Assignment history")}
          variant="h5"
          color={colors.primary}
          isUppercase
        />
      </div>
      <div className={classes.historiqueContent}>
        {/* {dates?.map((date) => (
          <div key={date.id}>
            <CustomTypography
              text={date.date}
              variant="default"
              fontWeight="bold"
              isUppercase
            />
            <CustomTypography text={date.hours} variant="link" isUppercase />
          </div>
        ))} */}
      </div>
    </div>
  );
};
