import { useTranslation } from "react-i18next";
import { CustomButtonOutlined } from "../../../../components/CustomButtonOutlined/CustomButtonOutlined";
import { CustomButtonTable } from "../../../../components/CustomButtonTable/CustomButtonTable";
import { CustomIconButton } from "../../../../components/CustomIconButton/CustomIconButton";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { CustomTypographyLabelValue } from "../../../../components/CustomTypographyLabelValue/CustomTypographyLabelValue";
import { colors } from "../../../../styles/colors";
import useStyles from "./styles";
import { useNavigate, useParams } from "react-router";
import { useGetSinisterById } from "../../../../api/sinistre";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import dayjs from "dayjs";
import { getSinisterKeyLabelByValue } from "../../types";

export const SectionSinister = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id = "" } = useParams();
  const sinisterId = parseInt(id);
  const navigate = useNavigate();
  const { sinisterDetail } = useGetSinisterById(
    getCurrentFleetId(),
    sinisterId
  );

  return (
    <div className={classes.divLeftTop}>
      <div className={classes.code}>
        <CustomTypography text={sinisterDetail?.number ?? ""} variant={"h3"} />
      </div>
      <div className={classes.divLeftTopRight}>
        {/* sinister header */}
        <div className={classes.divSinister}>
          <CustomTypography
            text={t("claims.sinister", "Sinister")}
            variant="h3"
            isUppercase
          />
          <CustomIconButton
            iconName={"pencil"}
            widthIcon={"30px"}
            heightIcon={"30px"}
          />
        </div>
        {/* infos */}
        <div className={classes.divInfos}>
          <div className={classes.divInfosComments}>
            <CustomTypographyLabelValue
              label={t("claims.dateOfLoss", "Date of loss")}
              value={
                sinisterDetail?.sinister_at
                  ? dayjs(sinisterDetail.sinister_at).format("DD-MM-YYYY")
                  : ""
              }
            />
            <div className={classes.divText}>
              <CustomTypographyLabelValue
                label={t("common.comment", "Comment")}
                isUppercaseValue={false}
                value={sinisterDetail?.description ?? ""}
              />
            </div>
          </div>
          <div className={classes.divInfosComments}>
            <CustomTypography
              text={t("common.status", "status")}
              variant="h5"
              color={colors.primary}
              isUppercase
            />
            <CustomButtonOutlined
              variant="success"
              label={
                getSinisterKeyLabelByValue(
                  sinisterDetail?.sinister_status as any
                ) ?? ""
              }
              width={"79px"}
            />
          </div>
        </div>
        {/* button */}
        <CustomButtonTable
          variant={"primary"}
          textVariant={"link"}
          label={t("collaborator.seeTheVehicle", "See the vehicle")}
          width={"fit-content"}
          fontWeight={"bold"}
          textDecoration={"none"}
          textTransform="uppercase"
          iconName={"eye"}
          onClick={() =>
            sinisterDetail?.vehicle_id &&
            navigate(`/vehicule/update/${sinisterDetail.vehicle_id}`)
          }
        />
      </div>
    </div>
  );
};
