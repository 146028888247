const initialState = {
  vehicule: null,
  ownerCompany: null,
};

const vehicleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "NEWLY_CREATED_VEHICULE":
      return {
        ...state,
        vehicule: action.data,
      };
    case "OWNER_COMPANY":
      return {
        ...state,
        selectedRentalCompany: action.data,
      };
    default:
      return state;
  }
};

export default vehicleReducer;
