import * as Yup from "yup";

export const validationSchemaCreateSinister = Yup.object().shape({
  name: Yup.string().nonNullable().required(),
  description: Yup.string().nonNullable().required(),
  mileage: Yup.string().nonNullable().required(),
  sinister_status: Yup.string().nonNullable().required(),
  sinister_at: Yup.string().nonNullable().required(),
  // Ajoutez d'autres champs de validation si nécessaire
});

interface FormValuesSinister {
  name: string | null;
  description: string | null;
  mileage: string | null;
  sinister_status: string | null;
  sinister_at: string | null;
}

export const initialValuesSinister = {
  name: null,
  description: null,
  mileage: null,
  sinister_status: null,
  sinister_at: null,
} as FormValuesSinister;
