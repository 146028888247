import dayjs from "dayjs";
import { CustomEnchorNotification } from "../../../components/CustomEnchorNotification/CustomEnchorNotification";
export const columnsNotification = [
  {
    header: "Date",
    field: "",
    render: (rowData: Record<string, any>) => {
      return (
        <div>
          {rowData?.date &&
            dayjs(rowData.date).format("DD-MM-YYYY")}
        </div>
      );
    },
  },
  { header: "Type d'intervention", field: "type" },
  { header: "Vehicule", field: "brandModel" },
  { header: "Marque", field: "brand" },
  { header: "Nom du collaborateur", field: "lastname" },
  { header: "Prénom du collaborateur", field: "firstname" },
  {
    header: "",
    field: "",
    render: (row: Record<string, any>) => {
      return (
        <CustomEnchorNotification
          notification={row as TNotification}
          isRead={Number(row.is_read) === 0 ? false : true}
        />
      );
    },
  },
];
