/* eslint-disable react-hooks/exhaustive-deps */
import useStyles from "./styles";
import { CustomModal } from "../../../../components/CustomModal/CustomModal";
import { useTranslation } from "react-i18next";
import { CustomTextInput } from "../../../../components/CustomTextInput/CustomTextInput";
import { CustomTextSelect } from "../../../../components/CustomTextSelect/CustomTextSelect";
import { FC, ReactNode, useEffect, useState } from "react";
import { CustomSearch } from "../../../../components/CustomSearch/CustomSearch";
import { useGetAllBrands, useGetAllModel } from "../../../../api/brandModel";
import { CustomTextDatePicker } from "../../../../components/CustomTextDatePicker/CustomTextDatePicker";
import {
  VehicleStatus,
  getKeyLabelByValue,
  getValueByKeyVehicleStatus,
} from "../../../../utils/types";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { SelectChangeEvent } from "@mui/material";
import { ModalLeasing } from "../../CreateVehicule/StepCreateFileVehicule/ModalLeasing/ModalLeasing";
import toast from "react-hot-toast";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";
import { useParams } from "react-router";
import {
  useGetOneVehicle,
  useUpdateVehicule,
  useUpdateVehiculeFleet,
} from "../../../../api/vehicule";
import {
  initialValuesVehicleUpdate,
  validationSchemaUpdateVehicle,
} from "./validationShemaUpdate";
import { checkValues } from "./checkValue";
import nextId from "react-id-generator";

type props = {
  opeModal: boolean;
  onClose: () => void;
  onCancel: () => void;
};

export const ModalUpdateVehicle: FC<props> = ({
  opeModal,
  onCancel,
  onClose,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const vehicleId = parseInt(id);

  const { vehicule } = useGetOneVehicle(getCurrentFleetId(), vehicleId);
  const { brands } = useGetAllBrands();

  const [brand, setbrand] = useState<TBrand | null>(null);
  const { brandsModel } = useGetAllModel(brand?.id ?? null);

  const [keyModel, setKeyModel] = useState(nextId());

  useEffect(() => {
    if (vehicule) {
      formik.setFieldValue("vin", vehicule.vin);
      formik.setFieldValue("immatriculation", vehicule.immatriculation);
      // brand
      const currentbrand = {
        id: vehicule.brandId,
        name: vehicule.brand,
      };
      setbrand(currentbrand as TBrand);
      formik.setFieldValue("brand", currentbrand);
      if (vehicule?.brandModel) {
        const currentbrandModel = JSON.parse(vehicule.brandModel);
        formik.setFieldValue("modele", currentbrandModel);
      }

      formik.setFieldValue("gearBox", vehicule.boite);
      formik.setFieldValue("fiscalPower", vehicule.puissance);
      formik.setFieldValue("energy", vehicule.carburant);
      formik.setFieldValue("releaseDate", dayjs(vehicule.mise_en_circulation));
      formik.setFieldValue(
        "monthly_average_cost",
        vehicule.monthly_average_cost
      );

      formik.setFieldValue(
        "status",
        getKeyLabelByValue(vehicule.fleetVehicleStatut as any)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicule]);

  const onSuccess = () => {
    onClose && onClose();
  };

  const { mutate: updateVehicle } = useUpdateVehicule(
    getCurrentFleetId(),
    vehicleId,
    onSuccess
  );
  const { mutate: updateVehicleFleet } = useUpdateVehiculeFleet(
    getCurrentFleetId(),
    vehicleId
  );

  const [selectedRental, setSelectedRental] = useState({
    label: "",
    value: "",
  });
  const [openLeasing, setOpenLeasing] = useState(false);

  const formik = useFormik({
    initialValues: initialValuesVehicleUpdate,
    validationSchema: validationSchemaUpdateVehicle,
    onSubmit: (values: any) => {
      const isChecked = checkValues(values);
      if (isChecked) {
        updateVehicle({
          // required api
          vin: values.vin ?? "",
          number: values.immatriculation ?? "",
          // owner_company_id:
          //   selectedRental.label.trim().length === 0
          //     ? getCurrentFleetId()
          //     : Number(selectedRental.value),
          brand_model_id: values.modele?.id ?? 0,
          // not required api
          boite: values.gearBox ?? "",
          puissance: values.fiscalPower ?? "",
          carburant: values.energy ?? "",
          mise_en_circulation:
            dayjs(values.releaseDate).format("YYYY-MM-DD") ?? "",
          register_step: 0,
        });
        updateVehicleFleet({
          vehicle_status:
            getValueByKeyVehicleStatus(values.status ?? "") ?? "F",
          monthly_average_cost: 0,
        });
      }
    },
  });

  // const handleRadioChange = (
  //   _: ChangeEvent<HTMLInputElement>,
  //   value: string
  // ) => {
  //   formik.setFieldValue("isLeasing", value);
  //   if (value === "yes") {
  //     setOpenLeasing(true);
  //   } else {
  //     setSelectedRental({ label: "", value: "" });
  //     setOpenLeasing(false);
  //   }
  // };

  const onChangeAutoCompletBrand = (_: any, brand: any) => {
    setbrand(brand as TBrand);
    formik?.setFieldValue("brand", brand);
    formik?.setFieldValue("modele", null);
    setKeyModel(nextId());
  };

  const onChangeAutoCompletModele = (_: any, modele: any) => {
    formik?.setFieldValue("modele", modele);
  };

  const onSaveLeasing = () => {
    if (selectedRental?.label?.trim()?.length > 0) {
      setOpenLeasing(false);
    } else {
      toast.error(
        t("required.selectRentalCompany", "Please select a rental company")
      );
    }
  };

  const onCloseLeasing = () => {
    setOpenLeasing(false);
    formik?.setFieldValue("isLeasing", null);
    setSelectedRental({ label: "", value: "" });
  };

  const onChangeSelectRentalCompany = (
    event: SelectChangeEvent<any>,
    _: ReactNode
  ) => {
    setSelectedRental(event.target.value as { label: string; value: string });
  };

  const handleSave = () => {
    formik.handleSubmit();
  };

  return (
    <>
      <ModalLeasing
        open={openLeasing}
        title={t("vehicule.societyName", "Adding rental company name")}
        valueSelectedRental={selectedRental}
        onSave={onSaveLeasing}
        onCancel={onCloseLeasing}
        onClose={onCloseLeasing}
        onChange={onChangeSelectRentalCompany}
      />

      <CustomModal
        width={"663px"}
        open={opeModal}
        onCancel={onCancel}
        title={t("vehicule.editVehicle", "Edit vehicle")}
        onClose={onClose}
        onSave={handleSave}
      >
        <div className={classes.containerText}>
          <div className={classes.divModalText}>
            <CustomTextInput
              name="vin"
              value={formik?.values?.vin}
              onChange={formik.handleChange}
              label={`${t("vehicule.vin", "VIN ")} *`}
              placeholder={t("vehicule.vin", "VIN")}
              disabled
            />
            <CustomTextInput
              name="immatriculation"
              value={formik?.values?.immatriculation}
              onChange={formik.handleChange}
              label={`${t("vehicule.registration", "registration")} *`}
              placeholder={t("vehicule.registration", "Registration")}
            />
          </div>
          <div className={classes.divModalText}>
            <CustomSearch
              options={brands}
              getOptionLabel={(option: any) => option?.name}
              label={`${t("vehicule.brand", "Brand")} *`}
              placeholder={t("vehicule.brand", "Brand")}
              value={formik?.values?.brand}
              onChange={onChangeAutoCompletBrand}
              onChangeTextField={(event) => {
                if (event.target.value?.trim().length === 0) {
                  setbrand(null);
                  setKeyModel(nextId());
                  formik?.setFieldValue("brand", null);
                }
              }}
            />
            <CustomSearch
              key={keyModel}
              options={brandsModel}
              getOptionLabel={(option: any) => option?.name}
              label={`${t("vehicule.model", "Model")} *`}
              placeholder={t("vehicule.model", "Model")}
              value={formik?.values?.modele}
              onChange={onChangeAutoCompletModele}
              onChangeTextField={(event) => {
                if (event.target.value?.trim().length === 0) {
                  formik?.setFieldValue("modele", null);
                }
              }}
            />
          </div>
          <div className={classes.divModalText}>
            <CustomTextSelect
              label={`${t("vehicule.gearBox", "Gearbox")} *`}
              options={["Automatique", "Manuelle"]}
              placeholder={t("vehicule.gearBox", "Gearbox")}
              name="gearBox"
              value={formik?.values?.gearBox}
              onChange={formik.handleChange}
            />
            <CustomTextInput
              label={`${t("vehicule.fiscalePower", "Fiscal power")} *`}
              placeholder={t("vehicule.fiscalePower", "Fiscal power")}
              name="fiscalPower"
              value={formik?.values?.fiscalPower}
              onChange={formik.handleChange}
            />
          </div>
          <div className={classes.divModalText}>
            <CustomTextSelect
              label={`${t("vehicule.fuel", "Fuel")} *`}
              options={[
                "Essence",
                "Diesel",
                "Electrique",
                "GPL",
                "Hydrogène",
                "Ethanol",
              ]}
              placeholder={t("vehicule.fuel", "Fuel")}
              name="energy"
              value={formik?.values?.energy}
              onChange={formik.handleChange}
            />
            <CustomTextDatePicker
              label={`${t("vehicule.release", "Release")} *`}
              value={formik?.values?.releaseDate as any}
              onChange={(value) =>
                formik?.setFieldValue(
                  "releaseDate",
                  dayjs(value).format("YYYY-MM-DD")
                )
              }
            />
          </div>
          <div className={classes.divModalText}>
            <CustomTextSelect
              label={`${t("vehicule.status", "Status")} *`}
              options={Object.keys(VehicleStatus)}
              placeholder={t("vehicule.status", "Status")}
              name="status"
              value={formik?.values?.status}
              onChange={formik.handleChange}
            />
          </div>
          {/* <div className={classes.divRent}>
            <CustomTypography
              text={t("vehicule.rent", "Is this a rental or leasing car? *")}
              variant={"link"}
            />
            <FormControl>
              <RadioGroup
                row
                value={formik?.values?.isLeasing}
                name="isLeasing"
                onChange={(event) => {
                  handleRadioChange(event, event.target.value);
                }}
              >
                <FormControlLabel
                  value={"no"}
                  control={<Radio />}
                  label={t("common.no", "No")}
                />
                <FormControlLabel
                  value={"yes"}
                  control={<Radio />}
                  label={t("common.yes", "Yes")}
                />
              </RadioGroup>
            </FormControl>
          </div> */}
        </div>
      </CustomModal>
    </>
  );
};
