import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { Icon } from "../../../../components/Icon/Icon";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { colors } from "../../../../styles/colors";
import { dataDocuments } from "./data";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router";
import { useGetDocumentByVehicleId } from "../../../../api/document";

type Props = {};

export const Documents: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const vehicleId = parseInt(id);

  const { documents } = useGetDocumentByVehicleId(vehicleId);

  const onClickDocument = (id: string) => {
    switch (id) {
      // select contrat
      case "1":
        const contrat = documents?.find(
          (document) => document.type === "contract"
        );
        if (contrat) {
          window.open(contrat.scan_id, "_blank");
        }
        break;
      // select carte grise
      case "2":
        const greyCard = documents?.find(
          (document) => document.type === "registration_certificate"
        );
        if (greyCard) {
          window.open(greyCard.scan_id, "_blank");
        }
        break;

      // select assurance
      case "3":
        const assurance = documents?.find(
          (document) => document.type === "insurance_certificate"
        );
        if (assurance) {
          window.open(assurance.scan_id, "_blank");
        }
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className={classes.container}>
        <div>
          <CustomTypography
            isUppercase
            text={t("panel.document.documents", "Documents")}
            variant={"h5"}
            styles={{ containerText: classes.contratTitle }}
          />
        </div>
        {dataDocuments.map((document, index) => (
          <div className={classes.subContainer} key={index}>
            <div className={classes.subContainer2}>
              <IconWithBackground iconName={document.icoName} />
              <div>
                <CustomTypography
                  text={document.title}
                  isUppercase
                  variant="link"
                  fontWeight={"bold"}
                />
                <CustomTypography text={document.subTitle} variant="link" />
              </div>
            </div>
            <IconButton
              className={classes.iconButton}
              onClick={() => onClickDocument(document.id)}
            >
              <Icon
                name="eye"
                width={"24px"}
                height={"24px"}
                color={colors.BLACK}
              />
            </IconButton>
          </div>
        ))}
      </div>
    </>
  );
};
