import { createUseStyles } from "react-jss";
import { colors } from "../../styles/colors";

type Props = {
  marginBottom?: string | number;
  width?: string | number;
  isError?: boolean;
};

const useStyles = createUseStyles((_) => ({
  container: {
    marginBottom: (props: Props) =>
      props.marginBottom ? props.marginBottom : "15px",
    width: (props: Props) => (props.width ? props.width : "100%"),
  },
  formControl: {
    "&.MuiFormControl-root": {
      width: "100%",
      marginTop: "5px",
      "& fieldset": {
        border: "none",
      },
      "& .MuiInputBase-root.MuiOutlinedInput-root": {
        fontFamily: "Poppins-Regular",
        backgroundColor: colors.default,
        border: (props: Props) =>
          props.isError
            ? `2px solid ${colors.tertiary}`
            : `2px solid ${colors.WHITE}`,
        borderRadius: "50px",
        fontSize: "13px",
        color: colors.BLACK,
        padding: "3px 12px 3px 19px",
      },
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "26px",
      color: colors.tertiary,
    },
  },
  containerLabel: {
    paddingLeft: "26px",
  },
}));

export default useStyles;
