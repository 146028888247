import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { CustomTypography } from "../../../../components/CustomTypography/CustomTypography";
import { CustomTextInput } from "../../../../components/CustomTextInput/CustomTextInput";
import { CustomButton } from "../../../../components/CustomButton/CustomButton";
import { IconWithBackground } from "../../../../components/IconWithBackground/IconWithBackground";
import { colors } from "../../../../styles/colors";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { CustomUploadButton } from "../../../../components/CustomUploadButton/CustomUploadButton";
import { Icon } from "../../../../components/Icon/Icon";
import { CustomSearch } from "../../../../components/CustomSearch/CustomSearch";
import { validateEmail } from "../../../../utils/regex";
import {
  UserRole,
  useGetAllEmployeeWithoutVehicle,
  useInviteEmployeeCompanie,
} from "../../../../api/employee";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";

type Props = {
  onClickSend?: () => void;
  onChangeCurrentEmployee: (employee: TEmployeeWithoutVehicle | null) => void;
};

export const StepSearchCollaborator: FC<Props> = ({
  onClickSend,
  onChangeCurrentEmployee,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { mutate: inviteUser, isPending: loadingUserInvite } =
    useInviteEmployeeCompanie(getCurrentFleetId());
  const { employees } = useGetAllEmployeeWithoutVehicle(getCurrentFleetId());

  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const handleCopy = () => {
    toast.success(
      t("collaborator.copySuccess", "Copy completed successfully !")
    );
  };

  const onDrop = (acceptedFiles: File[]) => {
    setUploadedFile(acceptedFiles[0]);
  };

  const onSendInvitationEmail = () => {
    if (!validateEmail(email)) {
      toast.error(t("required.emailInvalid", "Invalid email"));
    } else {
      if (firstname.trim().length === 0 || lastname.trim().length === 0) {
        toast.error(t("required.checkFields", "All fields are required"));
      } else {
        inviteUser({ email, role: UserRole.EMPLOYEE, firstname, lastname });
      }
    }
  };

  const onChangeTextField = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value.trim().length === 0) {
      onChangeCurrentEmployee(null);
    }
  };

  const onChangeAutocomplet = (_: any, valueTrue: any) => {
    onChangeCurrentEmployee(valueTrue);
  };

  return (
    <div className={classes.container}>
      <div className={classes.divLeft}>
        <div className={classes.subDivInvit}>
          <CustomTypography
            text={t("collaborator.invite", "Invite a collaborator")}
            variant={"h3"}
            isUppercase
          />
          <div className={classes.divSendEmail}>
            <CustomTextInput
              label={`${t("collaborator.name", "Nom")} *`}
              placeholder={t("collaborator.name", "Nom")}
              onChange={(e) => setLastname(e.target.value)}
            />
            <CustomTextInput
              label={`${t("collaborator.firstName", "Firstname")} *`}
              placeholder={t("collaborator.firstName", "Firstname")}
              onChange={(e) => setFirstname(e.target.value)}
            />
            <CustomTextInput
              label={`${t(
                "collaborator.emailPerso",
                "Personal email of your employee"
              )} *`}
              placeholder={t(
                "collaborator.placeholderEmail",
                "collaborator@mail.com"
              )}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className={classes.divSendEmail2}>
              <CustomButton
                label={t("common.send", "Send")}
                variant={"primary"}
                width={"fit-content"}
                height={"40px"}
                loading={loadingUserInvite}
                onClick={onSendInvitationEmail}
              />
              <CopyToClipboard text={email} onCopy={handleCopy}>
                <IconWithBackground
                  iconName="copy"
                  backgroundColor={colors.primary}
                  height={"20px"}
                  width={"20px"}
                  iconColor={colors.WHITE}
                />
              </CopyToClipboard>
            </div>
          </div>
        </div>

        <div className={classes.subDivInvit}>
          <CustomTypography
            text={t("collaborator.invitList", "Invite a list of collaborators")}
            variant={"h3"}
            isUppercase
          />
          <div className={classes.divSendEmail}>
            <div className={classes.divText}>
              <CustomTypography
                text={t("collaborator.loadFile", "Load a .csv file")}
                variant="link"
              />
              <div className={classes.divTextDisplay}>
                <CustomTypography
                  text={uploadedFile?.name ?? ""}
                  variant={"default"}
                />
                <Icon name={"paperClip"} width={"20px"} height={"20px"} />
              </div>
            </div>
            <div className={classes.divSendEmail2}>
              <CustomUploadButton onDrop={onDrop} />
              <CustomButton
                label={t("common.send", "Send")}
                variant={"disabled"}
                width={"fit-content"}
                height={"40px"}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.subDivInvit}>
        <CustomTypography
          text={t("collaborator.collaboratorSearch", "Collaborator search")}
          variant={"h3"}
          isUppercase
        />
        <div className={classes.divSendEmail}>
          <CustomSearch
            label={t(
              "collaborator.nameOfCollab",
              "Name of your collaborator *"
            )}
            placeholder={t("collaborator.placeholderName", "NAME_COLLABORATOR")}
            options={employees}
            onChange={onChangeAutocomplet}
            onChangeTextField={onChangeTextField}
            getOptionLabel={(user: any) =>
              `${user?.firstname ?? ""} ${user?.lastname ?? ""}` 
            }
          />
          <div className={classes.divSendEmail2}>
            <CustomButton
              onClick={onClickSend}
              label={t("common.send", "Send")}
              variant={"primary"}
              width={"fit-content"}
              height={"40px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
