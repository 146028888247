import { useTranslation } from "react-i18next";
import { CustomButtonTable } from "../../../components/CustomButtonTable/CustomButtonTable";
import { CustomCardInfo } from "../../../components/CustomCardInfo/CustomCardInfo";
import { CustomTypography } from "../../../components/CustomTypography/CustomTypography";
import { colors } from "../../../styles/colors";
import useStyles from "./style";
import { useGetTCOByCompany } from "../../../api/tco";
import { getCurrentFleetId } from "../../../utils/getCurrentFleet";

export const TCO = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { tco } = useGetTCOByCompany(getCurrentFleetId());

  return (
    <>
      <div className={classes.divTCO}>
        <div>
          <CustomTypography
            text={t("notification.TCOPerMonth", "TCO per month")}
            variant="h5"
            color={colors.primary}
            isUppercase
          />
        </div>
        <CustomCardInfo
          label={t("dashboard.totalCostOfThePark", "Total cost of the park")}
          value={`${tco?.total_tco_cost?.toFixed(2) ?? 0} €`}
          backgroundColor={colors.default}
          width={"auto"}
          height={"81px"}
        />
        <CustomCardInfo
          label={t(
            "notification.averageCostPerVehicle",
            "Average cost per vehicle"
          )}
          value={`${tco?.total_monthly_cost?.toFixed(2) ?? 0} €`}
          width={"auto"}
          height={"81px"}
          backgroundColor={colors.default}
        />
        <CustomButtonTable
          variant={"primary"}
          textVariant={"link"}
          label={t("notification.seeCostPerVehicle", "See cost per vehicle")}
          textTransform={"uppercase"}
          width={"fit-content"}
          fontWeight={"bold"}
          textDecoration={"none"}
          iconName={"eye"}
        />
      </div>
    </>
  );
};
