import { createUseStyles } from "react-jss";
import { colors } from "../../../../styles/colors";
const useStyles = createUseStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  subContainer: {
    // width: "66%",
    maxWidth: "818px",
    minWidth: "818px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    // "& :first-child": {
    //   justifyContent: "center",
    // },
  },
  containerDocuments: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    overflowX: "auto",
    justifyContent: "center",
  },
  containerButton: {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
    justifyContent: "space-between",
  },

  divDocuments: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    padding: "20px",
    backgroundColor: colors.WHITE,
    borderRadius: "8px",
  },
  subContainer2: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "15px",
  },
  divContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "15px",
  },
  sinisterCommentary: {
    "& :last-child": {
      "& :last-child": {
        maxHeight: "143px",
      },
    },
  },
});

export default useStyles;
