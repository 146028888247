import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { CustomCost } from "../../../../components/CustomCost/CustomCost";
import { useParams } from "react-router";
import { useGetTotalTCOByVehicle } from "../../../../api/tco";
import { getCurrentFleetId } from "../../../../utils/getCurrentFleet";

type Props = {};

export const CostSection: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { id = "" } = useParams();
  const vehicleId = parseInt(id);

  // const { tcoByVehicle } = useGetTCOByVehicle(getCurrentFleetId(), vehicleId);
  const { totalTcoByVehicle } = useGetTotalTCOByVehicle(
    getCurrentFleetId(),
    vehicleId
  );

  return (
    <div className={classes.container}>
      <CustomCost
        label={t("panel.average.averageMonthlyCost", "AVERAGE MONTHLY COST")}
     value={`€ ${totalTcoByVehicle?.total_monthly_cost?.toFixed(2) ?? 0}`}
      />
      <CustomCost
        label={t("panel.average.totalCost", "TOTAL COST")}
        value={`€ ${totalTcoByVehicle?.total_tco_cost?.toFixed(2) ?? 0}`}
      />
    </div>
  );
};
