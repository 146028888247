import { FC, useState } from "react";
import { Icon } from "../../../components/Icon/Icon";
import { ModalCarteAndBadge } from "../../../containers/ModalCarteAndBadge/ModalCarteAndBadge";
import { typeOfCardOrBadge } from "../../../api/badge";
import { ModalDisabledCard } from "./ModalDisabledCard/ModalDisabledCard";
import { useTranslation } from "react-i18next";

type props = {
  card: TCard;
};

export const ButtonsActionCards: FC<props> = ({ card }) => {
  const { t } = useTranslation();

  const [openModalAddCard, setOpenModalAddCard] = useState(false);
  const [openDisabledCard, setOpenDisabledCard] = useState(false);
  const [currentCard, setcurrentCard] = useState<TCard | null>(null);

  const onCLoseModalCard = () => {
    //  toast.error(t("carteBadge.disabledCardForce", { name: "COLLAB_X" }));
    setcurrentCard(null);
    setOpenDisabledCard(false);
    setOpenModalAddCard(false);
  };

  return (
    <>
      <ModalCarteAndBadge
        open={openModalAddCard}
        currentCartOrBadge={currentCard}
        title={
          currentCard
            ? t("collaborator.modifyCard", "Modify card")
            : t("collaborator.newCard", "New card")
        }
        supportTypes={typeOfCardOrBadge.card}
        onCancel={onCLoseModalCard}
        onClose={onCLoseModalCard}
      />
      <ModalDisabledCard
        currentCard={currentCard}
        open={openDisabledCard}
        onCancel={onCLoseModalCard}
        onClose={onCLoseModalCard}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "15px",
          width: "100%",
        }}
      >
        <Icon
          name={"pencil"}
          width={"24px"}
          height={"24px"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setcurrentCard(card as TCard);
            setOpenModalAddCard(true);
          }}
        />
        <Icon
          name={"trash"}
          width={"24px"}
          height={"24px"}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setcurrentCard(card as TCard);
            setOpenDisabledCard(true);
          }}
        />
      </div>
    </>
  );
};
